import {
  Flex,
  FormErrorMessage,
  Button,
  Box,
  Heading,
  Divider,
  FormControl,
  Input,
  Text
} from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';
import PRModal from 'components/modal';
import { observer } from 'mobx-react-lite';
import { states } from 'data/location';
import { useStore } from 'store';
import { Form, Formik } from 'formik';
import GooglePlacesInput from 'components/placeinput';
import { toast } from 'react-hot-toast';
import React, { useEffect } from 'react';
import { ReceiverDetailsSchema } from 'validations/authValidators';
import Permissions from 'utils/permissions';
import CustomToolTip from 'components/customToolTip';
import StateCitySelect from 'components/stateSelection';

const AddPartnerMemberModal = () => {
  const {
    AuthStore: { isAuthenticated },
    PartnerStore: {
      isShowAddPartnerMember,
      toggleAddPartnerMember,
      partnerMember,
      selectedPartnerInfo,
      updatePartnerMemberAdmin,
      addPartnerMemberAdmin,
      getPartnerMembersAdmin,
      partnerMembersAdmin
    },
    CheckoutStore: { isDeliveryAddressCardOpen, isCityValid },
    AuthStore
  } = useStore();
  const permissions = new Permissions(AuthStore);
  const id = selectedPartnerInfo?.partnerId as string;

  useEffect(() => {
    if (isAuthenticated && isShowAddPartnerMember) {
        getPartnerMembersAdmin(id as string);
        partnerMembersAdmin;
    }
  }, [isShowAddPartnerMember]);

  const handleDiscardChanges = () => {
    initialValues = {} as IMember;
    toggleAddPartnerMember('', false);
  };
  const ToolTipInfo = () => {
    return (
      <Box bg={`white`} color={`black`} borderRadius={`8px`}>
        <Text
          fontSize={{
            base: '11.5px',
            md: '15px'
          }}
          fontWeight={`400`}
        >
          {' '}
          <span style={{ whiteSpace: 'nowrap' }}>
            unique identifier that you use to identify your customer
          </span>
        </Text>
      </Box>
    );
  };
  let initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    city: '',
    state: '',
    businessId: '',
    phoneNumber: '',
    street: '',
    longitude: '',
    latitude: '',
    landmark: ''
  } as IMember;

  if (partnerMember !== ({} as IMember)) {
    initialValues = {
      street: partnerMember.street,
      city: partnerMember.city,
      state: partnerMember.state,
      longitude: partnerMember.longitude,
      latitude: partnerMember.latitude,
      businessId: partnerMember.businessId as string,
      landmark: partnerMember.landmark as string,
      firstName: partnerMember.firstName as string,
      lastName: partnerMember.lastName as string,
      email: partnerMember.email as string,
      phoneNumber: partnerMember.phoneNumber as string
    };
  }

  const handleSubmit = async (values: IMember) => {
    const payload = {
      street: values.street,
      city: values.city,
      state: values.state,
      longitude: values.longitude,
      latitude: values.latitude,
      businessId: values.businessId,
      landmark: values.landmark,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phoneNumber: values.phoneNumber
    };
    if (typeof partnerMember?._id === 'undefined' || partnerMember?._id === '') {
      addPartnerMemberAdmin(payload as IMember, id as string);
      toast.success('Member details added successfully', {
        position: 'top-right'
      });
    } else {
      updatePartnerMemberAdmin(
        payload as IMember,
        partnerMember._id as string,
        partnerMember.partnerId as string
      );
      toast.success('Member details updated successfully', {
        position: 'top-right'
      });
    }
    toggleAddPartnerMember('', false);
  };

  return (
    <PRModal
      title={`${
        typeof partnerMember?._id === 'undefined' || partnerMember?._id === '' ? 'Add New' : 'Edit'
      } Member`}
      open={isShowAddPartnerMember}
      onClose={() => toggleAddPartnerMember('', false)}
    >
      {isShowAddPartnerMember &&
        !isDeliveryAddressCardOpen &&
        permissions.hasFeatureAccess('CanViewPartnerMembers') && (
          <Box
            minW={{ base: '400px', md: '500px' }}
            h="75vh"
            overflowY="scroll"
            sx={{
              '&::-webkit-scrollbar': {
                display: 'none'
              }
            }}
          >
            <Heading fontSize="15px" mb="1rem">
              MEMBER'S DETAILS
            </Heading>
            <Divider color="gray" mb="3" />
            <Formik
              initialValues={initialValues}
              validationSchema={ReceiverDetailsSchema}
              onSubmit={handleSubmit}
            >
            {({ values, errors, touched, handleChange, setFieldValue }) => (
                <Form
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1rem'
                  }}
                >
                  {(partnerMember !== ({} as IMember) || id !== '') && (
                    <>
                      <FormControl isInvalid={errors.firstName && touched.firstName ? true : false}>
                        <FormControl fontSize="14px" fontWeight="500" aria-label="first name">
                          First name
                        </FormControl>
                        <Input
                          id="firstName"
                          value={values.firstName}
                          name="firstName"
                          onChange={handleChange}
                          type="text"
                          backgroundColor={'#F5F5F5'}
                          fontSize={{ base: '1rem', md: '0.875rem' }}
                          placeholder="Enter first name"
                          _placeholder={{
                            fontSize: { base: '12px', md: '14px' }
                          }}
                          aria-label="first name"
                        />
                        <FormErrorMessage>
                          {errors.firstName && touched.firstName ? errors.firstName : null}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl isInvalid={errors.lastName && touched.lastName ? true : false}>
                        <FormControl fontSize="14px" fontWeight="500" aria-label="lastName">
                          Last name
                        </FormControl>
                        <Input
                          id="lastName"
                          name="lastName"
                          value={values.lastName}
                          onChange={handleChange}
                          backgroundColor={'#F5F5F5'}
                          type="text"
                          fontSize={{ base: '1rem', md: '0.875rem' }}
                          placeholder="Enter last name"
                          _placeholder={{
                            fontSize: { base: '12px', md: '14px' }
                          }}
                          aria-label="last name"
                        />
                        <FormErrorMessage>
                          {errors.lastName && touched.lastName ? errors.lastName : null}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        isInvalid={errors.businessId && touched.businessId ? true : false}
                      >
                        <FormControl fontSize="14px" fontWeight="500" aria-label="businessId">
                          Member ID{' '}
                          <CustomToolTip
                            label={<ToolTipInfo />}
                            bg={`white`}
                            placement="right-start"
                            maxW="600px"
                          >
                            <InfoIcon color="primary.main" mb="1" />
                          </CustomToolTip>
                        </FormControl>
                        <Input
                          id="businessId"
                          name="businessId"
                          value={values.businessId}
                          onChange={handleChange}
                          backgroundColor={'#F5F5F5'}
                          type="text"
                          fontSize={{ base: '1rem', md: '0.875rem' }}
                          placeholder="Enter MEMBER ID"
                          _placeholder={{
                            fontSize: { base: '12px', md: '14px' }
                          }}
                          aria-label="member id"
                        />
                        <FormErrorMessage>
                          {errors.businessId && touched.businessId ? errors.businessId : null}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl isInvalid={errors.email && touched.email ? true : false}>
                        <FormControl fontSize="14px" fontWeight="500" aria-label="email">
                          Email Address
                        </FormControl>
                        <Input
                          id="email"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          backgroundColor={'#F5F5F5'}
                          type="email"
                          placeholder="Email"
                          _placeholder={{
                            fontSize: { base: '12px', md: '14px' }
                          }}
                          fontSize={{ base: '1rem', md: '0.875rem' }}
                          aria-label="email"
                        />
                        <FormErrorMessage>
                          {errors.email && touched.email ? errors.email : null}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        isInvalid={errors.phoneNumber && touched.phoneNumber ? true : false}
                      >
                        <FormControl fontSize="14px" fontWeight="500" aria-label="phone number">
                          Phone number
                        </FormControl>
                        <Input
                          id="phoneNumber"
                          name="phoneNumber"
                          value={values.phoneNumber}
                          onChange={handleChange}
                          backgroundColor={'#F5F5F5'}
                          type="tel"
                          fontSize={{ base: '1rem', md: '0.875rem' }}
                          placeholder="Phone number"
                          _placeholder={{
                            fontSize: { base: '12px', md: '14px' }
                          }}
                          aria-label="phone number"
                        />
                        <FormErrorMessage>
                          {errors.phoneNumber && touched.phoneNumber ? errors.phoneNumber : null}
                        </FormErrorMessage>
                      </FormControl>
                    </>
                  )}

                  <GooglePlacesInput
                    defaultValue={values.street}
                    error={(errors.street && touched.street) || false}
                    handleChange={handleChange}
                    errorMsg={errors.street}
                    inputProps={{
                      _placeholder: {
                        fontSize: { base: '12px', md: '14px' }
                      }
                    }}
                  />

                <StateCitySelect
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  states={states}
                  isCityValid={isCityValid}
                />
                  <FormControl isInvalid={errors.landmark && touched.landmark ? true : false}>
                    <FormControl fontSize="14px" fontWeight="500" aria-label="landmark">
                      Landmark
                    </FormControl>
                    <Input
                      id="landmark"
                      name="landmark"
                      value={values.landmark}
                      onChange={handleChange}
                      backgroundColor={'#F5F5F5'}
                      type="text"
                      fontSize={{ base: '1rem', md: '0.875rem' }}
                      placeholder="Enter Landmark"
                      _placeholder={{
                        fontSize: { base: '12px', md: '14px' }
                      }}
                      aria-label="landmark"
                    />
                    <FormErrorMessage>
                      {errors.landmark && touched.landmark ? errors.landmark : null}
                    </FormErrorMessage>
                  </FormControl>

                  <Flex justify="end" gap="1rem">
                    <Button
                      aria-label="discard changes"
                      variant="outline"
                      color="primary.main"
                      borderColor="primary.main"
                      onClick={handleDiscardChanges}
                      type="button"
                      size="sm"
                      display={
                        initialValues === null ||
                        values.firstName === '' ||
                        values.lastName === '' ||
                        values.email === '' ||
                        values.phoneNumber === '' ||
                        values.street === '' ||
                        values.state === '' ||
                        values.city === '' ||
                        values.landmark === ''
                          ? 'none'
                          : 'block'
                      }
                    >
                      Discard Changes
                    </Button>
                    <Button type="submit" size="sm" aria-label="save changes">
                      {' '}
                      Save Changes
                    </Button>
                  </Flex>
                </Form>
              )}
            </Formik>
          </Box>
        )}
    </PRModal>
  );
};

export default observer(AddPartnerMemberModal);
