import { VStack, Tab, TabList, Tabs } from '@chakra-ui/react';

const TabSelection: React.FC<ITabProps> = ({
  setSelectedTab,
  onTabSelection,
  units,
  lower,
  style
}) => {
  
  const format = (unit: string) => {
    return lower ? unit?.toLowerCase()?.trim() : unit?.trim();
  };

  return (
    <VStack width="100%">
      <Tabs bgColor="#F3F5F7" width="100%" colorScheme="purple" style={style?.tabs}>
        <TabList width="100%" style={style?.tabList}>
          {units.map((unit, index) => (
            <Tab
              width="100%"
              borderLeftRadius="5px"
              fontSize="14px"
              border="1px solid #D2D5DA"
              key={index}
              style={style?.tab}
              onClick={() => {
                onTabSelection(unit);
              }}
              _selected={
                setSelectedTab === format(unit)
                  ? { color: 'white', bg: 'purple.700' }
                  : { color: 'black', bg: '#F3F5F7' }
              }
              _focus={{
                outline: 'none'
              }}
              _hover={{
                cursor: 'pointer',
                bgColor: 'purple.500',
                color: 'white'
              }}
              _active={{
                bgColor: 'purple.500',
                color: 'white'
              }}
            >
              {unit?.trim()}
            </Tab>
          ))}
        </TabList>
      </Tabs>
    </VStack>
  );
};

export default TabSelection;
