import { Box, Checkbox, Flex, Heading, Text, Image, Divider } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { Suspense, lazy, useCallback, useEffect, useState } from 'react';
import { cities } from 'data/location';
import { useStore } from 'store';
import backIcon from 'assets/images/backicon.svg';
import { useNavigate } from 'react-router-dom';
import Loading from 'components/loading';
import { Routes } from 'constant';
import CartItemTotal from 'components/adminCart/CartItemTotal';
import CartItem from 'components/adminCart/CartItem';
import ReceipientFormDetails from 'components/adminCart/ReceipientFormDetails';
import EmptyCart from 'components/cart/emptycart';
const DeliveryAddressCard = lazy(
  () => import('components/receipentform/deliveryaddress/component/DeliveryAddressCard')
);

const CheckoutDeliveryAddress = () => {
  const navigate = useNavigate();
  let memberId = localStorage.getItem('memberId');
  const {
    CartStore: { updateDeliveryFee, cart },
    UserStore: {
      selectedUser,
      userFamilyAddress,
      userfamilyOrFriendsInfo,
      getFamilyAndFriendsAdmin
    },
    CheckoutStore: {
      deliveryAddress,
      guestAddressForm,
      toggleGuestAddressForm,
      setDeliveryAddress,
      addressToEditIndex,
      setAddressToEditIndex,
      newOrderAddressForm,
      toggleNewOrderAddressForm,
      toggleDeliveryAddressCard,
      selectAddress,
      selectedAddress
    }
  } = useStore();

  const [selectedfnfId, setselectedfnfId] = useState('');

  useEffect(() => {
    if (selectedUser?.userId) {
      const _id = userfamilyOrFriendsInfo ? userfamilyOrFriendsInfo._id : '';
      setselectedfnfId(_id as string);
      getFamilyAndFriendsAdmin(selectedUser?.userId);
    }
  }, [selectedUser?.userId, userfamilyOrFriendsInfo, getFamilyAndFriendsAdmin]);

  useEffect(() => {
    if (memberId && selectedUser?.friendsAndFamily) {
      const member = selectedUser.friendsAndFamily[parseInt(memberId)];
      if (member) {
        setDeliveryAddress(member as unknown as IAddress);
        selectAddress(memberId, true);
      }
    } else if (selectedUser?.deliveryAddress && selectedAddress === 'default') {
      const userDetails = {
        firstName: selectedUser?.personalInformation?.firstName,
        lastName: selectedUser?.personalInformation?.lastName,
        phoneNumber: selectedUser?.personalInformation?.phoneNumber,
        email: selectedUser?.email,
        street: selectedUser?.deliveryAddress?.street || '',
        latitude: selectedUser?.deliveryAddress?.latitude || '',
        longitude: selectedUser?.deliveryAddress?.longitude || '',
        city: selectedUser?.deliveryAddress?.city || '',
        state: selectedUser?.deliveryAddress?.state || '',
        landmark: selectedUser?.deliveryAddress?.landmark || ''
      };
      setDeliveryAddress({ ...userDetails } as unknown as IAddress);
    }
  }, [selectedUser, selectedUser?.deliveryAddress, setDeliveryAddress, selectedAddress, memberId]);

  const calculateDeliveryFee = useCallback(
    (orderAddress: IAddress) => {
      if (orderAddress) {
        let fee = 0;
        if (orderAddress?.state?.toLowerCase() === 'lagos') {
          fee = cities.find((x) => x.name === orderAddress?.city)?.price || 0;
        }
        updateDeliveryFee(fee);
      }
    },
    [updateDeliveryFee]
  );

  useEffect(() => {
    if (selectedAddress !== '') {
      calculateDeliveryFee(deliveryAddress);
    }
  }, [calculateDeliveryFee, deliveryAddress, selectedAddress]);

  const handleBack = () => {
    navigate({
      pathname: Routes.AdminPlaceOrderForCustomer
    });
  };

  const isAddressEmpty = Object.entries(deliveryAddress).some(([key, value]) => {
    return (
      key !== 'latitude' &&
      key !== 'longitude' &&
      (value === null || value === '' || value === undefined)
    );
  });
  const isDeliveryAddressEmptyStrings = (address: any) => {
    if (!address || typeof address !== 'object') return true;
    return Object.values(address).every(value => value === '');
  };

  const resetMemberId = () => {
    if (memberId) {
      localStorage.removeItem('memberId');
      memberId = null;
    }
  };
  return (
    <Flex flexDir="column" mx="2" my="5">
      <Flex align="center" gap=".5rem">
        <Image onClick={handleBack} src={backIcon} alt="logo" loading="lazy" />
        <Heading fontSize={{ base: '14px', md: '16px' }}>Add delivery address</Heading>
      </Flex>

      <Suspense fallback={<Loading />}>
        <Flex
          flexDirection={{ base: 'column', md: 'row' }}
          gap="2rem"
          bg="#ffff"
          mt="1rem"
          p="1.5rem"
        >
          <Box w={{ base: '', md: '100%', lg: '100%' }}>
            <Suspense fallback={<Loading />}>
              {JSON.stringify(selectedUser) !== '{}' &&
                selectedUser?.status === true &&
                selectedUser?.deliveryAddress ? (
                <>
                  <Checkbox
                    name="thirdPartyOrder"
                    isChecked={newOrderAddressForm}
                    onChange={() => {
                      toggleNewOrderAddressForm();
                    }}
                    colorScheme="purple"
                    mb="3"
                  >
                    <Text as="span" fontSize={{ base: '13px', md: '15px' }}>
                      I want this order sent to a different address
                    </Text>
                  </Checkbox>
                  <>
                    {(newOrderAddressForm || addressToEditIndex !== '') && (
                      <ReceipientFormDetails />
                    )}

                    {selectedfnfId ? (
                      <>
                        {userFamilyAddress
                          ?.filter((fnf: any) => fnf?._id === selectedfnfId)
                          .map((fnf: any, index: number) => (
                            <DeliveryAddressCard
                              key={index}
                              handleSelect={() => {
                                selectAddress(fnf?._id as string, true);
                                setDeliveryAddress(fnf as unknown as IAddress);
                              }}
                              selected={selectedAddress === (fnf?._id as string)}
                              street={fnf?.street || ''}
                              firstName={fnf?.firstName || ''}
                              lastName={fnf?.lastName || ''}
                              city={fnf?.city || ''}
                              state={fnf?.state || ''}
                              landmark={fnf?.landmark || ''}
                              isFaf={true}
                              title="Member's Information"
                              onClickHandler={() => {
                                toggleDeliveryAddressCard();
                                setDeliveryAddress(fnf as unknown as IAddress);
                                setAddressToEditIndex(index.toString());
                                selectAddress(fnf?._id as string, true);
                              }}
                            />
                          ))}
                      </>
                    ) : (
                      <>
                        <DeliveryAddressCard
                          selected={selectedAddress === 'default'}
                          handleSelect={() => {
                            resetMemberId();
                            selectAddress('default', false);
                            setDeliveryAddress({
                              ...selectedUser?.deliveryAddress
                            } as unknown as IAddress);
                          }}
                          email={selectedUser?.email || ''}
                          street={selectedUser?.deliveryAddress?.street || ''}
                          city={selectedUser?.deliveryAddress?.city || ''}
                          state={selectedUser?.deliveryAddress?.state || ''}
                          landmark={selectedUser?.deliveryAddress?.landmark || ''}
                          isFaf={false}
                          title="My Delivery Address"
                          onClickHandler={() => {
                            toggleDeliveryAddressCard();
                            setDeliveryAddress(
                              selectedUser?.deliveryAddress as unknown as IAddress
                            );
                            setAddressToEditIndex('default');
                          }}
                        />

                        {userFamilyAddress?.map((faf: any, index: number) => (
                          <DeliveryAddressCard
                            key={index}
                            handleSelect={() => {
                              resetMemberId();
                              selectAddress(faf?._id as string, true);
                              setDeliveryAddress(faf as unknown as IAddress);
                            }}
                            selected={selectedAddress === (faf?._id as string)}
                            email={faf?.email || ''}
                            street={faf?.street || ''}
                            firstName={faf?.firstName || ''}
                            lastName={faf?.lastName || ''}
                            city={faf?.city || ''}
                            state={faf?.state || ''}
                            landmark={faf?.landmark || ''}
                            isFaf={true}
                            title="Recipients Information"
                            onClickHandler={() => {
                              toggleDeliveryAddressCard();
                              setDeliveryAddress(faf as unknown as IAddress);
                              setAddressToEditIndex(index.toString());
                              selectAddress(faf?._id as string, true);
                            }}
                          />
                        ))}
                      </>
                    )}
                  </>
                </>
              ) : guestAddressForm &&
                (selectedUser?.status === false ||
                  !selectedUser?.deliveryAddress || !deliveryAddress ||
                  (selectedUser?.status === true && !selectedUser?.deliveryAddress) ||
                  (selectedUser?.status === true && isAddressEmpty) ||
                  isAddressEmpty || isDeliveryAddressEmptyStrings(deliveryAddress)
                ) ? (
                <ReceipientFormDetails />
              ) : (
                <DeliveryAddressCard
                  selected={selectedAddress === 'guest'}
                  handleSelect={() => {
                    selectAddress('guest', true);
                  }}
                  email={selectedUser?.email || ''}
                  street={deliveryAddress.street as string}
                  city={deliveryAddress.city as string}
                  state={deliveryAddress.state as string}
                  landmark={deliveryAddress.landmark as string}
                  isFaf={false}
                  onClickHandler={() => {
                    toggleGuestAddressForm();
                  }}
                  title={`${deliveryAddress.firstName} ${deliveryAddress.lastName}`}
                  isGuest={true}
                />
              )}
            </Suspense>
          </Box>
          <Box
            border="1px solid #f5f2f2"
            w={{ base: '93%', md: '84%', lg: '60%' }}
            bg="#ffff"
            borderRadius="5px"
            justifyContent="center"
            alignItems="center"
          >
            {cart.length < 1 ? (
              <EmptyCart />
            ) : (
              <>
                {cart.map((item, index) => (
                  <Box key={index}>
                    <CartItem cartItem={item} index={index} />
                    <Divider />
                  </Box>
                ))}

                <CartItemTotal />
              </>
            )}
          </Box>
        </Flex>
      </Suspense>
    </Flex>
  );
};

export default observer(CheckoutDeliveryAddress);
