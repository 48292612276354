import { Box, Text } from "@chakra-ui/react";

const ToolTipInfo = ({ text }: { text: string }) => {
  return (
    <Box bg={`white`} color={`black`} borderRadius={`8px`}>
      <Text
        fontSize={{
          base: '11.5px',
          md: '12px'
        }}
        fontWeight={`400`}
      >
        {' '}
        <span style={{ whiteSpace: 'nowrap' }}>{text}</span>
      </Text>
    </Box>
  );
};

export default ToolTipInfo;
