import { action, makeObservable, observable } from 'mobx';
import { RootStore } from '..';

export class CouponStore {
  selectedCoupon = {} as ICoupon;
  isCreateCouponModalOpen = false;
  isUpdateCouponStatusModalOpen = false;
  isDeleteCouponModalOpen = false;
  currentPage = 1;
  pageLimit = 10;
  newCoupon = '';
  editCoupon = '';
  initialValues = {
    couponCode: '',
    description: '',
    couponValue: 0,
    couponType: '',
    usageLimit: 0,
    expiryDate: ''
  } as ICoupon;
  params = {
    page: this.currentPage,
    pageSize: this.pageLimit,
    status: '',
    couponType: '',
    searchKey: ''
  };
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      newCoupon: observable,
      editCoupon: observable,
      selectedCoupon: observable,
      currentPage: observable,
      pageLimit: observable,
      initialValues: observable,
      params: observable,
      isCreateCouponModalOpen: observable,
      isUpdateCouponStatusModalOpen: observable,
      isDeleteCouponModalOpen: observable,

      setEditCoupon: action.bound,
      setNewCoupon: action.bound,
      setInitialValues: action.bound,
      setCurrentPage: action.bound,
      setPageLimit: action.bound,
      toggleCreateCouponModal: action.bound,
      toggleCouponActionModal: action.bound
    });
    this.rootStore = rootStore;
  }

  toggleCouponActionModal = (type?: string, coupon?: ICoupon) => {
    if (type === 'update') {
      this.isUpdateCouponStatusModalOpen = !this.isUpdateCouponStatusModalOpen;
    } else {
      this.isDeleteCouponModalOpen = !this.isDeleteCouponModalOpen;
    }
    if (coupon) {
      this.selectedCoupon = coupon;
    }
  };
  setEditCoupon = (coupon: string) => {
    this.editCoupon = coupon;
  };

  setNewCoupon = (coupon: string) => {
    this.newCoupon = coupon;
  };

  setInitialValues = (data: ICoupon) => {
    this.initialValues = {
      couponCode: data.couponCode,
      description: data.description,
      couponValue: data.couponValue,
      couponType: data.couponType,
      usageLimit: data.usageLimit,
      expiryDate: data.expiryDate
    } as ICoupon;
  };

  toggleCreateCouponModal = () => {
    if (!this.isCreateCouponModalOpen) {
      this.newCoupon = 'newCoupon';
    } else {
      this.newCoupon = '';
      this.initialValues = {
        couponCode: '',
        description: '',
        couponValue: 0,
        couponType: '',
        usageLimit: 0,
        expiryDate: ''
      } as ICoupon;
    }

    this.isCreateCouponModalOpen = !this.isCreateCouponModalOpen;
  };

  setCurrentPage(page: number) {
    this.currentPage = page;
  }

  setPageLimit(limit: number) {
    this.pageLimit = limit;
  }
}
