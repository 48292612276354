import {
  VStack,
  Card,
  SimpleGrid,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  InputGroup,
  InputLeftElement,
  Flex,
  ModalFooter,
  Button,
  Box,
  Text,
  Image,
  Spinner,
  Checkbox
} from '@chakra-ui/react';
import PRModal from 'components/modal';
import { categoryOptions, subCategoryOptions, mobileCategoryOptions } from 'data/inventory';
import { Formik, Form } from 'formik';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { MdDelete, MdAdd } from 'react-icons/md';
import { useStore } from 'store';
import { BundleDetailsSchema } from 'validations/inventoryValidators';
import { useBundles, useCreateBundle, useGetDrugs } from 'hooks/queryHooks';
import toast from 'react-hot-toast';

const CreateBundleItem = () => {
  const {
    InventoryStore: {
      isCreateBundleItemModal,
      toggleCreateBundleItemModal,
      newBundleItem,
      isLoading,
      bundleItem,
      queryString,
      toggleMedicationItems,
      editBundle,
      editBundleItem,
      currentPage,
      pageLimit,
      setBundle,
      deleteImageKey,
      updateTooltipMessage
    },
    CartStore: { sendPrescription },
    PartnerStore: { pharmacies, getAllPartners }
  } = useStore();
  const { refetch } = useBundles(currentPage, pageLimit, queryString, setBundle);
  const { mutate: createBundle, isLoading: createLoading } = useCreateBundle(
    toggleCreateBundleItemModal
  );
  const [searchKey, setSearchKey] = useState('');
  const { data: searchedDrugs, isLoading: fetchDrugs } = useGetDrugs(searchKey, {
    toggleMedicationItems,
    updateTooltipMessage
  });
  const [image, setImage] = useState<any>('');
  const handleSubmit = async (values: IBundle) => {
    const newValues = {
      name: values.name,
      category: values.category,
      subCategory: values.subCategory,
      mobileCategory: values.mobileCategory,
      description: values.description,
      supplier: values.supplier,
      drugs: values.drugs.map((item: any) => {
        return typeof item === 'string' ? item : item?.drugGeneratedId;
      }),
      image: image.fileUrl || values.image,
      canBeModified: values.canBeModified
    };
    let res;

    if (newBundleItem === 'newBundleItem') {
      await createBundle(newValues);
      await refetch();
    } else {
      if (newBundleItem === 'editInventoryItem') {
        res = await editBundle(newValues, editBundleItem as string);
      }
    }
    res && (await refetch());
  };
  const handleImageUpload = async (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => {
    const MAX_FILE_SIZE = 5120000;
    const file = e.target.files;

    // Validating file existence
    if (!file) {
      toast.error('Please choose a file', {
        position: 'top-right'
      });
      return;
    }
    // Validating file size
    if (file[0].size > MAX_FILE_SIZE) {
      toast.error('File size is more than Max size.', {
        position: 'top-right'
      });
      return;
    }

    const fileType = file[0].type.split('/');
    const extension = fileType[1] as string;
    const extensions = ['jpg', 'jpeg', 'pdf', 'png', 'heic'];

    if (extensions.includes(extension)) {
      const formData: any = new FormData();
      formData.append('file', file[0]);
      const imageUrl = await sendPrescription(formData, 'Image');

      setImage(imageUrl as any);
      setFieldValue('image', imageUrl || '');
    }
  };

  const handleImageDelete = (bundleItemId: string, imageKey: string) => {
    deleteImageKey(bundleItemId, imageKey);
    setImage('');
  };

  useEffect(() => {
    getAllPartners();
  }, []);

  return (
    <PRModal
      title={newBundleItem === 'newBundleItem' ? 'Create bundles' : 'Edit bundles'}
      open={!isCreateBundleItemModal ? false : true}
      onClose={() => {
        toggleCreateBundleItemModal();
      }}
      width="50%"
    >
      <VStack
        h="65vh"
        minW={{ md: '400px' }}
        overflowY="scroll"
        sx={{
          '&::-webkit-scrollbar': {
            display: 'none'
          }
        }}
        mb={{ base: '10rem', md: '8rem' }}
        gap="1rem"
        align="start"
      >
        <Formik
          initialValues={bundleItem}
          validationSchema={BundleDetailsSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, handleChange, handleBlur, setFieldValue }) => (
            <Form
              action=""
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem'
              }}
              id="bundle-form"
            >
              <Box>
                <Text
                  fontWeight="500"
                  fontSize={{
                    base: 'h5',
                    md: 'h4'
                  }}
                >
                  .NAME YOUR BUNDLE
                </Text>
                <Text
                  fontSize={{
                    base: '12px',
                    md: '14px'
                  }}
                >
                  Add names, manufacturer & medication summary your customers would use to recognise
                  the medication
                </Text>
              </Box>
              <Card border="1.5px solid #F5F5F5" p=".6rem" w="100%">
                <FormControl isInvalid={errors.name && touched.name ? true : false}>
                  <Box
                    fontSize={{
                      base: '12px',
                      md: '14px'
                    }}
                  >
                    <FormLabel fontWeight="500" aria-label="bundle name">
                      Bundle name
                    </FormLabel>
                    <Input
                      id="name"
                      bg="#F3F5F7"
                      name="name"
                      type="text"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Enter the bundle name"
                      _placeholder={{
                        fontSize: '12px'
                      }}
                      fontSize={{ base: '1rem', md: '0.875rem' }}
                      aria-label="bundle name"
                    />
                  </Box>
                  <FormErrorMessage>
                    {errors?.name && touched.name ? errors.name : null}
                  </FormErrorMessage>
                </FormControl>

                <SimpleGrid columns={2} spacing={4}>
                  <FormControl isInvalid={errors.category && touched.category ? true : false}>
                    <Box
                      fontSize={{
                        base: '12px',
                        md: '14px'
                      }}
                    >
                      <FormLabel fontWeight="500" aria-label="item category">
                        Item category
                      </FormLabel>
                      <Select
                        id="category"
                        isSearchable={false}
                        options={categoryOptions}
                        placeholder="Pick a category"
                        name="category"
                        value={
                          categoryOptions.find((option) => option.value === values.category) || ''
                        }
                        onChange={(selectedOption: any) =>
                          setFieldValue('category', selectedOption.value)
                        }
                        onBlur={handleBlur}
                        aria-label="item category"
                      />
                    </Box>
                    <FormErrorMessage>
                      {errors?.category && touched.category ? errors.category : null}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.subCategory && touched.subCategory ? true : false}>
                    <Box
                      fontSize={{
                        base: '12px',
                        md: '14px'
                      }}
                    >
                      <FormLabel fontWeight="500" aria-label="item sub category">
                        Item sub category
                      </FormLabel>
                      <Select
                        id="subCategory"
                        isSearchable={false}
                        options={subCategoryOptions}
                        placeholder="Pick a category"
                        name="subCategory"
                        value={
                          subCategoryOptions.find(
                            (option) => option.value === values.subCategory
                          ) || ''
                        }
                        onChange={(selectedOption: any) =>
                          setFieldValue('subCategory', selectedOption.value)
                        }
                        onBlur={handleBlur}
                        aria-label="item sub category"
                      />
                    </Box>
                    <FormErrorMessage>
                      {errors?.subCategory && touched.subCategory ? errors.subCategory : null}
                    </FormErrorMessage>
                  </FormControl>
                </SimpleGrid>
                <SimpleGrid my={4} columns={1}>
                  <FormControl
                    isInvalid={errors.mobileCategory && touched.mobileCategory ? true : false}
                  >
                    <Box
                      fontSize={{
                        base: '12px',
                        md: '14px'
                      }}
                    >
                      <FormLabel fontWeight="500" aria-label="mobile category">
                        Mobile category
                      </FormLabel>
                      <Select
                        isMulti
                        id="mobileCategory"
                        isSearchable={false}
                        options={mobileCategoryOptions}
                        placeholder="Select categories"
                        name="mobileCategory"
                        value={mobileCategoryOptions.filter((option) =>
                          values.mobileCategory.includes(option.value)
                        )}
                        onChange={(selectedOptions) =>
                          setFieldValue(
                            'mobileCategory',
                            selectedOptions ? selectedOptions.map((option) => option.value) : []
                          )
                        }
                        onBlur={handleBlur}
                        aria-label="mobile category"
                      />
                    </Box>
                    <FormErrorMessage>
                      {errors?.mobileCategory && touched.mobileCategory
                        ? errors.mobileCategory
                        : null}
                    </FormErrorMessage>
                  </FormControl>
                </SimpleGrid>
                <FormControl isInvalid={errors.description && touched.description ? true : false}>
                  <Box
                    fontSize={{
                      base: '12px',
                      md: '14px'
                    }}
                    mt="1rem"
                    w="100%"
                  >
                    <FormLabel fontWeight="500" aria-label="medication summary">
                      Description{' '}
                    </FormLabel>
                    <Input
                      id="description"
                      _placeholder={{
                        fontSize: '12px'
                      }}
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      bg="#F3F5F7"
                      h="80px"
                      type="text"
                      placeholder="Add a medication summary"
                      fontSize={{ base: '1rem', md: '0.875rem' }}
                      aria-label="medication summary"
                    />
                  </Box>
                  <FormErrorMessage>
                    {errors?.description && touched.description ? errors.description : null}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.drugs && touched.drugs ? true : false}>
                  <Box
                    fontSize={{
                      base: '12px',
                      md: '14px'
                    }}
                    mt="1rem"
                    w="100%"
                  >
                    <FormLabel fontWeight="500" aria-label="drugs">
                      Add items to this bundle
                    </FormLabel>
                    <Box pos="relative" w="100%">
                      <AsyncSelect
                        id="drugs"
                        isMulti
                        placeholder="Search for items you want to add"
                        name="drugs"
                        defaultValue={values.drugs.map((item: any) => {
                          return {
                            drugData: item,
                            label: item.genericName || item.popularName || item.brandName,
                            value: item.drugGeneratedId
                          };
                        })}
                        onChange={(selectedOptions) => {
                          const selectedDrugIds = selectedOptions.map(
                            (option: any) => option.drugData?.drugGeneratedId
                          );
                          setFieldValue('drugs', selectedDrugIds);
                        }}
                        defaultOptions={bundleItem?.drugs.map((item: any) => {
                          return {
                            drugData: item,
                            label: item.genericName || item.popularName || item.brandName,
                            value: item.drugGeneratedId
                          };
                        })}
                        loadOptions={(inputValue, callback) => {
                          if (inputValue.length >= 3) {
                            setSearchKey(inputValue);
                            callback(searchedDrugs);
                          } else {
                            callback([]);
                          }
                        }}
                        noOptionsMessage={() => 'Type to search in order to select items'}
                        aria-label="drugs"
                        isSearchable={true}
                      />

                      <Box position="absolute" top="10px" right="10px">
                        {fetchDrugs && <Spinner speed="0.3s" />}
                      </Box>
                    </Box>
                  </Box>
                  <FormErrorMessage>
                    {errors?.drugs && touched?.drugs ? errors?.drugs : null}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.supplier && touched.supplier ? true : false}>
                  <Box
                    fontSize={{
                      base: '12px',
                      md: '14px'
                    }}
                    mt="1rem"
                    w="100%"
                  >
                    <FormLabel fontWeight="500" aria-label="supplier">
                      Supplier
                    </FormLabel>
                    <Select
                      id="supplier"
                      placeholder="Search for supplier"
                      isSearchable={true}
                      onChange={(selectedOption: any) => {
                        setFieldValue('supplier', selectedOption.value);
                      }}
                      value={
                        pharmacies.find((option: any) => option?.value === values.supplier) || ''
                      }
                      onBlur={handleBlur}
                      options={pharmacies || []}
                    />
                  </Box>
                  <FormErrorMessage>
                    {errors?.supplier && touched.supplier ? errors.supplier : null}
                  </FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={errors.canBeModified && touched.canBeModified ? true : false}
                >
                  <Box
                    fontSize={{
                      base: '12px',
                      md: '14px'
                    }}
                    mt="1rem"
                    w="100%"
                  >
                    <Checkbox
                      name="canBeModified"
                      fontWeight="500"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('canBeModified', e.target.checked);
                      }}
                      colorScheme="purple"
                      isChecked={values.canBeModified}
                      onBlur={handleBlur}
                    >
                      <Text
                        fontSize={{
                          base: '13px',
                          md: '15px'
                        }}
                      >
                        Can be modified
                      </Text>
                    </Checkbox>
                  </Box>
                  <FormErrorMessage>
                    {errors?.canBeModified && touched.canBeModified ? errors.canBeModified : null}
                  </FormErrorMessage>
                </FormControl>
              </Card>
              <Box>
                <Text
                  fontWeight="500"
                  fontSize={{
                    base: 'h5',
                    md: 'h4'
                  }}
                >
                  .ADD ITEM IMAGE
                </Text>
                <Text
                  fontSize={{
                    base: '12px',
                    md: '14px'
                  }}
                >
                  Include item images to this medication
                </Text>
                <Card p="3rem 1.5rem" mb="1rem">
                  <Box position="relative" width="150px" height="150px" border="1.5px dotted #ccc">
                    {image && (
                      <Image
                        src={image.fileUrl}
                        width="100%"
                        height="100%"
                        objectFit="cover"
                        alt="image"
                      />
                    )}
                    {image && (
                      <InputGroup
                        width="20px"
                        bg="primary.main"
                        color="white"
                        height="20px"
                        position="absolute"
                        bottom="-8px"
                        right="-5px"
                        borderRadius="50%"
                        display="flex"
                        alignItems="center"
                      >
                        <Box ml="-10px" position="relative">
                          <InputLeftElement
                            mt=".15rem"
                            cursor="pointer"
                            borderRadius="50%"
                            onClick={() => handleImageDelete(bundleItem?.image, image?.key)}
                            children={<MdDelete fontSize=".75rem" />}
                            top="-22px"
                          />
                        </Box>
                      </InputGroup>
                    )}

                    {!image && (
                      <label>
                        <InputGroup
                          border="1px solid #541AA9"
                          width="20px"
                          bg="primary.main"
                          color="white"
                          height="20px"
                          position="absolute"
                          bottom="-8px"
                          right="-4px"
                          borderRadius="50%"
                          display="flex"
                          alignItems="center"
                        >
                          <Box ml="-10.5px" position="relative">
                            <InputLeftElement
                              mt=".15rem"
                              pointerEvents="none"
                              children={<MdAdd fontSize="1.1rem" />}
                              top="-22px"
                            />
                          </Box>
                          <Input
                            type="file"
                            accept="image/*"
                            sx={{
                              border: 'none',
                              padding: '0',
                              margin: '0',
                              height: '100%',
                              width: '100%',
                              outline: 'none',
                              cursor: 'pointer',
                              fontSize: '1rem',
                              marginTop: '1.2rem',
                              backgroundColor: 'transparent',
                              textAlign: 'center',

                              '&::-webkit-file-upload-button': {
                                display: 'none'
                              },

                              _before: {
                                content: `''`,
                                display: 'inline-block',
                                backgroundColor: 'transparent',
                                cursor: 'pointer',
                                fontSize: '14px',
                                textAlign: 'center',
                                padding: '0',
                                margin: '0',
                                height: '100%',
                                width: '100%',
                                outline: 'none',
                                border: 'none'
                              }
                            }}
                            aria-label="Upload a picture"
                            onChange={(e) => handleImageUpload(e, setFieldValue)}
                          />
                        </InputGroup>
                      </label>
                    )}
                  </Box>
                </Card>
              </Box>

              <ModalFooter
                sx={{
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  bg: 'white'
                }}
                position="absolute"
                bottom="0"
                left="0"
              >
                <Flex
                  flexDir={{
                    base: 'column',
                    md: 'row'
                  }}
                  align="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Button
                    onClick={() => toggleCreateBundleItemModal()}
                    bg="transparent"
                    color="primary.main"
                    cursor="pointer"
                    aria-label="cancel"
                  >
                    Cancel
                  </Button>

                  <Flex
                    fontSize={{
                      base: '14px',
                      md: '16px'
                    }}
                  >
                    <Button
                      size={{ base: 'sm' }}
                      onClick={async () => await handleSubmit(values)}
                      isLoading={
                        newBundleItem === 'newBundleItem' ? createLoading : isLoading.editBundle
                      }
                      type="submit"
                      form="bundle-form"
                      aria-label="submit"
                    >
                      {newBundleItem === 'newBundleItem' ? 'Create bundle' : 'Edit bundle'}
                    </Button>
                  </Flex>
                </Flex>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </VStack>
    </PRModal>
  );
};

export default observer(CreateBundleItem);
