import { useStore } from 'store';
import { useLocation, Navigate } from 'react-router-dom';

const AccountGuard: React.FC<{
  children: JSX.Element;
}> = ({ children }) => {
  const {
    AuthStore: { isAuthenticated }
  } = useStore();
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/auth/admin/login" state={{ from: location }} replace />;
  }

  return children;
};

export default AccountGuard;
