import { Divider, Flex, Text } from '@chakra-ui/react';
import { orderStatus } from 'constant';
import React from 'react'; 

const refineScheduleData = (schedule: ISchedule) => {
  if (schedule.delivered) {
    return {
      description: 'Delivered',
      label: 'Delivered',
      date: new Date(schedule?.dispatchDate).toLocaleDateString('en-NG'),
      bg: orderStatus.Delivered.bg,
      color: orderStatus.Delivered.color
    };
  } else if (schedule.dispatched) {
    return {
      description: 'Waiting to be delivered',
      label: 'Dispatched',
      date: new Date(schedule?.dispatchDate).toLocaleDateString('en-NG'),
      bg: orderStatus.Dispatched.bg,
      color: orderStatus.Dispatched.color
    };
  } else {
    return {
      description: 'Scheduled to be delivered',
      label: 'Paid',
      date: new Date(schedule?.dispatchDate).toLocaleDateString('en-NG'),
      bg: orderStatus.Paid.bg,
      color: orderStatus.Paid.color
    };
  }
};

const ScheduleItem = ({ schedule }: { schedule: ISchedule }) => {
  const scheduleInfo = refineScheduleData(schedule);
  
  return (
    <>
      <Flex w="100%" gap="1rem" align="center">
        <Text fontSize={{ base: '12px', md: '14px' }} fontWeight="500" w={`50%`}>
          {scheduleInfo?.description}
        </Text>
        <Text w={`30%`}>{scheduleInfo?.date}</Text>
        <Text
          fontSize={{
            base: '12px',
            md: '14px'
          }}
          h="auto"
          color={scheduleInfo.color}
          p=".5rem"
          align="center"
          borderRadius="8px"
          bg={scheduleInfo.bg}
          w={`20%`}
        >
          {scheduleInfo.label}
        </Text>
      </Flex> 
      <Divider pt=".3rem" />
    </>
  );
};

export default ScheduleItem;
