import { Box, Card, Flex, Text, Image, Button } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { Suspense, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Order1 from 'assets/images/order1.svg';
import Order2 from 'assets/images/order2.svg';
import member from 'assets/images/addrunner.svg';
import { Routes } from 'constant';
import { useStore } from 'store';
import Loading from 'components/loading';

const Order = () => {
  const {
    OrderStore: { makeSubscription },
    UserStore: { accountType, changeOnboardingStep }
  } = useStore();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (location.state) {
      navigate(location.state);
    }
  }, [location, navigate]);

  return (
    <Suspense fallback={<Loading />}>
      <Box>
        <Text as="b">I want to...</Text>
        <Flex direction={{ base: 'column', md: 'row' }} alignItems="center" gap="1.5rem" mt="2rem">
          <Card
            onClick={() => navigate(Routes.PlaceOrder)}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
              textAlign: 'center',
              cursor: 'pointer'
            }}
            p="2rem"
            w={{ base: '70%', md: '50%' }}
          >
            <Image w="50px" src={Order1} alt="place order" loading="lazy" />
            <Text fontSize="12px">Place a one time order</Text>
          </Card>
          {accountType === 'business account' ? (
            <Card
              onClick={() => navigate(Routes.Members)}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '1rem',
                textAlign: 'center',
                cursor: 'pointer'
              }}
              p="2rem"
              w={{ base: '70%', md: '50%' }}
            >
              <Image w="50px" src={member} alt="create a member" loading="lazy" />
              <Text width="150px" fontSize="12px">
                Add member
              </Text>
            </Card>
          ) : (
            <Card
              onClick={() => {
                makeSubscription();
                navigate(Routes.PlaceOrder);
              }}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '1rem',
                textAlign: 'center',
                cursor: 'pointer'
              }}
              p="2rem"
              w={{ base: '70%', md: '50%' }}
            >
              <Image w="50px" src={Order2} alt="get meds" loading="lazy" />
              <Text width="150px" fontSize="12px">
                Get your meds at the same time every month
              </Text>
            </Card>
          )}
        </Flex>
        <Flex mt="2rem" mb="2rem" justify="space-between">
          <Button
            type="button"
            bg="primary.sub"
            color="primary.main"
            size="sm"
            onClick={() => changeOnboardingStep('remove')}
          >
            Prev
          </Button>
          <Button
            type="button"
            size="sm"
            _hover={{
              bgColor: 'primary.sub',
              color: 'primary.main'
            }}
            variant="outline"
            onClick={() => navigate(Routes.Home)}
          >
            Skip for Later
          </Button>
        </Flex>
      </Box>
    </Suspense>
  );
};

export default observer(Order);
