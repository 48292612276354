import { Tooltip, TooltipProps } from '@chakra-ui/react';
import { useState } from 'react';

type CustomToolTipProps = {
  children: React.ReactNode;
} & TooltipProps;

const CustomToolTip = ({
  children,
  ...props
}: CustomToolTipProps) => {
  const [isToolTipOpen, setToolTipOpen] = useState(false);

  return (
    <div
      onMouseEnter={() => {
        setToolTipOpen(true);
      }}
      onMouseLeave={() => {
        setToolTipOpen(false);
      }}
    >
      <Tooltip
        bg={props?.bg ?? '#000'}
        isOpen={isToolTipOpen}
        label={props?.label}
        placement={props?.placement ?? "auto-start"}
        {...props}
      >
        {children}
      </Tooltip>
    </div>
  );
};

export default CustomToolTip;
