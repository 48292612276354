import { makeObservable, action, observable } from 'mobx';
import { RootStore } from '..';

export class CampaignStore {
  campaign = [] as ICampaign[];
  selectedCampaign: Nullable<ICampaign> = null 
  pageNumber = 1;
  pageSize = 10;
  newCampaign = '';
  editCampaign = '';
  campaignForm = {
    title: '',
    description: '',
    images: Array.from({ length: 5 }, () => ({ key: '', fileUrl: '' }))
  } as ICampaign;
  isCreateCampaignModalOpen = false;
  isStatusCampaignModalOpen = false;
  isDeleteCampaignModalOpen = false;
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeObservable(this, {
      campaign: observable,
      pageSize: observable,
      pageNumber: observable,
      campaignForm: observable,
      selectedCampaign: observable,
      newCampaign: observable,
      editCampaign: observable,
      isCreateCampaignModalOpen: observable,
      isStatusCampaignModalOpen: observable,
      isDeleteCampaignModalOpen: observable,

      toggleCreateCampaignModal: action.bound,
      toggleDeleteCampaignModal: action.bound,
      toggleStatusCampaignModal: action.bound,
      setPageNumber: action.bound,
      setPageSize: action.bound,
      setNewCampaignBanner: action.bound,
      setToEditCampaignBanner: action.bound,
      setCampaignBanner: action.bound
    });
    this.rootStore = rootStore;
  }
  setCampaignBanner(campaign: ICampaign) {
    this.campaignForm = campaign;
  }
  setToEditCampaignBanner(id: string) {
    this.editCampaign = id;
  }
  setNewCampaignBanner(value: string) {
    this.newCampaign = value;
  }
  setPageSize(limit: number) {
    this.pageSize = limit;
  }

  setPageNumber(page: number) {
    this.pageNumber = page;
  }
  toggleCreateCampaignModal(id?: string) {
    if (!this.isCreateCampaignModalOpen) {
      this.newCampaign = 'newCampaign';
    } else {
      this.newCampaign = '';
      this.campaignForm = {
        title: '',
        description: '',
        images: Array.from({ length: 5 }, () => ({ key: '', fileUrl: '' }))
      } as ICampaign;
    }
    this.isCreateCampaignModalOpen = !this.isCreateCampaignModalOpen;
    const items = this.campaign.find((item) => item._id === id);
    if (items) {
      this.selectedCampaign = items;
    }
  }
  toggleDeleteCampaignModal(campaign?: ICampaign) {
    this.isDeleteCampaignModalOpen = !this.isDeleteCampaignModalOpen;

    if (campaign) {
      this.selectedCampaign = campaign;
    }
  }
  toggleStatusCampaignModal(campaign?: ICampaign) {
    this.isStatusCampaignModalOpen = !this.isStatusCampaignModalOpen;

    if (campaign) {
      this.selectedCampaign = campaign;
    }
  }
}
