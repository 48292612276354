import { createDecipheriv, randomBytes } from 'crypto';
import aes from 'crypto-js/aes';
import enc from 'crypto-js/enc-utf8';
import storage from './storage';
import jwtDecode from 'jwt-decode';
import { ACCESS_TOKEN, ENCRYPTION_KEY, REFRESH_TOKEN_KEY, Routes, userKey } from '../constant';
import axios from 'axios';
import config from 'config';

interface DecodedToken {
  exp: number;
  // Include other properties present in your token payload
}

export const encrypt_key = (token: any) => {
  const encryptedToken = aes.encrypt(token, ENCRYPTION_KEY).toString();

  return encryptedToken;
};

export const decrypt_key = (token: string) => {
  if (token) {
    const decryptedToken = aes.decrypt(token, ENCRYPTION_KEY);
    const decryptedTokenSting = decryptedToken?.toString(enc);
    return decryptedTokenSting;
  }
  return token;
};

export const encrypt_userobj = (user: IAuthResponse) => {
  const encryptedUser = aes.encrypt(JSON.stringify(user), ENCRYPTION_KEY).toString();

  return encryptedUser;
};

export const decrypt_user0bj = (user: any) => {
  if (user) {
    const decryptedUser = aes.decrypt(user, ENCRYPTION_KEY);
    return JSON.parse(decryptedUser.toString(enc));
  } else {
    return '';
  }
};

export const stringifyCart = (cart: ICartDrug[]) => {
  return JSON.stringify(cart);
};

export const parseCart = (cart: any): ICartDrug[] => {
  return JSON.parse(cart);
};

export const getUserId = () => {
  const { userId } = decrypt_user0bj(storage.get(userKey));
  return userId;
};

//logout functionality
export const handleLogout = (page = '') => {
  storage.logout();
  window.location.href = page ?? Routes.PlaceOrder;
};

export const setTokens = (accessToken: string, refreshToken: string) => {
  const encryptedAccessToken = encrypt_key(accessToken);
  const encryptedRefreshToken = encrypt_key(refreshToken);

  if (encryptedAccessToken && encryptedRefreshToken) {
    storage.set(ACCESS_TOKEN, encryptedAccessToken);
    storage.set(REFRESH_TOKEN_KEY, encryptedRefreshToken);
  }
};

export const userIsAuthenticated = () => {
  const { accessToken } = getAuthTokens();
  if (!accessToken) return false;
  const notAuthenticated = isTokenExpired(accessToken);
  if (notAuthenticated) refreshAccessToken();
  return !notAuthenticated;
};

export const refreshAccessToken = async () => {
  try {
    const { refreshToken } = getAuthTokens();
    if (!refreshToken) {
      throw new Error('Refresh token is missing');
    }
    const axiosInstance = axios.create({
      baseURL: config.AUTH_BASE_URL,
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const { data } = await axiosInstance.get('/api/auth/refresh/', {
      headers: { Authorization: `Bearer ${refreshToken}` }
    });

    if (data.status) {
      const {
        data: { accessToken, refreshToken: refresh }
      } = data;
      setTokens(accessToken, refresh);
      return accessToken;
    }
    return null;
  } catch (error) {
    console.log('Error refreshing access token', error);
    handleLogout('/auth/login');
    throw error;
  }
};

export const getAuthTokens = () => {
  const accessToken = storage.get(ACCESS_TOKEN);
  const refreshToken = storage.get(REFRESH_TOKEN_KEY);
  if (accessToken && refreshToken) {
    return {
      accessToken: decrypt_key(accessToken),
      refreshToken: decrypt_key(refreshToken)
    };
  }
  return {};
};

export const isTokenExpired = (token: string): boolean => {
  try {
    const decodedToken = jwtDecode<DecodedToken>(token);
    const expirationTime = decodedToken?.exp;

    if (expirationTime && Date.now() >= expirationTime * 1000) {
      // Token has expired
      return true;
    } else {
      // Token is still valid
      return false;
    }
  } catch (error) {
    // Error decoding token or invalid token format
    console.error('Error decoding token:', error);
    return true; // Assume token has expired to be safe
  }
};

export const decryptedToken = (token: string) => {
  const iv = randomBytes(16);
  const Securitykey = randomBytes(32);
  const algorithm = 'aes-256-ctr';
  const decipher = createDecipheriv(algorithm, Securitykey, iv, );
  let decryptedData: any = decipher.update(token, 'hex', 'utf8');

  decryptedData += decipher.final('utf8');

  const newDecryptedValue: any = JSON.parse(decryptedData);

  return newDecryptedValue;
};
