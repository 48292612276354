import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/react';
import { responsiveLg } from '../../utils';

export interface IModalProps {
  open: boolean;
  onClose: () => void;
  title?: string;
  children: React.ReactNode;
  width?: string;
  actionButtons?: React.ReactNode[];
}

const PRModal = ({ open, onClose, width, title, children, actionButtons }: IModalProps) => {
  return (
    <Modal isOpen={open} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        maxW={{ base: '20%', md: '100%' }}
        sx={{
          borderRadius: '12px',
          width: width,
          minW: responsiveLg('90%', '448px')
        }}
      >
        <ModalHeader
          fontSize={{ base: '14px', md: '16px' }}
          fontWeight="400"
          sx={{
            borderBottom: '1px solid rgba(112, 112, 112, 0.24)'
          }}
        >
          {title}
        </ModalHeader>

        <ModalCloseButton
          _hover={{
            background: 'none'
          }}
        >
          <Box
            style={{
              color: 'black',
              background: '#EE4E61',
              borderRadius: '50%',
              width: '15px',
              height: '15px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              top: '12px',
              right: '10px'
            }}
          > 
            <Box w="5px" h="5px" borderRadius="50%" bgColor="#A4414D"></Box>
          </Box>
        </ModalCloseButton>
        <ModalBody
          flex="1"
          sx={{
            overflowY: 'auto'
          }}
        >
          {children}
        </ModalBody>
        {actionButtons && (
          <ModalFooter
            sx={{
              borderTop: '1px solid rgba(112, 112, 112, 0.24)'
            }}
          >
            <Flex
              w="100%"
              flexDir={{ base: 'column', md: 'row' }}
              justify={{ md: 'flex-end' }}
              alignItems="center"
              gap='0.8rem'
            >
              {actionButtons.map((actionButton, index) => (
                <Box key={index}>{actionButton}</Box>
              ))}
            </Flex>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};

export default PRModal;
