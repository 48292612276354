import { Heading, Divider, Box, Text, Flex, VStack } from '@chakra-ui/react';
import { Routes } from 'constant';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'store';
import { timeAgo } from 'utils/helpers';
import { IoNotificationsOffOutline } from 'react-icons/io5';

const NotificationCard = () => {
  const {
    AppStore: { adminNotifications, removeNotification, clearNotification, toggleNotificationBell }
  } = useStore();
  const navigate = useNavigate();

  const viewOrder = (notification: IAdminNotifications) => {
    const { notificationType, orderNumber } = notification;
    removeNotification(orderNumber);
    toggleNotificationBell();
    
    if (notificationType === 'subscription') {
      navigate(Routes.AdminSubscriptionDetails(orderNumber));
    } else {
      navigate(Routes.AdminOrderDetails(orderNumber));
    }
  };

  return (
    <Box
      w="320px"
      bg="white"
      position="absolute"
      top="50px"
      right="100px"
      zIndex="9999"
      p="10px"
      borderRadius="8px"
      boxShadow="md"
    >
      <Heading as="h3" size="sm" fontWeight="400" textAlign="center">
        <Flex justify={`space-between`}>
          Notifications
          <Box
            style={{
              color: 'black',
              background: '#EE4E61',
              borderRadius: '50%',
              width: '15px',
              height: '15px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              top: '12px',
              right: '10px'
            }}
            onClick={toggleNotificationBell}
            cursor={`pointer`}
          >
            <Box w="5px" h="5px" borderRadius="50%" bgColor="#A4414D"></Box>
          </Box>
        </Flex>
      </Heading>
      <Divider mt=".5rem" />
      <VStack h={adminNotifications.length >= 5 ? '40vh' : 'auto'} w="100%">
        <VStack
          overflowY="scroll"
          h={adminNotifications.length >= 5 ? 'scroll' : 'auto'}
          css={{
            '-ms-overflow-style': 'none',
            'scrollbar-width': 'none',
            '&::-webkit-scrollbar': {
              width: '0 !important'
            }
          }}
        >
          {adminNotifications.length > 0 && (
            <Text
              fontSize={`10px`}
              color={`#541AA9`}
              cursor={`pointer`}
              onClick={clearNotification}
              pl={`.5rem`}
              mt={`.5rem`}
              mb={`.5rem`}
            >
              Mark all as read
            </Text>
          )}
          {adminNotifications.length === 0 && (
            <VStack py="3rem">
              <Box>
                <IoNotificationsOffOutline color={`#541AA9`} fontSize={60} />
              </Box>
              <Text mt="1rem" color="gray.600">
                No new notifications!
              </Text>
            </VStack>
          )}
          {adminNotifications.map((notification: IAdminNotifications, index: number) => (
            <VStack
              key={`${notification.orderNumber}-${index}`}
              sx={{
                padding: '8px',
                alignItems: 'flex-start',
                w: '100%'
              }}
            >
              <Flex
                justify="space-between"
                fontSize={`14px`}
                w="100%"
                onClick={() => viewOrder(notification)}
                cursor={`pointer`}
                gap="2rem"
              >
                <Text>{notification?.status}</Text>
                <Text color={`#541AA9`}>{notification?.orderNumber}</Text>
              </Flex>
              <Flex gap={`.5rem`} w="100%">
                <Text fontSize={`10px`} color={`#707070`}>
                  {timeAgo(notification?.datetime)}
                </Text>
                <Text
                  fontSize={`10px`}
                  color={`#541AA9`}
                  cursor="pointer"
                  onClick={() => removeNotification(notification?.orderNumber)}
                >
                  Mark as read
                </Text>
              </Flex>
              {index !== adminNotifications.length - 1 && <Divider />}
            </VStack>
          ))}
        </VStack>
      </VStack>
    </Box>
  );
};

export default observer(NotificationCard);
