import React, { useCallback } from "react";
import usePlacesAutocomplete, {
  getDetails,
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Input,
  Text,
  Flex,
  FormControl,
  FormLabel,
  Image,
  List,
  ListItem,
} from "@chakra-ui/react";
import FaGoogle from "assets/icons/google-map.svg";
import IoLocationSharp from "assets/icons/current-location.svg";
import config from "config";
import { cities } from "data/location";

const GooglePlacesInput: React.FC<GooglePlacesInputProps> = ({
  defaultValue,
  handleChange,
  handleBlur,
  error,
  isDisabled = false,
  errorMsg = "Street is required",
  inputProps = {},
}) => {
  const {
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });

  const handlePlaceSelect = useCallback(
    async (selectedAddress: { place_id: string; description: string }) => {
      const description = selectedAddress.description;

      // Use a type guard to check if details is a PlaceResult
      if (
        typeof selectedAddress === "object" &&
        "place_id" in selectedAddress
      ) {
        const details = await getDetails({ placeId: selectedAddress.place_id });

        // Check if details has address_components property
        if (typeof details === "object" && "address_components" in details) {
          // Accessing the address components
          const addressComponents = details.address_components;
          extractLocationInfo(addressComponents);
        }
      }

      setValue(description, false);
      handleControlledChanged("street", description);
      clearSuggestions();

      getGeocode({ address: description }).then((results: any) => {
        const { lat, lng } = getLatLng(results[0]);
        if (lat && lng) {
          setCardinals(lat, lng);
        }
      });
    },
    [setValue, clearSuggestions]
  );

  const extractLocationInfo = (addressComponents: any) => {
    let city: string, state: string, landmark: string;

    // Extracting city and state information
    addressComponents &&
      addressComponents.forEach(
        (component: { types: string | string[]; long_name: any }) => {
          if (component.types.includes("administrative_area_level_1")) {
            state = component.long_name;
            handleControlledChanged("state", state);
          } else if (component.types.includes("administrative_area_level_2")) {
            city = component.long_name;
            // if state is city, check that city exists in my available cities
            if (state === "Lagos") {
              const cityExists = cities.find((c) => c.name === city);
              if (!cityExists) {
                city = "";
              }
            }  
            handleControlledChanged("city", city);
          } else if (component.types.includes("point_of_interest")) {
            landmark = component.long_name;
            handleControlledChanged("landmark", landmark);
          }
        }
      );
  };
  const setCardinals = (latitude: number, longitude: number) => {
    handleControlledChanged("latitude", latitude.toString());
    handleControlledChanged("longitude", longitude.toString());
  };

  const handleControlledChanged = (name: string, value: string | number) => {
    handleChange({
      currentTarget: { name, value },
    } as React.FormEvent<HTMLInputElement | HTMLSelectElement>);
  };

  const handleCurrentLocation = useCallback(async () => {
    try {
      const position = await new Promise<GeolocationPosition>(
        (resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        }
      );

      const { latitude, longitude } = position.coords;
      setCardinals(latitude, longitude);

      // Reverse geocoding using Google Maps Geocoding API
      const apiKey = config.googlePlaceKey;
      const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

      const response = await fetch(apiUrl);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.results && data.results.length > 0) {
        const streetAddress = data.results[0].formatted_address;
        const addressComponents = data.results[0].address_components;
        extractLocationInfo(addressComponents);
        handleControlledChanged("street", streetAddress);
        clearSuggestions();
      } else {
        console.error("No results found for reverse geocoding.");
      }
    } catch (error) {
      console.error("Error during reverse geocoding:", error);
    }
  }, []);

  const renderSuggestions = () => {
    if (status === "OK") {
      return (
        <List
          style={{
            boxShadow: "md",
            borderRadius: "md",
            overflow: "hidden",
            maxHeight: "200px",
            overflowY: "auto",
          }}
        >
          {data.map((suggestion: any, index: number) => (
            <ListItem
              key={index}
              py="2"
              px="3"
              _hover={{
                cursor: "pointer",
                bg: "secondary.sub",
                border: "1px solid #5519A8",
                color: "#5519A8",
              }}
              style={{
                cursor: "pointer",
                borderBottomWidth: "1px",
                borderColor: "gray.200",
                transition: "background-color 0.3s ease",
              }}
              onClick={() =>
                handlePlaceSelect({
                  place_id: suggestion.place_id,
                  description: suggestion.description,
                })
              }
            >
              {suggestion.description}
            </ListItem>
          ))}
        </List>
      );
    }
    return null;
  };

  return (
    <FormControl my={1}>
      <FormLabel
        fontWeight="500"
        fontSize={{ base: "14px", md: "16px" }}
        aria-label="street"
      >
        Street address
      </FormLabel>
      <Input
        id="street"
        name="street"
        placeholder="Enter address here"
        value={value || defaultValue}
        onChange={(e) => {
          setValue(e.target.value);
          handleChange(e);
        }}
        onBlur={handleBlur && handleBlur}
        isDisabled={isDisabled}
        bgColor="#F3F5F7"
        fontSize={{ base: "1rem", md: "0.875rem" }}
        type="text"
        aria-label="google-places-input"
        {...inputProps}
      />
      {status === "OK" && renderSuggestions()}
      {error && (
        <Text fontSize="12px" color="red">
          Street is required
        </Text>
      )}
      <Flex align="center" mt={2} justify="space-between">
        <Flex>
          <Text fontSize="12px" color="gray.500" pr={2}>
            Powered by Google
          </Text>
          <Image src={FaGoogle} />
        </Flex>

        <Image
          src={IoLocationSharp}
          cursor={!isDisabled ? "pointer" : "not-allowed"}
          onClick={handleCurrentLocation}
        />
      </Flex>
      {error && (
        <Text fontSize="12px" color="red">
          {errorMsg}
        </Text>
      )}
    </FormControl>
  );
};

export default GooglePlacesInput;
