import { action, makeObservable, observable } from 'mobx';
import { RootStore } from '..';

export class ActivitiesStore {
    currentPage = 1;
    pageLimit = 10;

    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        makeObservable(this, {
            currentPage: observable,
            pageLimit: observable,

            setCurrentPage: action.bound,
            setPageLimit: action.bound,
            onPageChange: action.bound
        });
        this.rootStore = rootStore;
    }
    onPageChange(page: number) {
        this.currentPage = page;
    }


    setCurrentPage(page: number) {
        this.currentPage = page;
    }

    setPageLimit(limit: number) {
        this.pageLimit = limit;
    }
}
