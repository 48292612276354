import { Box, Button, Flex, Input, VStack, Text } from '@chakra-ui/react';
import PRModal from 'components/modal';
import { useCampaign, useCampaignStatusAction } from 'hooks/queryHooks';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useStore } from 'store';

const BannerStatusActivation = () => {
  const {
    CampaignStore: {
      toggleStatusCampaignModal,
      isStatusCampaignModalOpen,
      pageSize: pageLimit,
      pageNumber: currentPage,
      selectedCampaign
    }
  } = useStore();
  const [deactivationReason, setDeactivationReason] = useState<string>('');
  const { refetch } = useCampaign(currentPage, pageLimit);
  const { mutate, isLoading } = useCampaignStatusAction(
    toggleStatusCampaignModal,
    selectedCampaign?._id as string,
    selectedCampaign?.isActive as boolean,
    deactivationReason as string
  );

  const handleStatusActivation = () => {
    if (selectedCampaign?.isActive === true) {
      mutate(selectedCampaign?._id as any, deactivationReason as any);
      setDeactivationReason('');
    } else {
      mutate(selectedCampaign?._id as any);
    }
    refetch();
  };

  return (
    <PRModal
      title={
        isStatusCampaignModalOpen
          ? selectedCampaign?.isActive
            ? 'Deactivate this promotion banner'
            : 'Reactivate this promotion banner'
          : 'Delete this promotion banner'
      }
      open={isStatusCampaignModalOpen}
      onClose={() => toggleStatusCampaignModal()}
    >
      <VStack py="1.5rem" maxW={{ base: '100%', md: '450px' }}>
        {isStatusCampaignModalOpen && (
          <Box>
            <Text>
              You're about to <b>{`${selectedCampaign?.isActive ? 'deactivate' : 'reactivate'}`}</b>{' '}
              this promotion banner. Do you want to go ahead with this ?
            </Text>
            {selectedCampaign?.isActive && (
              <Input
                mt="1rem"
                name="deactivationReason"
                value={deactivationReason}
                onChange={(e) => setDeactivationReason(e.target.value)}
                fontSize={{ base: '1rem', md: '0.875rem' }}
                type="text"
                placeholder={`Enter reason for ${
                  selectedCampaign.isActive ? 'deactivation' : 'activation'
                }`}
              />
            )}
          </Box>
        )}

        <Flex mt="2rem" w="100%" justify="end">
          <Button
            isLoading={isLoading}
            bg={selectedCampaign?.isActive ? '#e42222dd' : 'primary.main'}
            onClick={() => {
              handleStatusActivation();
            }}
          >
            {selectedCampaign?.isActive ? 'Deactivate ' : 'Reactivate '}
            banner
          </Button>
        </Flex>
      </VStack>
    </PRModal>
  );
};

export default observer(BannerStatusActivation);
