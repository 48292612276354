import { action, flow, makeObservable, observable } from 'mobx';
import { RootStore } from '..';
import { cartKey, cartPricing } from 'constant';
import { parseCart } from 'utils/auth-util';
import storage from 'utils/storage';
import toast from 'react-hot-toast';
import { uploadFile, getFile } from 'requests/cart';

export class CartStore {
  cart = parseCart(storage.get(cartKey)) || [];
  loading = false;
  isUploading = false;
  isDownloading = false;
  isCartOpen = false;
  cartTotals = JSON.parse(storage.get(cartPricing) || "{}") || {
    itemsTotal: 0,
    deliveryFee: 0,
    sampleCollectionFee: 0,
  };
  logs: {
    search: string[];
    selectedDrug: IDrug[];
  } = {
      search: [],
      selectedDrug: []
    };

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      cart: observable,
      loading: observable,
      isUploading: observable,
      isDownloading: observable,
      logs: observable,
      isCartOpen: observable,
      cartTotals: observable,

      addToCart: action.bound,
      removeCartItem: action.bound,
      clearCart: action.bound,
      addCartQuantity: action.bound,
      subtractCartQuantity: action.bound,
      clearLogs: action.bound,
      toggleCartOpen: action.bound,
      sumOrder: action.bound,
      updateDeliveryFee: action.bound,
      checkCartItemType: action.bound,

      sendPrescription: flow.bound,
      getS3BucketFile: flow.bound
    });

    this.rootStore = rootStore;
  }
  toggleCartOpen() {
    this.isCartOpen = !this.isCartOpen;
  }
  addToCart(drug: ICartDrug) {
    const item = this.cart.find((item) => item.drugGeneratedId === drug.drugGeneratedId);
    if (item) {
      item.quantity = item.quantity + 1;
      item.totalPrice = item.price * (item.quantity);
      toast.success('Cart quantity updated successfully', {
        position: 'top-right',
      })
    }
    else {
      this.cart.push(drug);
      toast.success('Product added successfully', {
        position: 'top-right',
      })
    }
    storage.set(cartKey, JSON.stringify(this.cart));
    this.sumOrder(); 
  }
  removeCartItem(id: string) {
    if(this.cart.length <= 1) {
      this.rootStore.CheckoutStore.validatedCoupon = null;
    }
    this.cart = this.cart.filter((item) => item.drugGeneratedId !== id);
    storage.set(cartKey, JSON.stringify(this.cart));
    this.sumOrder();
    toast.success('Product removed successfully', {
      position: 'top-right',

    });
  }
  clearCart() {
    this.cart = [];
    storage.set(cartKey, JSON.stringify(this.cart));
    this.sumOrder();
  }

  addCartQuantity(id: string) {
    this.cart.map((item) => {
      if (item.drugGeneratedId === id) {
        item.quantity = item.quantity + 1,
          item.totalPrice = item.price * (item.quantity);
        toast.success('Cart quantity updated successfully', {
          position: 'top-right',
        })
      }
      else {
        item.quantity;
      }

    });
    storage.set(cartKey, JSON.stringify(this.cart));
    this.sumOrder();
  }
  subtractCartQuantity(id: string) {
    this.cart.map((item) => {
      if (item.drugGeneratedId === id) {
        if (item.quantity > 1) {
          item.quantity = item.quantity - 1;
          item.totalPrice = item.price * (item.quantity);
          toast.success('Cart quantity updated successfully', {
            position: 'top-right',
          })
        }
        else {
          item.quantity = 1;
        }
      }
    });
    storage.set(cartKey, JSON.stringify(this.cart));
    this.sumOrder();
  }
  sumOrder() {
    const { drugType , testType} = this.checkCartItemType();

    const itemsTotal = this.cart.reduce(
      (total, item) => total + (item.totalPrice || 0),
      0
    );
    this.cartTotals = {
      ...this.cartTotals,
      itemsTotal,
      deliveryFee: drugType ? this.cartTotals.deliveryFee : 0,
      sampleCollectionFee: testType ? 5000 : 0
    };


    storage.set(cartPricing, JSON.stringify(this.cartTotals));
  }
  clearLogs() {
    this.logs = {
      search: [],
      selectedDrug: []
    };
  }
  checkCartItemType() {
    const testType = this.cart.find((element: any) => element.itemType === 'TEST');
    const drugType = this.cart.find((element: any) => element.itemType === 'DRUG' || element.itemType === 'PRESCRIPTION');
    return {
      testType, drugType
    }
  }
  updateDeliveryFee(deliveryFee: number) {
    const { drugType } = this.checkCartItemType();
    if (!drugType) return
    this.cartTotals = {
      ...this.cartTotals,
      deliveryFee,
    };
    storage.set(cartPricing, JSON.stringify(this.cartTotals));
  }
  *sendPrescription(file: File, message: string) {
    this.isUploading = true;
    try {
      const { data } = yield uploadFile(file);
      toast.success(`${message} uploaded successfully`, {
        position: 'top-right'
      });
      return data;
    } catch (error) {
      toast.error('An error occured while uploading prescription');
    } finally {
      this.isUploading = false;
    }
  }

  *getS3BucketFile(key: string) {
    this.isDownloading = true;
    try {
      const { data } = yield getFile(key);
      window.open(data, "_blank");
      return data;
    } catch (error) {
      toast.error('An error occured while getting prescription');
    } finally {
      this.isDownloading = false;
    }
  }
}
