import { Box } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useStore } from 'store';
import { observer } from 'mobx-react-lite';
import AdminNavbar from 'components/navbar/adminNavbar';
import { useAdminInfo } from 'hooks/queryHooks';

const AdminLayout = () => {
  const navigate = useNavigate();
  const {
    AuthStore: { user, updatePermissions }
  } = useStore();

  const { data } = useAdminInfo();

  useEffect(() => {
    !user?.role && navigate('/');
    if (data) {
      updatePermissions(data as IRoleFeature[]);
    }
  }, [user, data]);

  return (
    <Box>
      <AdminNavbar />
      <Box h="100%" w="100vw" overflowY="scroll">
        <Outlet />
      </Box>
    </Box>
  );
};

export default observer(AdminLayout);
