import { Box, Flex, keyframes } from '@chakra-ui/react';
import { ReactComponent as LoaderIcon } from '../../assets/images/loader-icon.svg';
import { ReactComponent as SpinningIcon } from '../../assets/images/spinning-icon.svg';


const Loading = () => {
  const animation = keyframes`from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);

  }`;
  return (
    <Flex h="100%" w="100%" alignItems="center" justifyContent="center">
      <Box pos="relative">
        <LoaderIcon width="2.5rem" />
        <Box
          sx={{
            position: 'absolute',
            left: '-9px',
            top: '25px',
            animation: `${animation} 2s infinite linear`
          }}
        >
          <SpinningIcon width="2rem" />
        </Box>
      </Box>
    </Flex>
  );
};

export default Loading;

