import { client } from '..';
import config from '../../config';

const generateCouponCode = async (): Promise<ApiResponse<{ couponCode: string }> | undefined> => {
  return await client<ApiResponse<{ couponCode: string }>>(
    `${config.AUTH_BASE_URL}/api/payment/generate-coupon`,
    'GET'
  );
};

const getCoupon = async (coupon: string): Promise<ApiResponse<string> | undefined> => {
  return await client<ApiResponse<string>>(
    `${config.AUTH_BASE_URL}/api/payment/coupon/${coupon}`,
    'GET'
  );
};
const getAllCoupons = async (
  params: GetAllCouponsParams
): Promise<ApiResponse<any> | undefined> => {
  const { page, pageSize, status, couponType, searchKey } = params;
  return await client<ApiResponse<any>>(
    `${config.AUTH_BASE_URL}/api/payment/get-all-coupons?page=${page}&pageSize=${pageSize}&status=${status}&couponType=${couponType}&searchKey=${searchKey}`,
    'GET'
  );
};

const postCoupon = async (body: ICoupon): Promise<ApiResponse<string> | undefined> => {
  return await client<ApiResponse<string>>(
    `${config.AUTH_BASE_URL}/api/payment/create-coupon`,
    'POST',
    {
      body
    }
  );
};

const editCoupon = async (
  body: ICoupon,
  coupon: string
): Promise<ApiResponse<string> | undefined> => {
  return await client<ApiResponse<string>>(
    `${config.AUTH_BASE_URL}/api/payment/edit-coupon/${coupon}`,
    'PUT',
    { body }
  );
};

const updateCouponStatus = async (coupon: string): Promise<ApiResponse<string> | undefined> => {
  return await client<ApiResponse<string>>(
    `${config.AUTH_BASE_URL}/api/payment/update-coupon-status/${coupon}`,
    'PUT'
  );
};

const deleteCoupon = async (coupon: string): Promise<ApiResponse<string> | undefined> => {
  return await client<ApiResponse<string>>(
    `${config.AUTH_BASE_URL}/api/payment/coupon/${coupon}`,
    'DELETE'
  );
};

const customerCouponValidate = async (
  couponCode: string,
  email: string
): Promise<ApiResponse<ICoupon> | undefined> => {
  return await client<ApiResponse<ICoupon>>(
    `${config.AUTH_BASE_URL}/api/payment/validate-coupon/${couponCode}/${email ?? ''}`,
    'GET'
  );
};

const couponUsageHistory = async (couponCode: string): Promise<ApiResponse<any> | undefined> => {
  return await client<ApiResponse<any>>(
    `${config.AUTH_BASE_URL}/api/payment/coupon/history/${couponCode}/usage`,
    'GET'
  );
}

export {
  generateCouponCode,
  getCoupon,
  getAllCoupons,
  postCoupon,
  editCoupon,
  updateCouponStatus,
  deleteCoupon,
  customerCouponValidate,
  couponUsageHistory
};
