import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import toast, { ToastBar, Toaster } from 'react-hot-toast';
import { GoogleOAuthProvider } from '@react-oauth/google';
import config from '../src/config';
import App from 'App';
import ErrorBoundary from 'layouts/ErrorLayout';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <GoogleOAuthProvider clientId={config.googleClientId as string}>
    <React.StrictMode>
      <ErrorBoundary>
        <Toaster reverseOrder={false} gutter={8}>
          {(t) => (
            <ToastBar toast={t}>
              {({ icon, message }) => (
                <>
                  <span onClick={() => t.type !== 'loading' && toast.dismiss(t.id)}> {icon}</span>
                  {message}
                </>
              )}
            </ToastBar>
          )}
        </Toaster>
        <App />
      </ErrorBoundary>
    </React.StrictMode>
  </GoogleOAuthProvider>
);

reportWebVitals();
