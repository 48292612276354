import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

// layouts
import CustomerLayout from '../layouts/clientLayout/';

// components
import Verify from '../domains/verify';

const ClientDashboard = lazy(() => import('../domains/clientDashboard'));
const Dashboard = lazy(() => import('../domains/clientDashboard/dashboard'));
const Orders = lazy(() => import('../domains/clientDashboard/orders'));
const Account = lazy(() => import('../domains/clientDashboard/account'));
const PlaceOrder = lazy(() => import('../domains/clientDashboard/placeanorder'));
const Members = lazy(() => import('../domains/clientDashboard/members'));
const Reports = lazy(() => import('../domains/clientDashboard/reports'));
const Wallet = lazy(() => import('../domains/clientDashboard/wallets'));
const CheckoutOrderPage = lazy(
  () => import('../domains/clientDashboard/placeanorder/components/CheckoutOrderPage')
);
const ContactUs = lazy(() => import('../domains/clientDashboard/contact'));
const OrderPlaced = lazy(() => import('../domains/clientDashboard/order-placed'));
const CartPage = lazy(() => import('../domains/clientDashboard/cartPage'));
const WellnessCalculator = lazy(() => import('../domains/clientDashboard/wellness'));
const WellnessHome = lazy(() => import('../domains/clientDashboard/wellness/components'));
const BmiChecker = lazy(() => import('../domains/clientDashboard/wellness/components/bmi'));
const BmiResult = lazy(
  () => import('../domains/clientDashboard/wellness/components/bmi/BmiResult')
);
const BmiGoal = lazy(() => import('../domains/clientDashboard/wellness/components/bmi/BmiGoal'));

const BookTest = lazy(() => import('../domains/clientDashboard/book-test'));
const BookTestHome = lazy(() => import('../domains/clientDashboard/book-test/components'));
const TestPackages = lazy(
  () => import('../domains/clientDashboard/book-test/components/testPackages/TestPackages')
);
const TestPackageView = lazy(
  () => import('../domains/clientDashboard/book-test/components/testPackages/TestPackageView')
);
const TestStore = lazy(
  () => import('../domains/clientDashboard/book-test/components/testStore/TestStore')
);
const TestStoreView = lazy(
  () => import('../domains/clientDashboard/book-test/components/testStore/TestStoreView')
);

const CalorieCalculator = lazy(
  () => import('domains/clientDashboard/wellness/components/calorie_checker/calorieChecker')
);
const CalorieResult = lazy(
  () => import('domains/clientDashboard/wellness/components/calorie_checker/CalorieResult')
);
const OvulationChecker = lazy(
  () => import('../domains/clientDashboard/wellness/components/ovulation/index')
);
const OvulationResult = lazy(
  () => import('../domains/clientDashboard/wellness/components/ovulation/ovulationResult')
);

const Consultations = lazy(() => import('../domains/clientDashboard/consultations/Consultations'));
const TalktoDoctor = lazy(() => import('../domains/clientDashboard/consultations/TalktoDoctor'));

const routes: RouteObject[] = [
  {
    path: '',
    element: <CustomerLayout />,
    children: [
      {
        path: '',
        element: <ClientDashboard />,
        children: [
          {
            index: true,
            element: <PlaceOrder />
          },
          {
            path: 'members',
            element: <Members />
          },
          {
            path: 'reports',
            element: <Reports />
          },
          {
            path: 'my-orders',
            element: <Dashboard />
          },
          {
            path: 'book-consultation',
            children: [
              {
                index: true,
                element: <Consultations />
              },
              {
                path: 'talk-to-doctor',
                element: <TalktoDoctor />
              }
            ]
          },
          {
            path: 'orders/:status',
            element: <Orders />
          },
          {
            path: 'cart',
            element: <CartPage />
          },
          {
            path: 'customer-wallet',
            element: <Wallet />
          },
          {
            path: 'tests',
            element: <BookTest />,
            children: [
              {
                path: '',
                element: <BookTestHome />
              },
              {
                path: 'test-packages',
                children: [
                  {
                    index: true,
                    element: <TestPackages />,
                    caseSensitive: true
                  },
                  {
                    path: ':id',
                    element: <TestPackageView />,
                    caseSensitive: true
                  }
                ],
                caseSensitive: true
              },
              {
                path: 'test-items',
                caseSensitive: true,
                children: [
                  {
                    index: true,
                    element: <TestStore />,
                    caseSensitive: true
                  },
                  {
                    path: ':id',
                    element: <TestStoreView />,
                    caseSensitive: true
                  }
                ]
              }
            ]
          },
          {
            path: 'wellness-calculator',
            element: <WellnessCalculator />,
            children: [
              {
                index: true,
                element: <WellnessHome />
              },
              {
                path: 'bmi-checker',
                children: [
                  {
                    index: true,
                    element: <BmiChecker />,
                    caseSensitive: true
                  },
                  {
                    path: 'result',
                    element: <BmiResult />
                  },
                  {
                    path: 'goal',
                    element: <BmiGoal />
                  }
                ],
                caseSensitive: true
              },
              {
                path: 'ovulation-checker',
                caseSensitive: true,
                children: [
                  {
                    index: true,
                    element: <OvulationChecker />
                  },
                  {
                    path: 'ovulation-result',
                    element: <OvulationResult />
                  }
                ]
              },
              {
                path: 'calorie-checker',
                caseSensitive: true,
                children: [
                  {
                    index: true,
                    element: <CalorieCalculator />,
                    caseSensitive: true
                  },
                  {
                    path: 'calorie-result',
                    element: <CalorieResult />
                  }
                ]
              }
            ]
          },
          {
            path: 'wellness-calculator',
            element: <WellnessCalculator />
          }
        ]
      },
      {
        path: 'account',
        element: <Account />
      },
      {
        path: '/place-order/address',
        element: <CheckoutOrderPage />
      },
      {
        path: 'contact',
        element: <ContactUs />
      },
      {
        path: 'verify',
        element: <Verify />
      },
      {
        path: 'order-placed',
        children: [
          {
            path: ':link',
            element: <OrderPlaced />,
            caseSensitive: true
          }
        ],
        caseSensitive: true
      },
    ]
  }
];

export default routes;
