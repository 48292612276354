import { extendTheme, ThemeConfig } from '@chakra-ui/react';
import { customComponents as components } from './components';
import colors from './colors';

const fonts = {
  heading: 'Rubik, sans-serif',
  body: 'Rubik, sans-serif',
};

export const config: ThemeConfig = {
  cssVarPrefix: 'pr',
};

export const PrChakraTheme = extendTheme({
  textStyles: {
    p: {
      fontSize: '16px',
    },
  },
  colors,
  fonts,
  components: {
    ...components,
  },
  config,
  
});

export default PrChakraTheme;
