import { action, makeObservable, observable } from 'mobx';
import toast from 'react-hot-toast';
import { calculatorBmi, calculatorOvulation, calculateCalorie } from 'requests/wellness';
import { RootStore } from 'store';
import { parseError } from 'utils';

export class WellnessStore {
  rootStore: RootStore;
  isLoading = {
    getBmi: false,
    getCalorie: false,
    getOvulation: false
  };
  calorieResults = {
    gainWeight: {
      calories: '',
      goal: '',
      range: '',
      rate: ''
    },
    maintainWeight: {
      calories: '',
      goal: '',
      range: '',
      rate: ''
    },
    loseWeight: {
      calories: '',
      goal: '',
      range: '',
      rate: ''
    }
  };
  bmiResult = {
    bmi: '',
    weightCategory: ''
  };
  ovulationResults = {
    ovulationDate: '',
    fertileFromDate: '',
    fertileUntilDate: '',
    nextPeriodDate: ''
  } as IOvulationResult;
  constructor(rootStore: RootStore) {
    makeObservable(this, {
      isLoading: observable,
      bmiResult: observable,
      calorieResults: observable,
      ovulationResults: observable,

      setBmiResult: action.bound,
      calculatorBmi: action.bound,
      calculatorOvulation: action.bound,
      calculateCalorie: action.bound
    });
    this.rootStore = rootStore;
  }

  setBmiResult(bmi = '', weightCategory = '') {
    if (bmi && weightCategory) {
      this.bmiResult = { bmi, weightCategory };
    } else {
      this.bmiResult = { bmi: '', weightCategory: '' };
    }
  }

  async calculatorBmi(data: IBmiDetails) {
    this.isLoading.getBmi = true;
    this.setBmiResult();
    try {
      const res = await calculatorBmi(data);
      if (res?.status) {
        toast.success(`${res.message}`, { position: 'top-right' });
        this.setBmiResult(res?.data?.bmi as unknown as string, res?.data?.weightCategory);
      }
      return res;
    } catch (error) {
      toast.error(parseError(error), { position: 'top-right' });
    } finally {
      this.isLoading.getBmi = false;
    }
  }

  async calculatorOvulation(data: IOvulationDetails) {
    this.isLoading.getOvulation = true;
    try {
      const res = await calculatorOvulation(data);
      if (res?.status) {
        toast.success(`${res.message}`, { position: 'top-right' });

        this.ovulationResults = {
          ovulationDate: res?.data?.ovulationDate as unknown as string,
          fertileFromDate: res?.data?.fertileFromDate as unknown as string,
          fertileUntilDate: res?.data?.fertileUntilDate as unknown as string,
          nextPeriodDate: res?.data?.nextPeriodDate as unknown as string
        } as IOvulationResult;
      }
      return res;
    } catch (error) {
      toast.error(parseError(error), { position: 'top-right' });
    } finally {
      this.isLoading.getOvulation = false;
    }
  }

  async calculateCalorie(data: ICalorieDetails) {
    this.isLoading.getCalorie = true;
    try {
      const res = await calculateCalorie(data);
      if (res?.status) {
        toast.success(`Your result is calculated`, { position: 'top-right' });
        this.calorieResults = {
          gainWeight: {
            calories: res?.data?.gainWeight?.calories as unknown as string,
            goal: res?.data?.gainWeight?.goal as unknown as string,
            range: res?.data?.gainWeight?.range as unknown as string,
            rate: res?.data?.gainWeight?.rate as unknown as string
          },
          maintainWeight: {
            calories: res?.data?.maintainWeight?.calories as unknown as string,
            goal: res?.data?.maintainWeight?.goal as unknown as string,
            range: res?.data?.maintainWeight?.range as unknown as string,
            rate: res?.data?.maintainWeight?.rate as unknown as string
          },
          loseWeight: {
            calories: res?.data?.loseWeight?.calories as unknown as string,
            goal: res?.data?.loseWeight?.goal as unknown as string,
            range: res?.data?.loseWeight?.range as unknown as string,
            rate: res?.data?.loseWeight?.rate as unknown as string
          }
        };
      }
      return res;
    } catch (error) {
      toast.error(parseError(error), { position: 'top-right' });
    } finally {
      this.isLoading.getCalorie = false;
    }
  }
}
