import { Flex, Box, Image, Text, Button, ModalFooter } from '@chakra-ui/react';
import PRModal from 'components/modal';
import { observer } from 'mobx-react-lite';
import acctPic from 'assets/images/acctPic.svg';
import { useStore } from 'store';
import { parseAmount } from 'utils';
import { useNavigate } from 'react-router-dom';
import Permissions from 'utils/permissions';
import { Routes } from 'constant';

const ViewPartnerMemberDetails = () => {
  const navigate = useNavigate();
  const {
    PartnerStore: {
      isViewPartnerMember,
      partnerMember,
      toggleViewPartnerMemberOrders,
      toggleAddPartnerMember,
      toggleViewPartnerMember
    },
    AuthStore
  } = useStore();
  const permissions = new Permissions(AuthStore);

  const {
    firstName,
    lastName,
    email,
    city,
    state,
    phoneNumber,
    businessId,
    _id,
    street,
    landmark,
    createdAt,
    updatedAt,
    itemsOrdered,
    partnerId
  } = partnerMember as any;
  const timeStamp = new Date(createdAt as Date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  });
  const timeStamp2 = new Date(updatedAt as Date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  });
  return (
    <PRModal
      title="Member details"
      open={isViewPartnerMember}
      onClose={() => toggleViewPartnerMember(_id)}
      width="38%"
    >
      <Box
        h="75vh"
        overflowY="scroll"
        sx={{
          '&::-webkit-scrollbar': {
            display: 'none'
          }
        }}
      >
        <Flex justify="center" align="center" py="1rem">
          <Image src={acctPic} alt="account" loading="lazy" />
        </Flex>
        <Text
          fontSize={{
            base: '14px',
            md: '16px'
          }}
          fontWeight="500"
        >
          MEMBER INFORMATION
        </Text>
        <Box w="100%" border="1px solid #EDEDED" p=".5rem">
          <Flex
            w="100%"
            direction="row"
            justify="space-between"
            px="1.8rem"
            gap={{ base: '1rem' }}
            align="right"
          >
            <Flex direction="column" justify="space-between" mr="-3rem">
              <Box
                fontSize={{
                  base: '12px',
                  md: '14px'
                }}
                mb=".5rem"
              >
                <Text color="#676C7B"> Member's name</Text>
                <Text>
                  {firstName} {lastName}{' '}
                </Text>
              </Box>
              <Box
                fontSize={{
                  base: '12px',
                  md: '14px'
                }}
                mb=".5rem"
              >
                <Text color="#676C7B">Email Address</Text>
                <Text>{email}</Text>
              </Box>
              <Box
                fontSize={{
                  base: '12px',
                  md: '14px'
                }}
                mb=".5rem"
                w="50%"
              >
                <Text color="#676C7B">Delivery Address</Text>
                <Text>
                  {street}, {city}, {state}
                </Text>
              </Box>
              <Box fontSize={{ base: '12px', md: '14px' }} mb=".5rem">
                <Text color="#676C7B">Date Added</Text>
                <Text>{timeStamp ? timeStamp : timeStamp2}</Text>
              </Box>
            </Flex>
            <Flex direction="column" justify="space-between" ml="-3rem">
              <Box
                fontSize={{
                  base: '12px',
                  md: '14px'
                }}
                mb=".5rem"
              >
                <Text color="#676C7B">Member ID</Text>
                <Text color="primary.main">{businessId || 'N/A'}</Text>
              </Box>
              <Box
                fontSize={{
                  base: '12px',
                  md: '14px'
                }}
                mb=".5rem"
              >
                <Text color="#676C7B">Phone number</Text>
                <Text>{phoneNumber}</Text>
              </Box>
              <Box fontSize={{ base: '12px', md: '14px' }} mb=".5rem">
                <Text color="#676C7B">Landmark</Text>
                <Text>{landmark}</Text>
              </Box>
              <Box fontSize={{ base: '12px', md: '14px' }} mb=".5rem">
                <Text color="#676C7B">Number of Orders</Text>
                <Text>{parseAmount(itemsOrdered ?? 0)}</Text>
              </Box>
            </Flex>
          </Flex>
        </Box>
      </Box>
      <ModalFooter
        sx={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          bg: 'white'
        }}
        position="absolute"
        bottom="0"
        left="0"
        w="100%"
      >
        <Flex gap=".5rem" justify="end" w="100%">
          <Button
            size={{
              base: 'sm',
              md: 'md'
            }}
            onClick={() => {
              toggleViewPartnerMember(_id as string);
              toggleViewPartnerMemberOrders(true);
              navigate(Routes.AdminMemberOrderHistory(partnerId as string, _id as string));
            }}
            variant="outline"
          >
            View order history
          </Button>
          {permissions.hasFeatureAccess('CanAddPartnerMembers') && (
            <Button
              size={{
                base: 'sm',
                md: 'md'
              }}
              onClick={() => {
                toggleViewPartnerMember(_id as string);
                toggleAddPartnerMember(_id as string, true);
              }}
            >
              Edit Member
            </Button>
          )}
        </Flex>
      </ModalFooter>
    </PRModal>
  );
};

export default observer(ViewPartnerMemberDetails);
