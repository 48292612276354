import { action, flow, makeObservable, observable } from 'mobx';
import { RootStore } from '..';
import toast from 'react-hot-toast';
import { parseError } from 'utils';
import {
  getWallet,
  topUpWallet,
  deductWallet,
  adminDeductFromWallet
} from 'requests/transactions';
import { makePayment, verifyPayment } from 'requests/order';
import Alert from 'utils/Alert';
import React from 'react';

export class FinanceStore {
  wallet = {} as IWallet;
  transactions: ITransaction[] = [];
  selectedTransaction = {};
  isTopUpModalOpen = false;
  isWalletPaymentSuccessModalOpen = false;
  isRequestPayoutModalOpen = false;
  isWithDrawalProcessingModalOpen = false;
  isTransactionReceiptModalOpen = false;
  filterTransactionOptions = {
    balanceStatus: [],
    transactionType: []
  } as Record<string, Array<string>>;
  deductionForm = {
    amount: 0,
    reason: ''
  };
  size = 10;
  page = 1;
  isLoadingState = {
    getWallet: false,
    topUpWallet: false,
    initiatePayment: false,
    verifyPayment: false,
    deductWallet: false
  };
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeObservable(this, {
      wallet: observable,
      deductionForm: observable,
      transactions: observable,
      selectedTransaction: observable,
      isLoadingState: observable,
      isTopUpModalOpen: observable,
      isWalletPaymentSuccessModalOpen: observable,
      isRequestPayoutModalOpen: observable,
      isWithDrawalProcessingModalOpen: observable,
      isTransactionReceiptModalOpen: observable,
      page: observable,
      size: observable,
      filterTransactionOptions: observable,

      setSelectedTransaction: action.bound,
      toggleOpenTopUpModal: action.bound,
      toggleWalletPaymentSuccessModal: action.bound,
      toggleRequestPayoutModal: action.bound,
      toggleWithDrawalProcessingModal: action.bound,
      toggleTransactionReceiptModal: action.bound,
      setDeduction: action.bound,
      setCurrentPage: action.bound,
      setPageLimit: action.bound,
      setFilterTransactionOptions: action.bound,
      setAllFilterTransactionOptions: action.bound,
      resetFilterTransactionOptions: action.bound,

      getWalletById: flow.bound,
      topUpWallet: flow.bound,
      deductFromWallet: flow.bound,
      initiatePayment: flow.bound,
      verifyWalletPayment: flow.bound,
      deductWallet: flow.bound
    });
    this.rootStore = rootStore;
  }
  setSelectedTransaction = (transaction: ITransaction) => {
    this.selectedTransaction = transaction;
  };

  setFilterTransactionOptions = (option: string, id: string) => {
    if (!this.filterTransactionOptions[id]) {
      this.filterTransactionOptions[id] = [];
    }

    if (this.filterTransactionOptions[id].includes(option)) {
      this.filterTransactionOptions[id] = this.filterTransactionOptions[id].filter(
        (item: string) => item !== option
      );
    } else {
      this.filterTransactionOptions[id].push(option);
    }
  };
  setAllFilterTransactionOptions = (option: string, id: string, checked?: boolean) => {
    if (!this.filterTransactionOptions[id]) {
      this.filterTransactionOptions[id] = [];
    }

    if (checked && !this.filterTransactionOptions[id].includes(option)) {
      this.filterTransactionOptions[id].push(option);
    } else {
      this.filterTransactionOptions[id] = [];
    }
  };
  resetFilterTransactionOptions() {
    this.filterTransactionOptions = {
      balanceStatus: [],
      transactionType: []
    };
  }
  setCurrentPage(page: number) {
    this.page = page;
  }

  setPageLimit(size: number) {
    this.size = size;
  }
  toggleTransactionReceiptModal(orderNumber: string) {
    this.rootStore.OrderStore.selectedOrder = this.rootStore.OrderStore.orders.find(
      (order) => order.orderNumber === orderNumber
    ) as IOrder;
    this.isTransactionReceiptModalOpen = !this.isTransactionReceiptModalOpen;
  }
  toggleOpenTopUpModal() {
    this.isTopUpModalOpen = !this.isTopUpModalOpen;
  }

  toggleRequestPayoutModal() {
    this.isRequestPayoutModalOpen = !this.isRequestPayoutModalOpen;
  }

  toggleWalletPaymentSuccessModal() {
    this.isWalletPaymentSuccessModalOpen = !this.isWalletPaymentSuccessModalOpen;
  }

  toggleWithDrawalProcessingModal() {
    this.isWithDrawalProcessingModalOpen = !this.isWithDrawalProcessingModalOpen;
  }

  *getWalletById(userId: any) {
    this.isLoadingState.getWallet = true;
    try {
      const response = (yield getWallet(userId)) as ApiResponse<IWallet | any>;
      if (response.status) {
        this.wallet = response.data;
      }
    } catch (error) {
      toast.error(parseError(error));
    } finally {
      this.isLoadingState.getWallet = false;
    }
  }

  *deductWallet(payload: IDeductWallet, setAmount: React.Dispatch<React.SetStateAction<string>>) {
    this.isLoadingState.deductWallet = true;
    try {
      const res = (yield deductWallet(payload)) as ApiResponse<any>;
      if (res && res?.status) {
        this.wallet = res.data?.updatedWallet;
        this.toggleWithDrawalProcessingModal();
        this.toggleRequestPayoutModal();
        setAmount('');
      }
    } catch (error) {
      toast.error(parseError(error));
    } finally {
      this.isLoadingState.deductWallet = false;
    }
  }

  *initiatePayment(amount: number) {
    try {
      this.isLoadingState.initiatePayment = true;
      const query = `finance/wallet/initiate-payment`;
      const res = (yield makePayment(query, {
        amount: amount
      })) as ApiResponse<{
        paymentUrl: string;
      }>;
      Alert.info({ message: res.message });
      window.location.href = res.data?.paymentUrl as string;
    } catch (error) {
      Alert.error({ message: parseError(error) });
    } finally {
      this.isLoadingState.initiatePayment = false;
    }
  }

  *verifyWalletPayment(query: string) {
    try {
      this.isLoadingState.verifyPayment = true;
      const res = ((yield verifyPayment(query)) as ApiResponse<string>) || undefined;
      if (res && res.status) {
        Alert.success({ message: res.message });
        this.toggleWalletPaymentSuccessModal();
      }
    } catch (error) {
      Alert.error({ message: parseError(error) });
    } finally {
      this.isLoadingState.verifyPayment = false;
    }
  }

  *topUpWallet(userId: string, amount: number, topupReason?: string, orderNumber?: string) {
    this.isLoadingState.topUpWallet = true;
    try {
      const response = (yield topUpWallet(
        userId,
        amount,
        orderNumber,
        topupReason
      )) as ApiResponse<any>;
      if (response?.status) {
        toast.success(`${response.message}`, {
          position: 'top-right'
        });
        this.getWalletById(userId);
      } else {
        toast.error(`${response.message}`, {
          position: 'top-right'
        });
      }
    } catch (error: any) {
      toast.error(parseError(error?.response?.message), {
        position: 'top-right'
      });
    } finally {
      this.isLoadingState.topUpWallet = false;
    }
  }

  *deductFromWallet(userId: string, amount: number, topupReason: string) {
    this.isLoadingState.deductWallet = true;
    try {
      const response = (yield adminDeductFromWallet(userId, {
        amount,
        reason: topupReason,
        userType: 'admin'
      })) as ApiResponse<any>;
      if (response?.status) {
        toast.success(`${response.message}`, {
          position: 'top-right'
        });
        this.setDeduction();
        this.getWalletById(userId);
      } else {
        toast.error(`${response.message}`, {
          position: 'top-right'
        });
      }
    } catch (error: any) {
      toast.error(parseError(error?.response?.message), {
        position: 'top-right'
      });
    } finally {
      this.isLoadingState.deductWallet = false;
    }
  }

  setDeduction(payload?: { key: string; value: string }) {
    if (!payload) {
      this.deductionForm = {
        amount: 0,
        reason: ''
      };
    } else {
      const { key, value } = payload;
      this.deductionForm = {
        ...this.deductionForm,
        [key]: key === 'amount' ? parseFloat(value) : value
      };
    }
  }
}
