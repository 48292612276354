import { action, makeObservable, observable } from 'mobx';
import { RootStore } from '..';

export class RunnerStore {
  runners: ISelectedUserInfo[] = [];
  selectedRunner = {} as IRunners;
  roles: IRole[] = [];
  role = <IRole>{};
  selectedModule: string | null = null;
  isCreateRunnerModalOpen = false;
  isCreateRoleModalOpen = false;
  isCustomerRunnerDetailsModalOpen = false;
  isStatusRunnerModalOpen = false;
  newRunner = '';
  newRole = '';
  editRunner = '' as string;
  runnerForm = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    role: '',
    userId: ''
  };
  roleForm = {
    name: '',
    description: '',
    permissions: []
  };
  currentPage = 1;
  pageLimit = 10;
  params = {
    searchKey: '',
    role: ''
  } as IParams;
  isLoading = {
    getAllRunners: false,
    getAppFeatures: false,
    getAllRoles: false,
    getRola: false,
    createRunner: false,
    createRole: false,
    updateRunner: false,
    updateRole: false,
    updateUserActivationStatus: false
  };
  appFeatures: IRoleFeature[] = [];

  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeObservable(this, {
      runners: observable,
      selectedRunner: observable,
      newRunner: observable,
      newRole: observable,
      editRunner: observable,
      runnerForm: observable,
      roleForm: observable,
      isLoading: observable,
      isCreateRunnerModalOpen: observable,
      isCreateRoleModalOpen: observable,
      isCustomerRunnerDetailsModalOpen: observable,
      isStatusRunnerModalOpen: observable,
      params: observable,
      selectedModule: observable,
      appFeatures: observable,
      currentPage: observable,
      pageLimit: observable,

      setSelectedModule: action.bound,
      setRunnerForm: action.bound,
      setRoleForm: action.bound,
      setNewRunner: action.bound,
      setEditRunner: action.bound,
      toggleCreateRunnerModal: action.bound,
      toggleCustomerRunnerDetailsModal: action.bound,
      toggleStatusRunnerModal: action.bound,
      toggleSelectedModule: action.bound,
      setCurrentPage: action.bound,
      setPageLimit: action.bound
    });
    this.rootStore = rootStore;
  }
  setCurrentPage(page: number) {
    this.currentPage = page;
  }

  setPageLimit(limit: number) {
    this.pageLimit = limit;
  }
  toggleSelectedModule(item: string | null) {
    if (this.selectedModule === item) {
      this.selectedModule = null;
    } else {
      this.selectedModule = item;
    }
  }
  setSelectedModule(item: any) {
    this.selectedModule = item;
  }
  setEditRunner(id: string) {
    this.editRunner = id;
  }

  setNewRunner(value: string) {
    this.newRunner = value;
  }

  setRunnerForm(user: Record<string, any>) {
    this.runnerForm = {
      firstName: user?.personalInformation.firstName,
      lastName: user?.personalInformation?.lastName,
      email: user.email,
      phoneNumber: user?.personalInformation?.phoneNumber,
      role: user?.role?._id,
      userId: user.userId
    };
  }

  setRoleForm(payload: IRole) {
    this.roleForm = {
      name: payload.name,
      description: payload.description,
      permissions: payload.permissions as any
    };
  }

  toggleCustomerRunnerDetailsModal(runner?: IRunners) {
    this.isCustomerRunnerDetailsModalOpen = !this.isCustomerRunnerDetailsModalOpen;
    this.selectedRunner = runner as IRunners;
  }

  toggleCreateRunnerModal() {
    if (!this.isCreateRunnerModalOpen) {
      this.newRunner = 'newRunner';
    } else {
      this.newRunner = '';
      this.runnerForm = {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        role: '',
        userId: ''
      };
    }
    this.isCreateRunnerModalOpen = !this.isCreateRunnerModalOpen;
  }

  toggleStatusRunnerModal(runner?: IRunners) {
    this.isStatusRunnerModalOpen = !this.isStatusRunnerModalOpen;
    if (runner) {
      this.selectedRunner = runner;
    }
  }
}
