import config from '../../config';
import { client } from '..';

export const getAllRunners = async (params: {
  pageNumber: number,
  pageSize: number,
  searchKey: string;
  role: string;
}): Promise<ApiResponse<IGetAllRunners | null> | undefined> => {
  const { searchKey, role, pageNumber, pageSize } = params;
  return await client<ApiResponse<IGetAllRunners | null>>(
    `${config.AUTH_BASE_URL}/api/user/runners/all/${pageNumber}/${pageSize}?searchKey=${searchKey}&role=${role}`,
    'GET'
  );
};

export const createRunner = async (
  body: RunnerInterface
): Promise<ApiResponse<RunnerInterface | null> | undefined> => {
  return await client<ApiResponse<RunnerInterface | null>>(
    `${config.AUTH_BASE_URL}/api/auth/register/admin`,
    'POST',
    { body }
  );
};

export const updateRunner = async (
  body: RunnerInterface
): Promise<ApiResponse<RunnerInterface | null> | undefined> => {
  return await client<ApiResponse<RunnerInterface | null>>(
    `${config.AUTH_BASE_URL}/api/user/update-record`,
    'POST',
    { body }
  );
};

export const updateUserActivationStatus = async (
  userId: string,
  status: boolean,
  statusReason: string
): Promise<ApiResponse<RunnerInterface | null> | undefined> => {
  return await client<ApiResponse<RunnerInterface | null>>(
    `${config.AUTH_BASE_URL}/api/user/update-user-status`,
    'PUT',
    { body: { userId, status, statusReason } }
  );
};

//TODO: change response interface

export const getAppFeatures = async (): Promise<ApiResponse<IRoleFeature[] | null> | undefined> => {
  return await client<ApiResponse<any[] | null>>(
    `${config.AUTH_BASE_URL}/api/role/permissions/all`,
    'GET'
  );
};

export const getAllRoles = async (): Promise<ApiResponse<IRole[] | null> | undefined> => {
  return await client<ApiResponse<IRole[] | null>>(`${config.AUTH_BASE_URL}/api/role`, 'GET');
};

export const getRole = async (id: string): Promise<ApiResponse<IRole | null> | undefined> => {
  return await client<ApiResponse<IRole | null>>(`${config.AUTH_BASE_URL}/api/role/${id}`, 'GET');
};

export const getPaginatedRoles = async (payload: {
  size: number;
  page: number;
}): Promise<ApiResponse<any[] | null> | undefined> => {
  return await client<ApiResponse<any[] | null>>(
    `${config.AUTH_BASE_URL}/api/role/${payload.page}/${payload.size}`,
    'GET'
  );
};

export const createRole = async (body: IRole): Promise<ApiResponse<any | null> | undefined> => {
  return await client<ApiResponse<any | null>>(`${config.AUTH_BASE_URL}/api/role`, 'POST', {
    body
  });
};

export const updateRole = async (
  body: IRole,
  roleId: string
): Promise<ApiResponse<any | null> | undefined> => {
  return await client<ApiResponse<any | null>>(
    `${config.AUTH_BASE_URL}/api/role/${roleId}`,
    'PUT',
    { body }
  );
};

export const updateRoleStatus = async (
  userId: string,
  status: boolean,
  statusReason: string
): Promise<ApiResponse<any | null> | undefined> => {
  return await client<ApiResponse<any | null>>(`${config.AUTH_BASE_URL}/api/role`, 'PUT', {
    body: { userId, status, statusReason }
  });
};
