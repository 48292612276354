import {
  Flex,
  Checkbox,
  Divider,
  Button,
  Text,
  Box,
  Heading,
  Input,
  InputGroup,
  InputRightElement
} from '@chakra-ui/react';
import { useStore } from 'store';
import toast from 'react-hot-toast';
import { observer } from 'mobx-react-lite';
import { Routes } from 'constant';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useValidateCoupon } from 'hooks/queryHooks';
import { InfoIcon } from '@chakra-ui/icons';
import AdminCart from '.';
import { parseAmount } from 'utils';
import EmptyCart from 'components/cart/emptycart';
import { isValidLagosCity } from 'utils/helpers';

const CartItemTotal = () => {
  const navigate = useNavigate();
  const { id } = useParams()
  const {
    CheckoutStore: {
      deliveryAddress,
      orderAdminCheckout,
      adminPartnerCheckout,
      thirdPartyOrder,
      recipientPay,
      setRecipientPay,
      validatedCoupon,
      setValidatedCoupon,
      setDeliveryAddress,
      toggleGuestAddressForm,
      toggleDeliveryAddressCard
    },
    CartStore: {
      loading,
      cart,
      cartTotals: { itemsTotal, deliveryFee, sampleCollectionFee },
      checkCartItemType
    },
    UserStore: { selectedUser, selectUserProfile, getUserById },
  } = useStore();

  useEffect(() => {
    if (id) {
      getUserById(id as string);
    }
  }, [id]);

  const [couponCode, setCouponCode] = useState('');
  const { refetch, isLoading } = useValidateCoupon(couponCode);

  const { testType, drugType } = checkCartItemType();
  let totalAmount = itemsTotal + sampleCollectionFee + deliveryFee;
  const zeroOrder = cart.some((item) => item.totalPrice <= 0);

  const isCommerceCheckout = deliveryAddress.state === 'Lagos' && !zeroOrder;
  const checkAddress =
    JSON.stringify(selectedUser) === '{}' ||
      selectedUser?.status === false ||
      JSON.stringify(selectUserProfile) === '{}' ||
      selectUserProfile?.status
      ? deliveryAddress
      : (deliveryAddress as IAddress);

  const handleCheckout = async () => {
    const isAddressEmpty = Object.entries(checkAddress).some(([key, value]) => {
      return (
        key !== 'latitude' &&
        key !== 'longitude' &&
        (value === null || value === undefined || value === "")
      );
    });
    const hasKeys = Object.keys(checkAddress).length > 0;
    if (isAddressEmpty || !hasKeys) {
      toast.error('Please save your information', {
        position: 'top-right'
      });
      return;
    }
 if (isCommerceCheckout) {
   const { state, city } = deliveryAddress;
   if (city) {
     const isCityValid = isValidLagosCity(state, city);
     if (!isCityValid) {
       toast.error(' Click "Change" to update your delivery information', {
         position: 'top-right'
       });
       return;
     }
   }
 }

    const res =
      id ? await adminPartnerCheckout(id) : await orderAdminCheckout();
    setDeliveryAddress({
      firstName: '',
      lastName: '',
      email: '',
      city: '',
      state: '',
      phoneNumber: '',
      street: '',
      longitude: '',
      latitude: '',
      landmark: ''
    });
    if (!id || !selectedUser?.deliveryAddress) {
      toggleGuestAddressForm()
      toggleDeliveryAddressCard();

    }
    if (res && res.status) {
      navigate(Routes.AdminOrdersList);
      return;
    }
  };

  const calculateDiscount = () => {
    if (validatedCoupon) {
      if (validatedCoupon.couponType === 'percentage') {
        return (validatedCoupon.couponValue / 100) * totalAmount;
      }
      return validatedCoupon.couponValue;
    }
    return 0;
  };
  
  const calculateServiceCharge = () => {
    const serviceCharge = itemsTotal * 0.02;
    totalAmount += serviceCharge;

    return parseAmount(serviceCharge);
  };

  const handleRemoveCoupon = () => {
    setValidatedCoupon(null);
    setCouponCode('');
    toast.error('Coupon removed successfully', {
      position: 'top-right'
    });
  };

  return (
    <>
      {cart.length > 0 ? (
        <Flex mt="5" px="3rem" flexDir="column" gap="1.5rem">
          <Box display={{ base: 'none', md: 'none', lg: 'none' }}>
            <Heading fontSize={{ base: '16px', md: '20px' }}>Your Bag</Heading>
            <AdminCart />
          </Box>

          <Flex justify="space-between">
            <Text fontSize={{ base: '13px', md: '15px' }}>Items total({cart.length})</Text>
            <Text fontSize={{ base: '13px', md: '15px' }}> ₦{itemsTotal}</Text>
          </Flex>

          {drugType && (
            <Flex justify="space-between">
              <Text fontSize={{ base: '13px', md: '15px' }}>Delivery Estimate</Text>
              <Text fontSize={{ base: '13px', md: '15px' }}>
                {deliveryFee && !zeroOrder ? `₦${deliveryFee}` : 'to be updated...'}
              </Text>
            </Flex>
          )}
          {testType && (
            <Flex justify="space-between">
              <Text fontSize={{ base: '13px', md: '15px' }}>Sample Collection Fee</Text>
              <Text fontSize={{ base: '13px', md: '15px' }}>
                {' '}
                {testType ? `₦${sampleCollectionFee}` : ''}
              </Text>
            </Flex>
          )}
          {validatedCoupon && isCommerceCheckout && (
            <Flex flexDirection="column">
              <Flex justify="space-between">
                <Text fontSize={{ base: '13px', md: '15px' }}>Coupon Discount</Text>
                <Text fontSize={{ base: '13px', md: '15px' }}> {`₦${calculateDiscount()}`}</Text>
              </Flex>
              <Text
                fontSize={{ base: '11px' }}
                color="primary.main"
                textDecoration="underline"
                onClick={handleRemoveCoupon}
                cursor="pointer"
              >
                Remove coupon
              </Text>
            </Flex>
          )}

          {isCommerceCheckout && (
            <Flex justify="space-between">
              <Text fontSize={{ base: '13px', md: '15px' }}>Service Charge</Text>
              <Text fontSize={{ base: '13px', md: '15px' }}>{calculateServiceCharge()}</Text>
            </Flex>
          )}

          <Divider />
          <Flex justify="space-between">
            <Text fontSize={{ base: '13px', md: '15px' }}>Total</Text>
            <Text fontSize={{ base: '13px', md: '15px' }}>
              {totalAmount && itemsTotal !== 0
                ? `₦${totalAmount - calculateDiscount()}`
                : 'to be updated'}
            </Text>
          </Flex>
          {checkAddress?.email && (
            <Box>
              {validatedCoupon ? (
                <Box>
                  <Flex align="center" gap="5px">
                    <InfoIcon fontSize={12} color="primary.main" />
                    <Text color="#707070" fontSize="12px">
                      {`Coupon discount ${isCommerceCheckout ? 'has been added to' : 'will be added when costing'
                        } order`}
                    </Text>
                  </Flex>
                  {!isCommerceCheckout && (
                    <Text
                      fontSize={{ base: '11px' }}
                      color="primary.main"
                      textDecoration="underline"
                      onClick={handleRemoveCoupon}
                      cursor="pointer"
                    >
                      Remove coupon
                    </Text>
                  )}
                </Box>
              ) : (
                <InputGroup>
                  <Input
                    placeholder="Have a coupon code?"
                    h="2.875rem"
                    value={couponCode}
                    onChange={(e) => setCouponCode(e.target.value)}
                  />
                  <InputRightElement width="unset" h="2.875rem">
                    <Button
                      borderRadius="0 5px 5px 0"
                      onClick={() => refetch()}
                      isLoading={isLoading}
                      isDisabled={couponCode.length < 1}
                    >
                      Apply
                    </Button>
                  </InputRightElement>
                </InputGroup>
              )}
            </Box>
          )}
          <Divider />
          {thirdPartyOrder && (
            <Checkbox
              name="recipientPay"
              isChecked={recipientPay}
              onChange={setRecipientPay}
              colorScheme="purple"
              mb="1"
            >
              <Text as="span" fontSize={{ base: '13px', md: '15px' }}>
                Allow recipient pay for this order
              </Text>
            </Checkbox>
          )}

          <Button type="submit" isLoading={loading} onClick={handleCheckout} fontSize="14px" disabled={loading}>
            Place Order
          </Button>
        </Flex>
      ) : (
        <EmptyCart />
      )}
    </>
  );
};

export default observer(CartItemTotal);
