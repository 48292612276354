import React, { useState } from 'react';
import { VStack, FormControl, Text, FormErrorMessage, Input, Flex } from '@chakra-ui/react';
import Select from 'react-select';
import CustomToolTip from 'components/customToolTip';

interface StateCitySelectProps {
    values: {
        state: string;
        city: string;
    };
    errors?: {
        state?: string;
        city?: string;
    };
    touched?: {
        state?: boolean;
        city?: boolean;
    };
    handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    setFieldValue?: (field: string, value: any) => void;
    states: Array<{
        name: string;
        value: string;
        cities?: Array<{
            name: string;
            value: string;
        }>;
    }>;
    isCityValid?: boolean;
}

const StateCitySelect: React.FC<StateCitySelectProps> = ({
    values,
    errors,
    touched,
    handleChange,
    setFieldValue,
    states,
    isCityValid
}) => {
    const [cityMenuIsOpen, setCityMenuIsOpen] = useState(false);
    const selectedState = states.find((state) => state.value === values.state);
    const cityOptions = selectedState?.cities?.map((city) => ({
        label: city.name,
        value: city.value,
    })) || [];

    const isCitySelected = cityOptions.some(city => city.value === values.city);
    const customStyles = {
        control: (provided: any) => ({
            ...provided,
            fontSize: '14px', 
        }),
        singleValue: (provided: any) => ({
            ...provided,
            fontSize: '14px',
        }),
        option: (provided: any) => ({
            ...provided,
            fontSize: '14px',
        }),
    };

    return (
        <Flex gap="2rem">
            {/* State Select */}
            <VStack align="start" w="100%">
                <FormControl isInvalid={errors?.state && touched?.state ? true : false}>
                    <FormControl fontSize="14px" fontWeight="500" aria-label="state">
                        State
                    </FormControl>
                    <Select
                        id="state"
                        placeholder={'All locations'}
                        name="state"
                        value={selectedState ? { label: selectedState.name, value: values.state } : null}
                        options={states.map((state) => ({
                            label: state.name,
                            value: state.value,
                        }))}
                        onChange={(selectedOption) =>
                            setFieldValue && setFieldValue('state', selectedOption?.value || '')
                        }
                        aria-label="state"
                        isClearable={true}
                        styles={customStyles} 

                    />
                    <FormErrorMessage>
                        {errors?.state && touched?.state ? errors.state : null}
                    </FormErrorMessage>
                </FormControl>
            </VStack>

            {/* City Select/Input */}
            <VStack align="start" w="100%">
                <FormControl isInvalid={errors?.city && touched?.city ? true : false}>
                    <FormControl fontSize="14px" fontWeight="500" aria-label="city">
                        City
                    </FormControl>
                    {values.state !== 'Lagos' ? (
                        <Input
                            id="city"
                            name="city"
                            value={values.city}
                            disabled={!values.state}
                            onChange={handleChange}
                            type="text"
                            fontSize={{ base: '1rem', md: '0.875rem' }}
                            placeholder="Enter city"
                            _placeholder={{
                                fontSize: { base: '12px', md: '14px' }
                            }}
                            aria-label="city"
                        />
                    ) : (
                        <>
                            <Select
                                id="city"
                                    placeholder={cityMenuIsOpen ? 'Search cities' : 'Select city'}
                                name="city"
                                options={cityOptions}
                                value={cityOptions.find((city) => city.value === values.city) || null}
                                onChange={(selectedOption) =>
                                    setFieldValue && setFieldValue('city', selectedOption?.value || '')
                                }
                                    aria-label="city"
                                    onMenuOpen={() => setCityMenuIsOpen(true)}
                                    onMenuClose={() => setCityMenuIsOpen(false)}
                                    isClearable={true}
                                    styles={customStyles} 

                            />
                            {!isCityValid && !isCitySelected && (
                                <CustomToolTip hasArrow={true} p="1">
                                    <Text
                                        align="center"
                                        bg="primary.sub"
                                        fontSize={{ base: '12px', md: '13px' }}
                                        color="primary.main"
                                        p="1"
                                        mt="1"
                                        borderRadius="8px"
                                        w="100%"
                                    >
                                        Select from the listed cities
                                    </Text>
                                </CustomToolTip>
                            )}
                        </>
                    )}
                    <FormErrorMessage>
                        {errors?.city && touched?.city ? errors.city : null}
                    </FormErrorMessage>
                </FormControl>
            </VStack>
        </Flex>
    );
};

export default StateCitySelect;
