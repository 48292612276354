import React from 'react';
import { useRoutes } from 'react-router-dom';
import AuthRoutes from './auth.route';
import AdminRoutes from './admin.route';
import CustomerRoutes from './customer.route';

const Routes: React.FC = () => {
  return useRoutes([
    {
      path: '/',
      children: CustomerRoutes
    },
    {
      path: 'admin',
      children: AdminRoutes
    },
    {
      path: 'auth',
      children: AuthRoutes
    }
  ]);
};

export default Routes;
