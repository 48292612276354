import React from 'react';
import { Flex, Select } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

interface Props {
  currentPage: number;
  totalPages: number | any;
  pageLimit?: number;
  onPageChange: number | any;
  setPageLimit?: (page: number) => void;
}


const Pagination: React.FC<Props> = ({ currentPage, totalPages, pageLimit = 5, onPageChange }) => {

  const handlePageChange = (page: number) => {
    onPageChange(page);
  };
  const handleRowsToShowChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedRows = parseInt(event.target.value, 10);
    onPageChange(1, selectedRows);
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    const pages = Math.ceil(totalPages / pageLimit);

    if (pages <= pageLimit) {
      // Display all page numbers
      for (let page = 1; page <= pages; page++) {
        pageNumbers.push(
          <li
            key={page}
            value={page}
            onClick={() => handlePageChange(page)}
            style={{ padding: '5px', cursor: 'pointer' }}
          >
            {currentPage === page ? (
              <span style={{ textDecoration: 'underline' }}>{page}</span>
            ) : (
              page
            )}
          </li>
        );
      }
    } else {
      // Display ellipsis and last few page numbers
      const firstPage = Math.max(currentPage - Math.floor(pageLimit / 2), 1);
      const lastPage = Math.min(firstPage + pageLimit - 1, totalPages);

      if (firstPage > 1) {
        pageNumbers.push(
          <li
            key="start-ellipsis"
            value={1}
            onClick={() => handlePageChange(1)}
            style={{ padding: '5px', cursor: 'pointer' }}
          >
            1
          </li>
        );

        pageNumbers.push(
          <li key="start-ellipsis-ellipsis" style={{ padding: '5px' }}>
            ...
          </li>
        );
      }

      for (let page = firstPage; page <= lastPage; page++) {
        pageNumbers.push(
          <li
            key={page}
            value={page}
            onClick={() => handlePageChange(page)}
            style={{ padding: '5px', cursor: 'pointer' }}
          >
            {currentPage === page ? (
              <span style={{ textDecoration: 'underline' }}>{page}</span>
            ) : (
              page
            )}
          </li>
        );
      }

      if (lastPage < totalPages) {
        pageNumbers.push(
          <li key="end-ellipsis-ellipsis" style={{ padding: '5px' }}>
            ...
          </li>
        );

        pageNumbers.push(
          <li
            key="end-ellipsis"
            value={totalPages}
            onClick={() => handlePageChange(totalPages)}
            style={{ padding: '5px', cursor: 'pointer' }}
          >
            {pages}
          </li>
        );
      }
    }

    return pageNumbers;
  };

  return (
    <Flex
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end',
        listStyleType: 'none',
        gap: '1rem',
        padding: '8px',
        fontSize: '13px'
      }}
      w="100%"
    >
      <li style={{ cursor: 'pointer' }}>
        <Flex align="center">
          Prev
          <button disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)}>
            <ChevronLeftIcon boxSize={7} />
          </button>
        </Flex>
      </li>
      <Flex
        style={{
          maxWidth: '300px',
          overflow: 'auto'
        }}
      >
        <ul style={{ display: 'flex', listStyleType: 'none', padding: 0, margin: 0 }}>
          {getPageNumbers()}
        </ul>
      </Flex>
      <li style={{ cursor: 'pointer' }}>
        <Flex align="center">
          <button
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
          >
            <ChevronRightIcon boxSize={7} />
          </button>
          Next
        </Flex>
      </li>
      <li>
        <Select
          style={{
            width: '80px'
          }}
          fontSize={{
            base: '13px'
          }}
          value={pageLimit}
          onChange={handleRowsToShowChange}
        >
          {[5, 10, 50, 100, 200].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </Select>
      </li>
      Rows per page
    </Flex>
  );
};

export default Pagination;
