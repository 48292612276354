import { Button, Divider, Flex } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import Card from '../card';
import { parseAmount } from 'utils';
import { useStore } from 'store';
import { Routes } from 'constant';
import { useNavigate, useParams } from 'react-router-dom';
import CartItem from './CartItem';
import EmptyCart from 'components/cart/emptycart';

const AdminCart = () => {
  const navigate = useNavigate();
  const { id, memberId } = useParams();
  const path = location.pathname;
  const {
    CartStore: {
      cart,
      cartTotals: { itemsTotal }
    },
  } = useStore();
  const ItemAmount = parseAmount(itemsTotal);
  const handlePlaceOrder = (id: string, memberId: string) => {
    path === Routes.AdminPlaceOrderForPartner(id as string)
      ? navigate(Routes.AdminPartnerCheckoutDeliveryAddress(id as string))
      : path === Routes.AdminPlaceOrderForPartnerMember(id as string, memberId as string)
      ? navigate(Routes.AdminPartnerMemberCheckoutDeliveryAddress(id as string, memberId as string))
      : navigate(Routes.AdminCheckoutDeliveryAddress);
  };

  return (
    <Card title="Your Bag">
      <Flex w="100%" justify="space-between" flexDir="column">
        <Divider mt="1rem" />
        <Flex flexDir="column" justify="space-between" mt="1rem">
          {cart.length < 1 ? (
            <EmptyCart />
          ) : (
            <>
              {cart.map((item, index) => (
                <CartItem cartItem={item} index={index} />
              ))}

              <Button
                w="90%"
                onClick={() => {handlePlaceOrder(id as string, memberId as string)}}
                fontSize="14px"
              >
                Proceed to checkout ({ItemAmount ? ItemAmount : '₦0.00'})
              </Button>
            </>
          )}
        </Flex>{' '}
      </Flex>
    </Card>
  );
};

export default observer(AdminCart);
