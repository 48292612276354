import { Box, Flex, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import TabSelection from '../../../../components/tabSelection';
import { useStore } from 'store';
import { InfoIcon } from '@chakra-ui/icons';
import { Suspense, lazy, useCallback } from 'react';
import Loading from 'components/loading';
import CustomToolTip from 'components/customToolTip';

const BusinessAccount = lazy(() => import('./BusinessAccount'));
const PersonalAccount = lazy(() => import('./PersonalAccount'));

const ToolTipInfo = () => {
  return (
    <Box bg={`white`} color={`black`} borderRadius={`8px`} w="80%">
      <Text textAlign="center" fontSize="sm" fontWeight={`500`}>
        You should choose this option if :
      </Text>
      <UnorderedList fontWeight={`400`}>
        <ListItem>you are a business</ListItem>
        <ListItem>you are an organization</ListItem>
      </UnorderedList>
    </Box>
  );
};

const Info = () => {
  const {
    UserStore: { accountType, updateAccountType }
  } = useStore();

  const handleAccountTab = useCallback(
    (value: string) => {
      updateAccountType(value);
    },
    [updateAccountType]
  );

  const accountOptions: { value: string; name: string }[] = [
    { value: 'Personal account', name: 'Personal account' },
    { value: 'Business account', name: 'Business account' }
  ];

  return (
    <Suspense fallback={<Loading />}>
      <Box>
        <Flex style={{ alignItems: 'center' }}>
          <TabSelection
            setSelectedTab={accountType}
            onTabSelection={handleAccountTab}
            units={accountOptions.map((unit) => unit.value)}
            lower={true}
            style={{
              tabs: {
                backgroundColor: '#FFFFFF',
                width: '70%',
                position: 'relative'
              },
              tabList: {
                display: 'flex',
                justifyContent: 'center',
                gap: '10px'
              },
              tab: {
                borderRadius: '5px',
                fontSize: '14px',
                padding: '8px 8px'
              }
            }}
          />
          <CustomToolTip label={<ToolTipInfo />} bg={`white`}>
            <InfoIcon color="primary.main" />
          </CustomToolTip>
        </Flex>

        {accountType === 'personal account' ? <PersonalAccount /> : <BusinessAccount />}
      </Box>
    </Suspense>
  );
};

export default observer(Info);
