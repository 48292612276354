import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

// layouts
import AuthLayout from '../layouts/authLayout';
const OnBoardingLayout = lazy(() => import('../layouts/onBoardingLayout'));
import { RedirectTo } from './util.route';

// components
const Login = lazy(() => import('../domains/auth/login'));
const SignUp = lazy(() => import('../domains/auth/signup'));
const ForgotPassword = lazy(() => import('../domains/auth/forgotpassword'));
const EmailSent = lazy(() => import('../domains/auth/emailsent'));
const ResetPassword = lazy(() => import('../domains/auth/resetpassword'));
const GuestConfirmationPage = lazy(
  () => import('../domains/clientDashboard/placeanorder/components/GuestConfirmationPage')
);

const routes: RouteObject[] = [
  {
    path: '',
    element: <AuthLayout />,
    children: [
      {
        index: true,
        element: <RedirectTo />
      },
      {
        path: 'login',
        element: <Login />,
        caseSensitive: true
      },
      {
        path: 'admin/login',
        element: <Login />,
        caseSensitive: true
      },
      {
        path: 'signup',
        element: <SignUp />,
        caseSensitive: true
      },
      {
        path: 'forgot-password',
        element: <ForgotPassword />,
        caseSensitive: true
      },
      {
        path: 'email-sent',
        element: <EmailSent />,
        caseSensitive: true
      },
      {
        path: 'reset-password/:token',
        element: <ResetPassword />,
        caseSensitive: true
      },
      {
        path: 'order-success',
        element: <GuestConfirmationPage />,
        caseSensitive: true
      }
    ]
  },
  {
    path: 'onboarding',
    element: <OnBoardingLayout />
  }
];

export default routes;
