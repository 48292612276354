import Info from "./Info";
import InfoIcon from "assets/images/personalinfo.svg";
import LocationIcon from "assets/images/location.svg";
import Location from "./Location";
import Order from "./Order";
import OrderIcon from "assets/images/orderbox.svg";

export const onboardingData: IOnboardingStepData[] = [
  {
    index: '01.',
    step: 'info',
    name: 'Personal Info',
    title: 'Hi there. Tell us about you!',
    subtitle: 'We need this information in order to fill your prescription...',
    component: <Info />,
    icon: InfoIcon
  },
  {
    index: '02.',
    step: 'location',
    name: 'Your Location',
    title: 'What is your location?',
    subtitle: 'We need this information in order to fill your prescription...',
    component: <Location />,
    icon: LocationIcon
  },
  {
    index: '03.',
    step: 'order',
    name: 'Place an Order',
    title: 'Place an Order',
    subtitle: 'Time to place your first order! What meds would you want to be delivered to you?',
    component: <Order />,
    icon: OrderIcon
  }
];