import dayjs from 'dayjs';
import * as yup from 'yup';

const LoginSchema = yup
  .object()
  .shape({
    email: yup
      .string()
      .required('Email is required')
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'Please enter a valid email'
      ),
    password: yup.string().min(4).max(30).required('Password is required').label('Password')
  })
  .required();

const LoggedInAddressSchema = yup.object().shape({
  state: yup.string().required('State is required'),
  city: yup.string().required('City is required'),
  street: yup.string().required('Street is required'),
  landmark: yup.string().required('Landmark is required')
});
const ReceiverDetailsSchema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  email: yup.string()
    .matches(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/, 'Email is invalid')
    .required('Email is required'),

  phoneNumber: yup.string()
    .max(11, 'Phone number cannot be more than 11 digits')
    .required('Phone number is required'),
  state: yup.string().required('State is required'),
  city: yup.string().required('City is required'),
  street: yup.string().required('Street is required'),
  landmark: yup.string().required('Landmark is required')
});
const BmiSchema = yup.object().shape({

  email: yup.string()
    .matches(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/, 'Email is invalid')
    .required('Email is required'),

  weight: yup.number()
    .positive('Weight is not valid')
    .required('Weight is required'),
  age: yup.number().positive('Age is not valid').required('age is required'),
  height: yup.number().positive('Height is not valid').required('height is required')
});
const CalorieSchema = yup.object().shape({

  email: yup.string()
    .matches(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/, 'Email is invalid')
    .required('Email is required'),

  weight: yup.number()
    .positive('Weight is not valid')
    .required('Weight is required'),
  age: yup.number().positive('Age is not valid').required('age is required'),
  height: yup.number().positive('Height is not valid').required('height is required'),
  activityLevel: yup.string().required('Activity level is required'),
});
const ovulationSchema = yup.object().shape({
  email: yup.string()
    .matches(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/, 'Email is invalid')
    .required('Email is required'),
  firstDayOfLastPeriod: yup.date().required('First day is required'),
  lengthOfPeriod: yup.number().positive('Period length is not valid').required('Period length is required'),
  cycleLength: yup.number().positive('Cycle length is not valid').required('Average Cycle length is required')

});

const PersonalInfoSchema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  phoneNumber: yup.string()
    .max(11, 'Phone number cannot be more than 11 digits')
    .matches(/^[0-9]+$/, 'Phone number must only contain digits')
    .required('Phone number is required'),
  dateOfBirth: yup.string()
    .test(
      "valid-date-of-birth",
      "Invalid date of birth",
      (value) => dayjs(value, "MM/DD/YYYY").isValid()
    )
    .required("Date of birth is required"),
  gender: yup.string().required('Gender is required')
});

const ContactSchema = yup.object().shape({
  reason: yup.string().required('Reason is required'),
  message: yup.string().test('required', 'Message is required', function (value) {
    return this.parent.message ? !!value : true;
  }),
  modeOfContact: yup.string().required('Mode of contact is required'),
  appointmentDate: yup.string().required('Appointment date is required'),
  appointmentTime: yup.string().required('Appointment time is required'),
});
const ContactFormSchema = yup.object().shape({
  reason: yup.string().required('Reason is required'),
  message: yup.string().test('required', 'Message is required', function (value) {
    return this.parent.message ? !!value : true;
  }),
 
});

const GeneralCustomerCouponSchema = yup.object().shape({
  couponValue: yup.string(),
  voucherValue: yup.string(),
  duration: yup.string(),
  startDate: yup.string(),
  endDate: yup.string(),

})
const CustomerCouponSchema = yup.object().shape({
  couponCode: yup.string().required('Coupon code is required'),
  description: yup.string().required('Coupon description is required'),
  couponValue: yup.number().integer().min(1).required('Coupon value is required'),
  couponType: yup.string().required('Coupon type is required'),
  usageLimit: yup.number().required('Usage limit is required'),
  expiryDate: yup.string().required('Expiry date is required'),

})

const SpecificCustomerCouponSchema = yup.object().shape({
  customerName: yup.string(),
  couponValue: yup.string(),
  duration: yup.string(),
  startDate: yup.string(),
  endDate: yup.string(),
  usage: yup.string(),
})

const DeleteCustomerSchema = yup.object().shape({
  reason: yup.string()
})
const TagSchema = yup.object().shape({
  tag: yup.string().required('tag name is required'),
  tagColor: yup.string().required('tag color is required'),
})

export { LoginSchema, ReceiverDetailsSchema, LoggedInAddressSchema, ContactSchema, ContactFormSchema, BmiSchema, ovulationSchema, PersonalInfoSchema, CalorieSchema, GeneralCustomerCouponSchema, CustomerCouponSchema, SpecificCustomerCouponSchema, DeleteCustomerSchema, TagSchema }



