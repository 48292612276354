import { adminNoticeKey } from "constant";
import { action, makeObservable, observable } from "mobx";
import { RootStore } from "store";
import storage from "utils/storage";

export class AppStore {
  rootStore: RootStore;
  menuOpen = false;
  isNotificationOpen = false;
  soundTarget = "group";
  searchQuery: "" | string = "";
  adminNotifications = JSON.parse(storage.get(adminNoticeKey) || "[]") || [];

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      isNotificationOpen: observable,
      menuOpen: observable,
      searchQuery: observable,
      adminNotifications: observable,
      soundTarget: observable,

      toggleMenu: action.bound,
      clearSearchQuery: action.bound,
      setSearchQuery: action.bound,
      addToNotification: action.bound,
      removeNotification: action.bound,
      clearNotification: action.bound,
      toggleNotificationBell: action.bound,
      setSoundTarget: action.bound,
    });
    this.rootStore = rootStore;
  }

  setSearchQuery(query: string) {
    this.searchQuery = query;
  }

  setSoundTarget(target: string) {
    this.soundTarget = target;
  }

  clearSearchQuery() {
    this.searchQuery = "";
  }

  toggleMenu() {
    if (this.menuOpen) {
      this.menuOpen = false;
    } else {
      this.menuOpen = true;
    }
  }
  toggleNotificationBell() {
    this.isNotificationOpen = !this.isNotificationOpen;
  }
  addToNotification(notice: IAdminNotifications) {
    this.adminNotifications.push(notice);
    storage.set(adminNoticeKey, JSON.stringify(this.adminNotifications));
  }
  removeNotification(orderNumber: string) {
    this.adminNotifications = this.adminNotifications.filter(
      (notice: IAdminNotifications) => notice.orderNumber !== orderNumber
    );
    storage.set(adminNoticeKey, JSON.stringify(this.adminNotifications));
  }
  clearNotification() {
    this.adminNotifications = [];
    storage.set(adminNoticeKey, JSON.stringify(this.adminNotifications));
  }
}
