import { Box, Flex, Heading } from '@chakra-ui/react';
import React from 'react';
import { useStore } from 'store';

const Card = ({ children, title }: { children: React.ReactNode; title?: string }) => {
  const {
    CartStore: { toggleCartOpen }
  } = useStore();
  return (
    <Box
      sx={{
        border: '1px solid #E6E7EA',
        borderRadius: '12px',
        padding: { base: '20px', md: '20px' },
        w: '100%',
        backgroundColor: 'white'
      }}
    >
      {title && (
        <Flex alignItems="center" justify="space-between">
          <Heading fontSize="xl">{title}</Heading>
          <Box
            onClick={() => {
              toggleCartOpen();
            }}
            sx={{
              color: 'black',
              background: '#EE4E61',
              borderRadius: '50%',
              width: '15px',
              height: '15px',
              display: { base: 'flex', md: 'flex', lg:"none" },
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Box w="5px" h="5px" borderRadius="50%" bgColor="#A4414D"></Box>
          </Box>
        </Flex>
      )}
      {children}
    </Box>
  );
};

export default Card;
