import { useStore } from 'store';
import { useLocation, Navigate } from 'react-router-dom';
import Permissions from 'utils/permissions';
import { ReactNode, ComponentType } from 'react';

const RoleGuard: React.FC<{
  children: JSX.Element;
  requiredRole: string[] | string;
}> = ({ children, requiredRole }) => {
  const location = useLocation();
  const pathname = location.pathname;
  const AuthStore = useStore().AuthStore;
  const permissions = new Permissions(AuthStore);

  if (requiredRole.length > 0 && !permissions.hasCategoryAccess(requiredRole)) {
    return (
      <Navigate
        to={pathname.includes('admin') ? '/admin/dashboard' : '/'}
        state={{ from: location }}
        replace
      />
    );
  }

  return children;
};

export const withRoleGuard = (Component: ComponentType, roles: string | string[]): ReactNode => {
  return (
    <RoleGuard requiredRole={roles}>
      <Component />
    </RoleGuard>
  );
};

export default RoleGuard;
