import { Text, Image, Card, Flex, VStack } from '@chakra-ui/react';
import PRModal from 'components/modal';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStore } from 'store';
import Item from 'assets/images/singleitem.png';
import Bulk from 'assets/images/bulkitem.png';

const AddPartnerModalType = () => {
  const {
    PartnerStore: { isViewAddPartnerOption, toggleAddPartnerModal, toggleAddPartnerOption },
    InventoryStore: { toggleAddItemBulkModal }
  } = useStore();
  return (
    <PRModal
      title="Add new partner"
      open={isViewAddPartnerOption}
      onClose={() => toggleAddPartnerOption()}
    >
      <VStack py="2rem" align="center" w={{ base: '100%', lg: '500px' }}>
        <Text>How would you like to add a partner</Text>
        <Flex w="100%" align="center" justify="center" gap="1rem">
          <Card
            _hover={{ border: '1px solid #805AD5' }}
            onClick={() => {
              toggleAddPartnerOption();
              toggleAddPartnerModal();
            }}
            cursor="pointer"
            align="center"
            gap="1rem"
            w={{ base: '100%', md: '40%' }}
            px="2rem"
            py="1rem"
          >
            <Image
              align="center"
              w="80px"
              src={Item}
              alt="order icon"
              sx={{
                cursor: 'pointer'
              }}
              loading="lazy"
            />
            <Text textAlign="center" fontSize={{ base: '12px', md: '14px' }}>
              Add single partner{' '}
            </Text>
          </Card>
          <Card
            _hover={{ border: '1px solid #805AD5' }}
            onClick={() => {
              toggleAddPartnerOption();
              toggleAddItemBulkModal();
            }}
            cursor="pointer"
            align="center"
            gap="1rem"
            w={{ base: '100%', md: '40%' }}
            px="2rem"
            py="1rem"
          >
            <Image
              align="center"
              w="80px"
              src={Bulk}
              alt="order icon"
              sx={{
                cursor: 'pointer'
              }}
              loading="lazy"
            />
            <Text textAlign="center" fontSize={{ base: '12px', md: '14px' }}>
              Add bulk partners{' '}
            </Text>
          </Card>
        </Flex>
      </VStack>
    </PRModal>
  );
};

export default observer(AddPartnerModalType);
