import { Box, HStack, Heading, Image, Flex } from '@chakra-ui/react';
import { Suspense, lazy, useCallback } from 'react';
import Loading from 'components/loading';
import { Routes } from 'constant';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store';
import backIcon from 'assets/images/backicon.svg';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { responsiveLg } from 'utils';
import { useEffect, useRef, useState } from 'react';
import { adminCustomerHistoryColumn } from 'data/customer';
import { filterStatus } from 'data/order';
import Select from 'react-select';

const DynamicTable = lazy(() => import('components/DynamicTable'));

const CustomerHistory = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;

  const { id } = useParams();
  const {
    UserStore: {
      selectedUserOrders,
      getUserById,
      selectUserProfile,
      getMemberOrders,
      getUserOrdersById
    },
    AppStore: { searchQuery },
    PartnerStore: { selectedPartner, partnerMember, isViewPartnerMemberOrders }
  } = useStore();
  const pagRef = useRef<HTMLDivElement>(null);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState<string>('All');
  const { personalInformation } = selectUserProfile || {};

  const handleFilterData = useCallback(() => {
    const filteredData = selectedUserOrders?.filter((order: any) => {
      const { orderStatus } = order;
      const query = searchQuery?.toLowerCase();
      const isStatusMatched =
        selectedStatus === 'All' || orderStatus.toLowerCase() === selectedStatus.toLowerCase();
      const isQueryMatched =
        order?.orderNumber?.toLowerCase().includes(query) ||
        order?.enrolleeId?.toLowerCase().includes(query);

      return isStatusMatched && isQueryMatched;
    });
    setFilteredOrders(filteredData as any);
  }, [selectedUserOrders, selectedStatus, searchQuery, selectUserProfile]);

  const { partnerId, _id } = partnerMember;
  const isMemberOrderhistory =
    path === Routes.AdminMemberOrderHistory(partnerId as string, _id as string);

  useEffect(() => {
    handleFilterData();
  }, [selectedUserOrders, selectedStatus, searchQuery, selectUserProfile]);

  useEffect(() => {
    if (isMemberOrderhistory) {
      getMemberOrders(_id as string);
    } else {
      getUserOrdersById(id as string, 0, 0);
    }
    getUserById(id as string);
  }, [id, isMemberOrderhistory, _id]);

  return (
    <Suspense fallback={<Loading />}>
      <Box mt=".6rem">
        <HStack display="flex" align="start" justify="space-between" w="100%">
          <Flex align="center" gap="1rem">
            <Image
              cursor="pointer"
              onClick={() =>
                isMemberOrderhistory
                  ? navigate(Routes.PartnerMembers(selectedPartner?.partnerId as string))
                  : navigate(Routes.AdminCustomer)
              }
              src={backIcon}
              alt="back"
            />
            <Heading
              sx={{
                fontSize: responsiveLg('.875rem', '1rem')
              }}
            >
              {partnerMember && isViewPartnerMemberOrders
                ? !partnerMember?.firstName
                  ? selectedUserOrders?.length
                  : `${partnerMember?.firstName} ${partnerMember?.lastName} | ${selectedUserOrders?.length}`
                : !personalInformation?.firstName
                ? selectedUserOrders?.length
                : `${personalInformation?.firstName} ${personalInformation?.lastName} | ${selectedUserOrders?.length}`}
            </Heading>
          </Flex>
          <Box w={{ base: '70%', md: '20%' }}>
            <Select
              options={filterStatus}
              onChange={(selectedOption: any) => {
                setSelectedStatus(selectedOption?.value as any);
                handleFilterData();
              }}
              placeholder={'Filter order by status'}
              isSearchable={false}
            />
          </Box>
        </HStack>
        <Box
          w="100%"
          bg="#ffffff"
          maxH="60%"
          mt="1.5rem"
          borderRadius="8px"
          overflowY="auto"
          overflowX="auto"
          fontSize={{ base: '12px', md: '14px' }}
          border="1px solid #EDEDED"
          ref={pagRef}
        >
          <DynamicTable data={filteredOrders} columns={adminCustomerHistoryColumn} />
        </Box>
      </Box>
    </Suspense>
  );
};

export default observer(CustomerHistory);
