import { SmallCloseIcon, MinusIcon, AddIcon } from '@chakra-ui/icons';
import { Flex, Icon, Divider, Box, Text } from '@chakra-ui/react';
import { useStore } from 'store';
import React from 'react';
import { parseAmount } from 'utils';
import { observer } from 'mobx-react-lite';

const CartItem: React.FC<ICartItem> = ({ cartItem: item, index }) => {
  const {
    CartStore: { cart, addCartQuantity, subtractCartQuantity, removeCartItem }
  } = useStore();
  return (
    <Box key={item?.drugGeneratedId}>
      <Flex w="100%" justify="space-between" align="start" p="1rem">
        <Flex gap=".5rem" align="start">
          <Icon
            onClick={() => {
              removeCartItem(item?.drugGeneratedId);
            }}
            fontSize="20px"
            mt=".2rem"
            color="#EA1831"
            borderRadius="50%"
            bg="#FEF2F2"
          >
            <SmallCloseIcon />
          </Icon>
          <Flex gap=".5rem" align="start" flexDir="column" w="100%">
            <Text fontSize="15px">{item?.brandName}</Text>
            <Text fontSize="13px">
              {item?.totalPrice ? parseAmount(item?.totalPrice) : 'price upon order confirmation'}
            </Text>
          </Flex>
        </Flex>
        <Flex gap=".5rem" align="center">
          <Icon
            fontSize="1.5rem"
            color="#A1A5AD"
            bg="#676C7B3D"
            borderRadius="50%"
            p="5px"
            _hover={{
              cursor: 'pointer',
              bg: 'white',
              border: '1px solid #5519A8',
              color: '#5519A8'
            }}
            onClick={() => {
              subtractCartQuantity(item.drugGeneratedId);
            }}
          >
            <MinusIcon />
          </Icon>
          <Text color="#A1A5AD">{item?.quantity}</Text>
          <Icon
            fontSize="1.5rem"
            color="#A1A5AD"
            bg="#676C7B3D"
            borderRadius="50%"
            p="5px"
            _hover={{
              cursor: 'pointer',
              bg: 'white',
              border: '1px solid #5519A8',
              color: '#5519A8'
            }}
            onClick={() => {
              addCartQuantity(item?.drugGeneratedId);
            }}
          >
            <AddIcon />
          </Icon>
        </Flex>
      </Flex>
      {index !== cart.length - 1 && <Divider />}
    </Box>
  );
};

export default observer(CartItem);
