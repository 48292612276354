import { VStack, Button, Image, Text } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import CartImg from 'assets/images/cart.svg';
import { Routes } from '../../../constant';
import toast from 'react-hot-toast';
import { useStore } from 'store';
const EmptyCart = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const {
    CartStore: { cart, toggleCartOpen }
  } = useStore();
  const placeOrder = () => {
    if (cart.length < 1) {
      toast.error('Cart is empty, please add item to bag', {
        position: 'top-right'
      });
    } else if (
      path !== Routes.AdminPlaceOrderForCustomer ||
      path !== Routes.AdminCheckoutDeliveryAddress
    ) {
      toggleCartOpen();
      navigate(Routes.PlaceOrder);
    }
  };
  return (
    <VStack mt="3rem">
      <Image w="150px" h="150px" src={CartImg} alt="PRLogo" />
      <Text>Empty!</Text>
      <Text fontSize="12px">There’s nothing in your bag…yet</Text>
      <Button size="sm" onClick={placeOrder} width={{ base: '75%', md: '80%' }}>
        Place order
      </Button>
    </VStack>
  );
};

export default EmptyCart;
