import { action, flow, makeObservable, observable } from "mobx";
import { RootStore } from "..";
import { parseError } from "../../utils";
import toast from "react-hot-toast";
import {
updateUserAddress,
getUserAddress,
getUserInfo,
getUserOrders,
updateUserInfo,
getFamilyAndFriends,
updateFamilyAndFriends,
addFamilyAndFriends,
getUserAllergies,
updateUserAllergies,
getUserHMO,
updateUserHMO,
deleteFamilyAndFriends,
onboardBusiness,
getOrderReport,
updateActivateCustomer,
deleteUser,
getUserById,
getReferralOrders,
joinPartnerNetwork,
hardDeleteUser,
memberRequestStatus,
getMemberRequest,
getUserPartner,
getFamilyAndFriendsAdmin,
addFamilyAndFriendsAdmin,
updateFamilyAndFriendsAdmin,
updateUserAddressAdmin,
getMemberOrders,
getUser,
updateUserMeds
} from "../../requests/user";
import { onboardingData } from "../../domains/auth/signup/components/onboardingData";
import dayjs from "dayjs";
import { addPartnerMember, deletePartnerMember, getPartnerById, getPartnerMembers, updatePartnerMember } from "requests/partners";

export class UserStore {
  aUser: Nullable<IUserInfo> = null;
  userFamilyAddress: IFamilyAndFriends[] = [];
  userfamilyOrFriendsInfo = {} as IFamilyAndFriends;
  userPartners = [];
  userInfo = {
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    phoneNumber: "",
    gender: "",
    isAffiliate: false,
  };
  filterOptions = {
    activeStatus: [],
    customerStatus: [],
    orderStatus: [],
    customerCategory: [],
  } as any;
  orderReport: Nullable<IOrderReport[]> = [];
  selectedCustomer = {} as ISelectedMember | null;
  selectUserProfile = {} as IFullUser | null;
  selectedUserOrders: IOrder[] = [];
  userPartnerMember = {} as IMember;
  userPartnerMembers: IMember[] = [];
  selectedAffiliateOrders = [];
  selectedUser = {} as ISelectedUserInfo | null;
  userAddress: Nullable<IUserAddress> = null;
  familyAndFriends: IFamilyAndFriends[] = [];
  familyOrFriends: Nullable<IFamilyAndFriends> = null;
  newBusiness = {} as IBusiness;
  newFamAndFriend = "";
  family: IFamilyAndFriends = {
    firstName: "",
    lastName: "",
    email: "",
    city: "",
    state: "",
    phoneNumber: "",
    street: "",
    landmark: "",
    longitude: "",
    latitude: "",
  };
  contactValue = {
    reason: "",
    message: "",
  } as IContactPharmacist;
  memberRequest: IMemberRequest[] = [];
  memberUser = {} as IMemberRequest;
  medsAndAllergies: Nullable<IMedAndAllergies> = null;
  userHmo: Nullable<IUserHmo> = null;
  isFamilyandFriendsState = {
    isOpen: false,
    isMember: false,
  };
  isRemoveFamilyandFriendsState = {
    id: "",
    isOpen: false,
    isMember: false,
  };
  isRemoveMemberOpen = false;

  isBookAPharmacyOpen = false;
  isNewBusiness = false;
  isCreateVoucherOpen = false;
  isCustomerDetailsOpen = false;
  isActivateCustomerOpen = false;
  isCustomerDeactivatedOpen = false;
  isDeleteCustomerOpen = false;
  isViewMemberOpen = false;
  isCustomerWalletOpen = false;
  isViewJoinRequestOpen = false;
  isDisabled = {
    personalInfo: true,
    address: true,
    familyAndFriends: true,
    medsAndAllergies: true,
    memberPartner: true,
  };

  onboardingStep: TOnboardingStep = "info";
  stepCount = 0;
  accountType = "personal account";
  isLoading = {
    getUserInfo: false,
    getUserById: false,
    getUserOrders: false,
    getUserAddress: false,
    updateUserInfo: false,
    updateBusinessInfo: false,
    updateUserAddress: false,
    getFamilyAndFriends: false,
    updateFamilyAndFriends: false,
    addFamilyAndFriends: false,
    getMedsAndAllergies: false,
    updateMeds: false,
    updateAllergies: false,
    getHMO: false,
    updateHMO: false,
    deleteFamilyAndFriends: false,
    getOrderReport: false,
    updateActivateCustomer: false,
    deleteUser: false,
    getReferralOrders: false,
    joinPartnerNetwork: false,
    hardDeleteUser: false,
    memberRequestStatus: false,
    getMemberRequest: false,
    getMemberOrders: false,
    getUserPartner: false,
    getFamilyAndFriendsAdmin: false,
    addFamilyAndFriendsAdmin: false,
    updateFamilyAndFriendsAdmin: false,
    getuserPartnerMembers: false,
    updateuserPartnerMember: false,
    adduserPartnerMember: false,
    deleteuserPartnerMember: false,
    getUser: false
  };
  currentPage = 1;
  pageLimit = 10;
  selectedType = {} as any;
  memberPartner = {};
  requestAction = '';

  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeObservable(this, {
      selectedType: observable,
      selectedCustomer: observable,
      selectedUser: observable,
      newBusiness: observable,
      selectedUserOrders: observable,
      selectUserProfile: observable,
      selectedAffiliateOrders: observable,
      userInfo: observable,
      orderReport: observable,
      userAddress: observable,
      accountType: observable,
      onboardingStep: observable,
      stepCount: observable,
      newFamAndFriend: observable,
      isFamilyandFriendsState: observable,
      isRemoveFamilyandFriendsState: observable,
      isDisabled: observable,
      isLoading: observable,
      medsAndAllergies: observable,
      userHmo: observable,
      familyAndFriends: observable,
      familyOrFriends: observable,
      family: observable,
      contactValue: observable,
      isBookAPharmacyOpen: observable,
      isNewBusiness: observable,
      isCreateVoucherOpen: observable,
      isActivateCustomerOpen: observable,
      isCustomerDeactivatedOpen: observable,
      isCustomerDetailsOpen: observable,
      isDeleteCustomerOpen: observable,
      isViewMemberOpen: observable,
      isRemoveMemberOpen: observable,
      currentPage: observable,
      pageLimit: observable,
      filterOptions: observable,
      isCustomerWalletOpen: observable,
      isViewJoinRequestOpen: observable,
      memberPartner: observable,
      memberRequest: observable,
      memberUser: observable,
      requestAction: observable,
      userPartners: observable,
      userFamilyAddress: observable,
      userfamilyOrFriendsInfo: observable,
      userPartnerMember: observable,
      userPartnerMembers: observable,

      toggleCustomerWalletModal: action.bound,
      toggleViewMemberModal: action.bound,
      toggleFamilyandFriends: action.bound,
      toggleRemoveFamilyandFriends: action.bound,
      toggleBookAPharmacyModal: action.bound,
      toggleCreateVoucherModal: action.bound,
      toggleCustomerDetailsModal: action.bound,
      toggleActivateCustomerModal: action.bound,
      toggleCustomerDeactivateModal: action.bound,
      toggleDeleteCustomerModal: action.bound,
      toggleRemoveCustomerDetailsModal: action.bound,
      changeOnboardingStep: action.bound,
      setIsDisabled: action.bound,
      setUserInfo: action.bound,
      setUserAddress: action.bound,
      getAllUserInfo: action.bound,
      setMedsAndAllergies: action.bound,
      setHMO: action.bound,
      setFamilyAndFriends: action.bound,
      setSelectedFamilyAndFriends: action.bound,
      setContactValue: action.bound,
      updateAccountType: action.bound,
      setSelectedUser: action.bound,
      setNewBusinessPersonalInfo: action.bound,
      setNewBusinessLocationInfo: action.bound,
      setNewBusiness: action.bound,
      setCurrentPage: action.bound,
      setPageLimit: action.bound,
      setFilterOptions: action.bound,
      resetFilterOptions: action.bound,
      setAllFilterOptions: action.bound,
      setSelectedType: action.bound,
      toggleRequestJoinNetworkModal: action.bound,


      getUser: flow.bound,
      getUserById: flow.bound,
      getUserInfo: flow.bound,
      getUserOrdersById: flow.bound,
      getMemberOrders: flow.bound,
      getUserAddress: flow.bound,
      updateUserInfo: flow.bound,
      updateUserAddress: flow.bound,
      updateUserAddressAdmin: flow.bound,
      getFamilyAndFriends: flow.bound,
      getFamilyOrFriends: flow.bound,
      addFamilyAndFriends: flow.bound,
      updateFamilyAndFriends: flow.bound,
      deleteFamilyAndFriends: flow.bound,
      getMedsAndAllergies: flow.bound,
      updateMeds: flow.bound,
      updateAllergies: flow.bound,
      createBusiness: flow.bound,
      getHMO: flow.bound,
      updateHMO: flow.bound,
      getOrderReport: flow.bound,
      updateActivateCustomer: flow.bound,
      deleteUser: flow.bound,
      getReferralOrders: flow.bound,
      joinPartnerNetwork: flow.bound,
      hardDeleteUser: flow.bound,
      memberRequestStatus: flow.bound,
      getMemberRequest: flow.bound,
      getUserPartner: flow.bound,
      getFamilyAndFriendsAdmin: flow.bound,
      addFamilyAndFriendsAdmin: flow.bound,
      updateFamilyAndFriendsAdmin: flow.bound,
      getFamilyOrFriendsAdmin: flow.bound,
      getuserPartnerMembers: flow.bound,
      updateuserPartnerMember: flow.bound,
      adduserPartnerMember: flow.bound,
      deleteuserPartnerMember: flow.bound,

    });
    this.rootStore = rootStore;
  }

  setSelectedType(data: any) {
    this.selectedType = data
  }
  setFilterOptions = (option: string | string[], id: string) => {
    if (this.filterOptions[id].includes(option)) {
      this.filterOptions[id] = this.filterOptions[id].filter(
        (item: string) => item !== option
      );
      return;
    }
    this.filterOptions[id].push(option);

  };

  setAllFilterOptions = (option: string, id: string, checked?: boolean) => {
    if (checked) {
      if (!this.filterOptions[id].includes(option)) {
        this.filterOptions[id].push(option);
      }
    } else {
      this.filterOptions[id] = [];
    }
  };

  resetFilterOptions = () => {
    this.filterOptions = {
      activeStatus: [],
      customerStatus: [],
      orderStatus: [],
      customerCategory: []
    };
  };

  setCurrentPage(page: number) {
    this.currentPage = page;
  }

  setPageLimit(limit: number) {
    this.pageLimit = limit;
  }

  toggleRequestJoinNetworkModal(id: string, action: string) {
    this.isViewJoinRequestOpen = !this.isViewJoinRequestOpen;
    const member = this.memberRequest.find((item) => item.memberId === id);
    if (member) {
      this.memberUser = member;
      this.requestAction = action
    }
  }


  toggleViewMemberModal(id: string) {

    this.isViewMemberOpen = !this.isViewMemberOpen;
    const member = this.userPartnerMembers?.find((item) => item?._id === id);
    if (member) {
      this.selectedCustomer = member;
    }
  }

  toggleFamilyandFriends(data?: { isMember: boolean; isOpen?: boolean }) {
    if (data) {
      this.newFamAndFriend = "new";
      this.isFamilyandFriendsState = {
        ...data,
        isOpen: true,
      };
    } else {
      this.newFamAndFriend = "";
      this.isFamilyandFriendsState = {
        isOpen: false,
        isMember: false,
      };
    }
    this.rootStore.CheckoutStore.deliveryAddress = {
      firstName: "",
      lastName: "",
      email: "",
      street: "",
      city: "",
      state: "",
      longitude: "",
      latitude: "",
      phoneNumber: "",
      landmark: "",
    };
  }

  toggleRemoveFamilyandFriends(data?: {
    id: string;
    isMember: boolean;
    isOpen?: boolean;
  }) {
    if (data) {
      this.isRemoveFamilyandFriendsState = {
        ...data,
        isOpen: true,
      };
    } else {
      this.isRemoveFamilyandFriendsState = {
        id: "",
        isOpen: false,
        isMember: false,
      };
    }
  }

  updateAccountType = (account: string) => {
    this.accountType = account?.trim()?.toLowerCase();
  };

  toggleActivateCustomerModal(user?: any) {
    this.isActivateCustomerOpen = !this.isActivateCustomerOpen;
    if (user) {
      this.selectedUser = (user as ISelectedUserInfo | unknown) as null;
    }
  }
  toggleCustomerDeactivateModal(user?: any) {
    this.isCustomerDeactivatedOpen = !this.isCustomerDeactivatedOpen;
    if (user) {
      this.selectedUser = (user as ISelectedUserInfo | unknown) as null;
    }
  }
  toggleDeleteCustomerModal(user?: any) {
    this.isDeleteCustomerOpen = !this.isDeleteCustomerOpen;
    if (user) {
      this.selectedUser = (user as ISelectedUserInfo | unknown) as null;
    }
  }
  toggleCreateVoucherModal() {
    this.isCreateVoucherOpen = !this.isCreateVoucherOpen;
  }
  toggleCustomerWalletModal(user?: any) {
    this.isCustomerWalletOpen = !this.isCustomerWalletOpen;
    if (user) {
      this.selectedUser = (user as ISelectedUserInfo | unknown) as null;
    }
  }
  toggleCustomerDetailsModal(user?: any) {
    this.isCustomerDetailsOpen = !this.isCustomerDetailsOpen;
    if (user) {
      this.selectedUser = (user as ISelectedUserInfo | unknown) as null;
    }
  }

  setSelectedUser(data?: ISelectedUserInfo) {
    if (!data) {
      this.selectedUser = null;
    } else {
      this.selectedUser = data;
    }
  }

  setNewBusinessPersonalInfo(data: IBusiness) {
    if (!data) {
      return;
    } else {
      this.newBusiness = { ...data };
    }
  }

  setNewBusinessLocationInfo(data: IAddress) {
    if (!data && !this.isNewBusiness) {
      return;
    } else {
      const { street, city, state, longitude, landmark, latitude } = data;
      this.newBusiness.street = street;
      this.newBusiness.city = city;
      this.newBusiness.state = state;
      this.newBusiness.longitude = longitude;
      this.newBusiness.latitude = latitude;
      this.newBusiness.landmark = landmark;
    }
  }

  setNewBusiness() {
    this.isNewBusiness = !this.isNewBusiness
  };

  toggleRemoveCustomerDetailsModal() {
    this.isCustomerDetailsOpen = false;
  }

  toggleBookAPharmacyModal() {
    this.isBookAPharmacyOpen = !this.isBookAPharmacyOpen;
  }

  setIsDisabled(section: keyof typeof this.isDisabled) {
    this.isDisabled = {
      ...this.isDisabled,
      [section]: !this.isDisabled[section],
    };
  }
  changeOnboardingStep(dir: "add" | "remove") {
    if (dir === "add") {
      if (this.stepCount > 2) {
        this.stepCount = 2;
      } else {
        this.stepCount += 1;
        this.onboardingStep = onboardingData[this.stepCount].step;
      }
    } else {
      if (this.stepCount < 0) {
        this.stepCount = 0;
      } else {
        this.stepCount -= 1;
        this.onboardingStep = onboardingData[this.stepCount].step;
      }
    }
  }

  setContactValue = (data: IContactPharmacist) => {
    this.contactValue = data;
  };

  setUserInfo(data: IUserInfo) {
    if (data) {
      this.userInfo = {
        ...this.userInfo,
        ...data,
        dateOfBirth: data.dateOfBirth
          ? dayjs(data.dateOfBirth).format("YYYY-MM-DD")
          : "",
      };
    }
  }

  setUserAddress(data: IUserAddress) {
    this.userAddress = data;
  }

  setFamilyAndFriends(data: IFamilyAndFriends) {
    this.rootStore.CheckoutStore.deliveryAddress = data as IAddress;
  }

  setSelectedFamilyAndFriends(selectedfnfid?: string) {
    const id = selectedfnfid ? selectedfnfid : ''
    this.userfamilyOrFriendsInfo = this.selectedUser?.friendsAndFamily?.find(fnf => fnf._id = id) as IFamilyAndFriends;
  }

  setMedsAndAllergies = (data: IMedAndAllergies) => {
    this.medsAndAllergies = data;
  };

  setHMO = (data: IUserHmo) => {
    this.userHmo = data;
  };

  getAllUserInfo() {
    this.getUserInfo();
    this.getUserAddress();
    this.getFamilyAndFriends();
    this.getMedsAndAllergies();
    this.getHMO();
  }

  *getUser() {
    this.isLoading.getUser = true;
    try {
      const res = (yield getUser()) as ApiResponse<IUserInfo | null>;
      if (res && res.data) {
        this.aUser = res.data;
      }
    } catch (error) {
      const err = parseError(error);
      toast.error(err, { position: "top-right" });
    } finally {
      this.isLoading.getUser = false;
    }
  }

  * getUserInfo() {
    this.isLoading.getUserInfo = true;
    try {
      const res = (yield getUserInfo()) as ApiResponse<IUserInfo | null>;
      if (res && res.data) {
        this.setUserInfo(res.data);
      }
    } catch (error) {
      const err = parseError(error);
      toast.error(err, { position: "top-right" });
    } finally {
      this.isLoading.getUserInfo = false;
    }
  }

  * getUserAddress() {
    this.isLoading.getUserAddress = true;
    try {
      const res = (yield getUserAddress()) as ApiResponse<IUserAddress | null>;
      if (res) {
        this.userAddress = res.data;
      }
    } catch (error) {
      toast.error(parseError(error), { position: "top-right" });
    } finally {
      this.isLoading.getUserAddress = false;
    }
  }
  * getUserById(userId: string) {
    this.isLoading.getUserById = true;
    try {
      if (this.rootStore.AdminOrderStore.isAdminReorder) {

        const res = (yield getUserById(userId)) as ApiResponse<ISelectedUserInfo | null>;
        if (res?.data) {
          this.selectedUser = res.data || null;
        }
      } else {
        const res = (yield getUserById(userId)) as ApiResponse<IFullUser | null>;
        if (res?.data) {
          this.selectUserProfile = res.data || null;
        }
      }
    } catch (error) {
      toast.error(parseError(error), { position: "top-right" });
    } finally {
      this.isLoading.getUserById = false;
    }
  }
  * getUserOrdersById(userId: string, page: number, pageSize: number, query?: any) {
    this.isLoading.getUserOrders = true;
    this.selectedUserOrders = [];
    try {
      const res = (yield getUserOrders(userId, page, pageSize, query)) as ApiResponse<IPaginatedOrder | null>;
      if (res?.data) {
        this.selectedUserOrders = res.data.orders;
      }
    } catch (error) {
      toast.error(parseError(error), { position: "top-right" });
    } finally {
      this.isLoading.getUserOrders = false;
    }
  }

  *getMemberOrders(memberId: string) {
    this.isLoading.getMemberOrders = true;
    this.selectedUserOrders = [];
    try {
      const res = (yield getMemberOrders(memberId)) as ApiResponse<IPaginatedOrder>;
      if (res?.data) {
        this.selectedUserOrders = res.data.orders;
      }
    } catch (error) {
      toast.error(parseError(error), { position: 'top-right' });
    } finally {
      this.isLoading.getMemberOrders = false;
    }
  }
  * getReferralOrders(userId: string) {
    this.isLoading.getReferralOrders = true;
    try {
      const res = (yield getReferralOrders(userId)) as ApiResponse<any | null>;
      if (res?.data) {
        const { data } = res;
        this.selectedAffiliateOrders = data?.data || [];
      }
      toast.success(`${res.message}`, { position: "top-right" });
    } catch (error) {
      toast.error(parseError(error), { position: "top-right" });
    } finally {
      this.isLoading.getReferralOrders = false;
    }
  }
  * updateUserInfo(data: IUserInfo, isOnBoarding?: boolean) {
    this.isLoading.updateUserInfo = true;
    try {
      const res = (yield updateUserInfo(data)) as ApiResponse<string>;
      toast.success(`${res.message}`, { position: "top-right" });
      this.setUserInfo(data);
      if (isOnBoarding) this.changeOnboardingStep("add");
      this.rootStore.AuthStore.getAccessToken();
    } catch (error) {
      toast.error(parseError(error), { position: "top-right" });
    } finally {
      this.isLoading.updateUserInfo = false;
    }
  }

  * createBusiness(data: IBusiness) {
    this.isLoading.updateUserInfo = true;
    try {
      const res = (yield onboardBusiness(data)) as ApiResponse<string>;
      toast.success(`${res.message}`, { position: "top-right" });
      this.rootStore.AuthStore.getAccessToken();
      this.changeOnboardingStep("add");
      if (res.status) {
        this.setNewBusiness();
      }
    } catch (error) {
      toast.error(parseError(error), { position: "top-right" });
    } finally {
      this.isLoading.updateUserInfo = false;
    }
  }

  * updateUserAddress(data: IUserAddress, isOnboarding?: boolean) {
    this.isLoading.updateUserAddress = true;
    try {
      const res = (yield updateUserAddress(data)) as ApiResponse<string>;
      toast.success(`${res.message}`, { position: "top-right" });
      this.userAddress = data;
      this.rootStore.CheckoutStore.setAddressToEditIndex("");
      if (isOnboarding) this.changeOnboardingStep("add");
    } catch (error) {
      toast.error(parseError(error), { position: "top-right" });
    } finally {
      this.isLoading.updateUserAddress = false;
    }
  }

  * updateUserAddressAdmin(data: IUserAddress, account: string, userId: string) {
    this.isLoading.updateUserAddress = true;
    try {
      const res = (yield updateUserAddressAdmin(data, account, userId)) as ApiResponse<string>;
      if (res.status) {
        toast.success(`${res.message}`, { position: "top-right" });
        this.userAddress = data;
        this.rootStore.CheckoutStore.setAddressToEditIndex("");
        getPartnerById(userId)
        this.getUserById(userId);
      }
      else {
        toast.error(`${res.message}`, { position: "top-right" })
      }
    } catch (error) {
      toast.error(parseError(error), { position: "top-right" });
    } finally {
      this.isLoading.updateUserAddress = false;
    }
  }
  * getuserPartnerMembers() {
    this.isLoading.getuserPartnerMembers = true;
    try {
      const res = (yield getPartnerMembers()) as ApiResponse<
        IMemberType | null
      >;
      if (res) {
        this.userPartnerMembers = res?.data?.members || [];
      }
    } catch (error) {
      toast.error(parseError(error), { position: "top-right" });
    } finally {
      this.isLoading.getuserPartnerMembers = false;
    }
  }

  * adduserPartnerMember(payload: IMember) {
    this.isLoading.adduserPartnerMember = true;
    try {
      const res = (yield addPartnerMember(payload)) as ApiResponse<string>;

      this.getuserPartnerMembers();
      toast.success(`${res.message}`, { position: "top-right" });
    } catch (error) {
      toast.error(parseError(error), { position: "top-right" });
    } finally {
      this.isLoading.adduserPartnerMember = false;
    }
  }

  * updateuserPartnerMember(
    payload: IMember,
    id: string
  ) {
    this.isLoading.updateuserPartnerMember = true;
    try {
      const res = (yield updatePartnerMember(payload, id)) as ApiResponse<
        string
      >;
      this.getuserPartnerMembers();
      this.userPartnerMember = {} as IMember;
      this.rootStore.CheckoutStore.deliveryAddress = {
        firstName: "",
        lastName: "",
        email: "",
        street: "",
        city: "",
        state: "",
        phoneNumber: "",
        landmark: "",
        longitude: "",
        latitude: "",
      };
      this.rootStore.CheckoutStore.setAddressToEditIndex("");

      toast.success(`${res.message}`, { position: "top-right" });
    } catch (error) {
      toast.error(parseError(error), { position: "top-right" });
    } finally {
      this.isLoading.updateuserPartnerMember = false;
    }
  }

  * deleteuserPartnerMember(id: string) {
    this.isLoading.deleteuserPartnerMember = true;
    try {
      const res = (yield deletePartnerMember(
        id
      )) as ApiResponse<string>;
      this.getuserPartnerMembers();
      toast.success(`${res.message}`, { position: "top-right" });
      this.getuserPartnerMembers();
    } catch (error) {
      toast.error(parseError(error), { position: "top-right" });
    } finally {
      this.isLoading.deleteuserPartnerMember = false;
    }
  }

  * getFamilyAndFriends() {
    this.isLoading.getFamilyAndFriends = true;
    try {
      const res = (yield getFamilyAndFriends()) as ApiResponse<
        IFamilyAndFriends[] | null
      >;
      if (res) {
        this.familyAndFriends = res.data || [];
      }
    } catch (error) {
      toast.error(parseError(error), { position: "top-right" });
    } finally {
      this.isLoading.getFamilyAndFriends = false;
    }
  }

  * getFamilyOrFriends(fnfId: string) {
    try {
      const { data } = yield getFamilyAndFriends();

      this.familyOrFriends = data.find(
        (item: IFamilyAndFriends) => item._id === fnfId
      ) as IFamilyAndFriends;
    } catch (error) {
      toast.error(parseError(error), { position: "top-right" });
    }
  }

  * addFamilyAndFriends(payload: {
    isMember: boolean;
    data: IFamilyAndFriends;
  }) {
    this.isLoading.addFamilyAndFriends = true;
    try {
      const res = (yield addFamilyAndFriends(payload)) as ApiResponse<string>;

      this.getFamilyAndFriends();
      toast.success(`${res.message}`, { position: "top-right" });
      if (!payload.isMember) {
        this.rootStore.CheckoutStore.toggleNewOrderAddressForm();
      }
    } catch (error) {
      toast.error(parseError(error), { position: "top-right" });
    } finally {
      this.isLoading.addFamilyAndFriends = false;
    }
  }

  * updateFamilyAndFriends(
    payload: { isMember: boolean; data: IFamilyAndFriends },
    fnfId: string
  ) {
    this.isLoading.updateFamilyAndFriends = true;
    try {
      const res = (yield updateFamilyAndFriends(payload, fnfId)) as ApiResponse<
        string
      >;
      this.getFamilyAndFriends();
      this.familyOrFriends = {} as IFamilyAndFriends;
      this.rootStore.CheckoutStore.deliveryAddress = {
        firstName: "",
        lastName: "",
        email: "",
        street: "",
        city: "",
        state: "",
        phoneNumber: "",
        landmark: "",
        longitude: "",
        latitude: "",
      };
      this.rootStore.CheckoutStore.setAddressToEditIndex("");

      toast.success(`${res.message}`, { position: "top-right" });
    } catch (error) {
      toast.error(parseError(error), { position: "top-right" });
    } finally {
      this.isLoading.updateFamilyAndFriends = false;
    }
  }

  * deleteFamilyAndFriends(isMember: boolean, fnfId: string) {
    this.isLoading.deleteFamilyAndFriends = true;
    try {
      if (isMember !== null && fnfId) {
        const res = (yield deleteFamilyAndFriends(
          isMember,
          fnfId
        )) as ApiResponse<string>;

        toast.success(`${res.message}`, { position: "top-right" });
        this.getFamilyAndFriends();
        this.toggleRemoveFamilyandFriends();
      }
    } catch (error) {
      toast.error(parseError(error), { position: "top-right" });
    } finally {
      this.isLoading.deleteFamilyAndFriends = false;
    }
  }
  * joinPartnerNetwork(partnerId: string) {
    this.isLoading.joinPartnerNetwork = true;
    try {
      const res = (yield joinPartnerNetwork(partnerId)) as ApiResponse<string
        | null>;
      if (res.data) {
        this.memberPartner = res.data;
      }
      toast.success(`${res.message}`, { position: "top-right" });

    } catch (error) {
      toast.error(parseError(error), { position: "top-right" });

    } finally {
      this.isLoading.joinPartnerNetwork = false;
    }


  }
  * getMedsAndAllergies() {
    this.isLoading.getMedsAndAllergies = true;
    try {
      const res = (yield getUserAllergies()) as ApiResponse<IMedAndAllergies | null>;
      if (res) {
        this.medsAndAllergies = res.data;
      }
    } catch (error) {
      toast.error(parseError(error), { position: "top-right" });
    } finally {
      this.isLoading.getMedsAndAllergies = false;
    }
  }

  * updateAllergies(data: IAllergies) {
    this.isLoading.updateAllergies = true;
    try {
      const res = (yield updateUserAllergies(data)) as ApiResponse<string>;
      this.getMedsAndAllergies();
      toast.success(`${res.message}`, { position: "top-right" });
    } catch (error) {
      toast.error(parseError(error), { position: "top-right" });
    } finally {
      this.isLoading.updateAllergies = false;
    }
  }

  * updateMeds(data: IMeds) {
    this.isLoading.updateMeds = true;
    try {
      const res = (yield updateUserMeds(data)) as ApiResponse<string>;
      this.getMedsAndAllergies();
      toast.success(`${res.message}`, { position: "top-right" });
    } catch (error) {
      toast.error(parseError(error), { position: "top-right" });
    } finally {
      this.isLoading.updateMeds = false;
    }
  }

  * getHMO() {
    this.isLoading.getHMO = true;
    try {
      const res = (yield getUserHMO()) as ApiResponse<IUserHmo | null>;
      if (res) {
        this.userHmo = res.data;
      }
    } catch (error) {
      toast.error(parseError(error), { position: "top-right" });
    } finally {
      this.isLoading.getHMO = false;
    }
  }

  * updateHMO(data: IUserHmo) {
    this.isLoading.updateHMO = true;
    try {
      const res = (yield updateUserHMO(data)) as ApiResponse<string>;
      this.getHMO();
      toast.success(`${res.message}`, { position: "top-right" });
    } catch (error) {
      toast.error(parseError(error), { position: "top-right" });
    } finally {
      this.isLoading.updateHMO = false;
    }
  }

  * getOrderReport(code: any) {
    this.isLoading.getOrderReport = true;
    try {
      const res = (yield getOrderReport(code)) as ApiResponse<
        IOrderReport[] | null
      >;
      if (res) {
        this.orderReport = res.data;
      }
    } catch (error) {
      toast.error(parseError(error), { position: "top-right" });
    } finally {
      this.isLoading.getOrderReport = false;
    }
  }
  * updateActivateCustomer(data: IActivateCustomer) {
    this.isLoading.updateActivateCustomer = true;
    try {
      const res = (yield updateActivateCustomer(data)) as ApiResponse<string>;
      toast.success(`${res.message}`, { position: "top-right" });
      this.selectedUser = {} as ISelectedUserInfo | null;
      return res;
    } catch (error) {
      toast.error(parseError(error), { position: "top-right" });
    } finally {
      this.isLoading.updateActivateCustomer = false;
    }
  }
  * deleteUser(id: string) {
    this.isLoading.deleteUser = true;
    try {
      const res = (yield deleteUser(id)) as ApiResponse<string>;
      toast.success(`${res.message}`, { position: "top-right" });
      this.selectedUser = {} as ISelectedUserInfo | null;
      return res;
    } catch (error) {
      toast.error(parseError(error), { position: "top-right" });
    } finally {
      this.isLoading.deleteUser = false;
    }
  }
  * hardDeleteUser(userId: string, isPartner: boolean) {
    this.isLoading.deleteUser = true;
    try {
      const res = (yield hardDeleteUser(userId, isPartner)) as ApiResponse<any>;
      toast.success(`${res.message}`, { position: "top-right" });
      this.rootStore.PartnerStore.selectedPartner = {} as IPartner;
      this.rootStore.PartnerStore.getAllPartners()
      return res;
    } catch (error) {
      toast.error(parseError(error), { position: "top-right" });
    } finally {
      this.isLoading.deleteUser = false;
    }
  }
  * memberRequestStatus(memberId: string, status: string) {
    this.isLoading.memberRequestStatus = true;
    try {
      const res = (yield memberRequestStatus(memberId, status)) as ApiResponse<any>;
      toast.success(`${res.message}`, { position: "top-right" });
      this.getMemberRequest()
      return res;
    } catch (error) {
      toast.error(parseError(error), { position: "top-right" });
    } finally {
      this.isLoading.memberRequestStatus = false;
    }
  }
  * getMemberRequest(id?: string) {
    this.isLoading.getMemberRequest = true;
    try {
      const res = (yield getMemberRequest()) as ApiResponse<any>;
      if (res.data) {
        this.memberRequest = res.data as IMemberRequest[];
        this.memberUser = this.memberRequest.find((item: IMemberRequest) => item?._id === id) as IMemberRequest
      }

      return res;
    } catch (error) {
      toast.error(parseError(error), { position: "top-right" });
    } finally {
      this.isLoading.getMemberRequest = false;
    }

  }
  * getUserPartner() {
    this.isLoading.getUserPartner = true;
    try {
      const res = (yield getUserPartner()) as ApiResponse<any>;
      if (res.data) {
        this.userPartners = res.data.data as [];
      }

      return res;
    } catch (error) {
      toast.error(parseError(error), { position: "top-right" });
    } finally {
      this.isLoading.getUserPartner = false;
    }

  }
  * getFamilyAndFriendsAdmin(userId: string) {
    this.isLoading.getFamilyAndFriendsAdmin = true;
    try {
      const res = (yield getFamilyAndFriendsAdmin(userId)) as ApiResponse<any>;
      if (res.data) {
        this.userFamilyAddress = res.data as [];
      }

      return res;
    } catch (error) {
      toast.error(parseError(error), { position: "top-right" });
    } finally {
      this.isLoading.getFamilyAndFriendsAdmin = false;
    }
  }

  * getFamilyOrFriendsAdmin(fnfId: string) {
    try {
      const { data } = yield getFamilyAndFriendsAdmin(fnfId);

      this.userfamilyOrFriendsInfo = data.find(
        (item: IFamilyAndFriends) => item._id === fnfId
      ) as IFamilyAndFriends;
    } catch (error) {
      toast.error(parseError(error), { position: "top-right" });
    }
  }
  * addFamilyAndFriendsAdmin(body: {
    isMember: boolean;
    data: IFamilyAndFriends;
  }, userId: string) {
    this.isLoading.addFamilyAndFriendsAdmin = true;
    try {
      const res = (yield addFamilyAndFriendsAdmin(body, userId)) as ApiResponse<any>;

      this.getFamilyAndFriendsAdmin(userId);
      toast.success(`${res.message}`, { position: "top-right" });
      if (!body.isMember) {
        this.rootStore.CheckoutStore.toggleNewOrderAddressForm();
      }
      return res;
    } catch (error) {
      toast.error(parseError(error), { position: "top-right" });
    } finally {
      this.isLoading.addFamilyAndFriendsAdmin = false;
    }
  }
  * updateFamilyAndFriendsAdmin(body: {
    isMember: boolean;
    data: IFamilyAndFriends;
  }, id: string, userId: string) {
    this.isLoading.updateFamilyAndFriendsAdmin = true;
    try {
      const res = (yield updateFamilyAndFriendsAdmin(body, id, userId)) as ApiResponse<any>;

      this.getFamilyAndFriendsAdmin(userId);
      toast.success(`${res.message}`, { position: "top-right" });
      this.userfamilyOrFriendsInfo = {} as IFamilyAndFriends;
      this.rootStore.CheckoutStore.deliveryAddress = {
        firstName: "",
        lastName: "",
        email: "",
        street: "",
        city: "",
        state: "",
        phoneNumber: "",
        landmark: "",
        longitude: "",
        latitude: "",
      };
      this.rootStore.CheckoutStore.setAddressToEditIndex("");
      return res;
    } catch (error) {
      toast.error(parseError(error), { position: "top-right" });
    } finally {
      this.isLoading.updateFamilyAndFriendsAdmin = false;
    }
  }
}
