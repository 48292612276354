import { Text, Button, Flex, VStack , Input} from '@chakra-ui/react';
import PRModal from 'components/modal';
import { observer } from 'mobx-react-lite';
import React from 'react'
import { useStore } from 'store';

const SuspendPartnerModal = () => {
   const {
     PartnerStore: { togglSuspendPartnerModal, isSuspendPartnerOpen, selectedPartner }
   } = useStore();
  return (
    <PRModal
      title="Suspend this partner"
      open={isSuspendPartnerOpen}
      onClose={() => togglSuspendPartnerModal()}
      width="400px"
    >
      <Flex flexDir="column" py="1rem">
        <VStack align="end" gap="1.5rem">
          <Text>
            You're about to suspend <b>{selectedPartner?.partnerName}</b>. Do you want to go ahead
            with this ?
          </Text>
          <VStack w="100%">
            <Input type="text" placeholder="Enter a suspend reason" />
          </VStack>

          <Flex justify="end">
            <Button type="submit" size="sm" bg="#EE4E61" fontWeight="900">
              Suspend Partner{' '}
            </Button>
          </Flex>
        </VStack>
      </Flex>
    </PRModal>
  );
}

export default observer(SuspendPartnerModal)
