import { AxiosError } from 'axios';
import { ValidationError } from 'yup';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { defaultTags } from 'data/order';
import { getAuthTokens, userIsAuthenticated, refreshAccessToken } from './auth-util';

dayjs.extend(duration);
dayjs.extend(relativeTime);

export function responsiveXl<T = string>(mobile: T, desktop: T) {
  return [mobile, null, null, null, desktop];
}

export function responsiveLg<T = string>(mobile: T, desktop: T) {
  return [mobile, null, null, desktop];
}

export function responsiveMd<T = string>(mobile: T, desktop: T) {
  return [mobile, null, desktop];
}

export function capitalizeFirstLetter(string: string) {
  const words = string.toLowerCase().split(/\s+/);
  const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
  return capitalizedWords.join(' ');
}

export const isYupError = (error: any): error is ValidationError => {
  return error.name === 'ValidationError' && error.inner instanceof Array;
};

export const isGenericError = (error: any): error is Error => {
  return typeof error.message !== 'undefined';
};

export const isAxiosError = (error: any): error is AxiosError<Partial<PRApiError>> => {
  return error.isAxiosError === true;
};
export const isStringError = (error: any): error is string => {
  return typeof error !== 'object' && error !== null;
};
export function parseError(error: any): string {
  if (isAxiosError(error)) {
    return error?.response?.data?.message ?? 'Something went wrong';
  }
  if (isYupError(error)) {
    return error?.inner?.map((e: any) => e?.message).join(', ') ?? 'Something went wrong';
  }
  if (isGenericError(error)) {
    return error?.message ?? 'Something went wrong';
  }
  if (isStringError(error)) {
    return error || 'Something went wrong';
  }
  return 'Something went wrong';
}

export const truncateText = (text: string, noOfChar: number, truncText?: string) =>
  text?.length > noOfChar ? text?.slice(0, noOfChar) + (truncText || '...') : text;

export const parseAmount = (amount: number): string => {
  const amountToFormat = amount || 0;
  const formattedAmount = amountToFormat.toLocaleString('en-NG', {
    style: 'currency',
    currency: 'NGN'
  });

  return formattedAmount;
};

export const parseDate = (date: Date | string) => new Date(date).toLocaleDateString('en-NG');

export const formatNumber = (num: number, decimalPlaces = 2): string => {
  if (isNaN(num) || !isFinite(num)) {
    return '0';
  }
  return num.toFixed(decimalPlaces);
};
export const hideMiddleLetters = (word: string) => {
  const length = word.length;
  const start = Math.floor((length - 6) / 2);
  const end = start + 6;
  const asterisks = '*'.repeat(6);

  return word.substring(0, start) + asterisks + word.substring(end);
};
export const formatDateTime = (date: string): string => {
  const parsedDate = dayjs(date);
  const formattedDateTime = parsedDate.format('MMM D, YYYY h:mm A');

  return formattedDateTime;
};

export const timeElapsed = (inputDate?: Date | string): string => {
  try {
    if (!inputDate) {
      return '';
    }

    const date = new Date(inputDate);

    if (isNaN(date.getTime())) {
      return '';
    }

    return dayjs(date).fromNow();
  } catch (error) {
    return '';
  }
};

export const getDuration = (timestamp: number) => {
  const durationObj = dayjs.duration(timestamp, 'seconds'); // Convert timestamp to duration object

  return durationObj.humanize(); // Get humanized duration
};

export const getTagColor = (index: number, type?: string, pickerColor?: string) => {
  if (type === 'bg') {
    return pickerColor || defaultTags[index]?.bgColor || '';
  } else {
    return pickerColor || defaultTags[index]?.color || '';
  }
};

export async function getApiHeaders(): Promise<ApiHeader> {
  let refreshToken, accessToken;
  const tokens = getAuthTokens();
  if (tokens) {
    accessToken = tokens.accessToken;
    refreshToken = tokens.refreshToken;
  }

  const headers: ApiHeader = {
    'Content-Type': 'multipart/form-data'
  };

  if (userIsAuthenticated()) {
    headers.Authorization = `Bearer ${accessToken}`;
  } else {
    if (refreshToken) {
      const getAccessToken = await refreshAccessToken();
      if (getAccessToken) {
        headers.Authorization = `Bearer ${getAccessToken}`;
      }
    }
  }

  return headers;
}
