import { Box, Button, Flex, Text, Textarea, VStack } from '@chakra-ui/react';
import PRModal from 'components/modal';
import { closeSubscriptionReasons } from 'data/order';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import Select from 'react-select';
import { useStore } from 'store';


const CloseSubscriptionModal = () => {
 
  const {
    AdminOrderStore: {
      isCloseSubscriptionModalOpen,
      toggleCloseSubscriptionModal,
      isLoading,
      closeSubscription,
      subscriptionData,
    }
  } = useStore();

  const [formDetail, setformDetail] = useState({ message: '' });
  const [others, setOthers] = useState<string>('');
  
  
  
    const handleTextAreaChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
      const { value } = e.target as HTMLTextAreaElement;
      setOthers(value);
    };
  
    async function handleFormSubmit(e: React.FormEvent) {
      e.preventDefault(); 
      if (!subscriptionData?.subscriptionNumber) return;
      let message = formDetail.message;
      if (message === 'Others') {
        message = others;
      }
      const res = await closeSubscription(message, subscriptionData?.subscriptionNumber); 
      if (res) {
        toggleCloseSubscriptionModal();
        setOthers('');
      }
    }
  return (
    <PRModal title="Close subscription?" open={isCloseSubscriptionModalOpen} onClose={toggleCloseSubscriptionModal}>
      <form autoComplete="off" onSubmit={handleFormSubmit}>
        <VStack
          fontSize={{
            base: '12px',
            md: '14px'
          }}
          align="start"
          w="100%"
        >
          <Box w="100%">
            <Text>Reason for closing subscription </Text>

            <Select
              options={closeSubscriptionReasons}
              isSearchable={false}
              onChange={(selectedOption: any) => {
                if (selectedOption) {
                  const value = selectedOption.value;

                  if (value === 'Others') {
                    setOthers('');
                  }
                  setformDetail((prev) => ({ ...prev, message: value }));
           
                }
              }}
              value={
                formDetail
                  ? { value: formDetail.message, label: formDetail.message }
                  : { value: '', label: 'Select a reason' }
              }
              menuPlacement="auto"
              menuPosition="fixed"
              styles={{
                menu: (provided: any) => ({
                  ...provided,
                  maxHeight: '300px',
                  overflowY: 'auto'
                })
              }}
            />
          </Box>
          <Box w="100%">
            <Text>Other reasons please specify</Text>

            <Textarea h="30vh" value={others} name="others" onChange={handleTextAreaChange} />
          </Box>


          <Flex justify="end" w="100%">
            <Button type="submit" size="sm" isLoading={isLoading.closeSubscription}>
              Submit
            </Button>
          </Flex>
        </VStack>
      </form>
    </PRModal>
  );
};

export default observer(CloseSubscriptionModal);
