import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const RedirectTo: React.FC<{
  path?: string;
}> = ({ path }) => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(path ?? '/auth/login');
  }, [navigate]);

  return null;
};
