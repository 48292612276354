import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Suspense, lazy } from 'react';
import Loading from 'components/loading';
import { useStore } from '../../store';
import { Routes } from '../../constant';
import { Box, Flex } from '@chakra-ui/react';
const Navbar = lazy(() => import('./components/navbar'));
const AuthFooter = lazy(() => import('./components/footer'));

const AuthLayout = () => {
  const {
    AuthStore: { isAuthenticated, user }
  } = useStore();
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (isAuthenticated) {
      if (user.isAdmin) {
        navigate(Routes.AdminDashboard);
      } else {
        navigate(Routes.Home);
      }
      
    }
  }, [isAuthenticated, navigate]);
  return (
    <Suspense fallback={<Loading />}>
    <Box
      sx={{
        width: '100vw',
        h: '100vh',
        backgroundPosition: 'bottom',
        backgroundSize: 'cover'
      }}
    >
      <Navbar />
      <Flex h="calc(100vh - 115px)" justify="center" align="center">
        <Box
          sx={{
            borderRadius: '18px',
            minH: '403px',
            w: `${pathname.includes(Routes.GuestConfirmationPage) ? null : '460px'}`,
            maxW: '95%',
            border: `${
              pathname.includes(Routes.GuestConfirmationPage) ? 'none' : '1px solid #d2d5dab2'
            }`,
            backdropFilter: 'blur(38px)'
          }}
        >
          <Outlet />
        </Box>
      </Flex>
      <AuthFooter />
      </Box>
    </Suspense>
  );
};

export default observer(AuthLayout);
