export const chartData2 = {
  labels: ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'],
  datasets: [
    {
      label: 'Profit',
      data: [500, 300, 400, 200, 100, 350, 400],
      backgroundColor: '#00C7B8CC',
      borderRadius: 5,
      barThickness: 10 // Set the width of the bar for Values 1,
    },
    {
      label: 'Loss',
      data: [300, 250, 300, 400, 300, 200, 150],
      backgroundColor: '#541AA9B2',
      borderRadius: 5,
      barThickness: 10 // Set the width of the bar for Values 2
    }
  ]
};
export const chartData = {
  labels: [],
  datasets: [
    {
      label: '',
      data: [],
      backgroundColor: '',
      borderRadius: 5,
      barThickness: 10 // Set the width of the bar for Values 1,
    }
  ]
};
export const options2 = {
  indexAxis: 'x', // Display the labels below the chart
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false
    }
  },
  scales: {
    x: {
      grid: {
        display: false
      },
      ticks: {
        font: {
          weight: 'bold' // Set the font weight of the labels
        }
      }
    },
    y: {
      grid: {
        display: false
      }
    }
  }
};

export const options = {
  indexAxis: 'x',
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false
    }
  },
  scales: {
    x: {
      grid: {
        display: false
      },
      ticks: {
        font: {
          weight: 'bold' // Set the font weight of the labels
        }
      }
    },
    y: {
      grid: {
        display: false
      }
    }
  }
};
