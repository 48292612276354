class Permission {
  store: any;

  constructor(store: any) {
    this.store = store;
  }

  hasFeatureAccess(allowedRoles: string[] | string): boolean {
    if (typeof allowedRoles === 'string') {
      return !!this.store?.features?.includes(allowedRoles);
    } else {
      return !!allowedRoles.some((role) => this.store?.features?.includes(role));
    }
  }

  hasCategoryAccess(allowedRoles: string[] | string): boolean {
    if (typeof allowedRoles === 'string') {
      return !!this.store?.categories?.includes(allowedRoles);
    } else {
      return !!allowedRoles.some((role) => this.store?.categories?.includes(role));
    }
  }
}

export default Permission;
