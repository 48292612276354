import config from '../../config';
import { client } from '..';

export const getUserConsultations = async (): Promise<
  ApiResponse<IConsultation | null> | undefined
> => {
  return await client<ApiResponse<IConsultation | null>>(
    `${config.AUTH_BASE_URL}/api/contact/consultations`,
    'GET'
  );
};

export const getAllConsultations = async (
  params: Record<string, string | number>
): Promise<ApiResponse<IPaginatedResponse<IConsultation> | null> | undefined> => {
  const {
    page,
    pageSize,
    status,
    customerSource,
    type,
    partnerType,
    searchKey,
    startDate: startDate,
    endDate: endDate
  } = params;
  return await client<ApiResponse<IPaginatedResponse<IConsultation> | null>>(
    `${config.AUTH_BASE_URL}/api/contact/consultations/all?page=${page}&size=${pageSize}&status=${status || ''}&customerSource=${customerSource || ''}&type=${type || ''}&partnerType=${partnerType || ''}&searchKey=${searchKey || ''}&startDate=${startDate}&endDate=${endDate}`,
    'GET'
  );
};

export const getConsultationById = async (
  id: string
): Promise<ApiResponse<IConsultation | null> | undefined> => {
  return await client<ApiResponse<IConsultation | null>>(
    `${config.AUTH_BASE_URL}/api/contact/consultations/${id}`,
    'GET'
  );
};

export const assignRunnersToConsultation = async (
  id: string,
  payload: { assigneeId: string; assigneeName: string }
): Promise<ApiResponse<IConsultation | null> | undefined> => {
  return await client<ApiResponse<IConsultation | null>>(
    `${config.AUTH_BASE_URL}/api/contact/consultations/assign-runners/${id}`,
    'PATCH',
    { body: payload }
  );
};

export const addCommentToConsultation = async (
  id: string,
  message: string
): Promise<ApiResponse<IConsultation | null> | undefined> => {
  return await client<ApiResponse<IConsultation | null>>(
    `${config.AUTH_BASE_URL}/api/contact/consultations/add-comment/${id}`,
    'POST',
    { body: { message } }
  );
};

export const updateConsultation = async (
  id: string,
  payload: Record<string, any>
): Promise<ApiResponse<IConsultation | null> | undefined> => {
  return await client<ApiResponse<IConsultation | null>>(
    `${config.AUTH_BASE_URL}/api/contact/consultations/${id}`,
    'PATCH',
    { body: payload }
  );
};

export const deleteConsultation = async (
  id: string
): Promise<ApiResponse<IConsultation | null> | undefined> => {
  return await client<ApiResponse<IConsultation | null>>(
    `${config.AUTH_BASE_URL}/api/contact/consultations/soft-delete/${id}`,
    'DELETE'
  );
};

export const initiateConsultationPayment = async (
  payload: IConsultationPayment
): Promise<ApiResponse<string> | undefined> => {
  return await client<ApiResponse<string>>(
    `${config.AUTH_BASE_URL}/api/contact/initiate-talk-payment`,
    'POST',
    {
      body: payload
    }
  );
};

export const signalADoc = async (): Promise<ApiResponse<{ redirectUrl: string }> | undefined> => {
  return await client<ApiResponse<{ redirectUrl: string }>>(
    `${config.AUTH_BASE_URL}/api/contact/signal-a-doc`,
    'POST'
  );
};

export const getConsultationStats = async (data: { startDate: string; endDate: string}): Promise<
  ApiResponse<Record<string, number> | null> | undefined
> => {
  const { startDate, endDate } = data;
  return await client<ApiResponse<Record<string, number> | null>>(
    `${config.AUTH_BASE_URL}/api/contact/consultations/all/stats?startDate=${startDate}&endDate=${endDate}`,
    'GET'
  );
};
