import { action, computed, makeObservable, observable, reaction } from 'mobx';
import storage from 'utils/storage';
import { ACCESS_TOKEN, userKey } from 'constant';
import { decrypt_key, decrypt_user0bj, encrypt_userobj, setTokens } from 'utils/auth-util';
import { RootStore } from '..';
import {
  resetPassword,
  SignIn,
  signUp as register,
  resendAuthToken,
  googleSignUp,
  googleSignIn
} from 'requests/auth';

interface ISignupRes {
  status: boolean;
  message: string;
  isAdmin?: boolean;
}

export class AuthStore {
  onboardingStep: TOnboardingStep = 'info';
  isLoading = {
    signUp: false,
    login: false,
    resetPassword: false,
    googleAuth: false
  };

  isAuthenticated = !!this.token;
  loading = false;
  rootStore: RootStore;
  loggedInUserDetails = {
    id: null,
    userId: '',
    email: null,
    status: false,
    role: null,
    isAdmin: null,
    referralCode: null,
    permissions: [],
    token: null,
    isBusiness: false,
    isAffiliate: false
  };
  permissionCategories: string[] = [];
  permissionFeatures: string[] = [];

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      isLoading: observable,
      isAuthenticated: observable,
      onboardingStep: observable,
      loading: observable,
      loggedInUserDetails: observable,

      login: action.bound,
      signUp: action.bound,
      isAdmin: action.bound,
      resetPassword: action.bound,
      changeOnboardingStep: action.bound,
      getAccessToken: action.bound,
      googleAuth: action.bound,
      updateCategoryTitle: action.bound,
      updatePermissions: action.bound,

      token: computed,
      user: computed
    });
    this.rootStore = rootStore;

    const key = storage.get(userKey);

    if (key) {
      this.loggedInUserDetails = decrypt_user0bj(key);

      if (this.loggedInUserDetails.isAdmin) {
        const permissions: IPermission[] = this.loggedInUserDetails?.permissions;
        this.updateCategoryTitle(permissions);
      }
    }
    // observe changes to the token computed property and set the isAuthenticated and loading properties accordingly
    reaction(
      () => this.token,
      (token) => {
        if (token) {
          this.isAuthenticated = true;
          this.loading = false;
        } else {
          this.isAuthenticated = false;
          this.loading = false;
        }
      }
    );
  }

  async signUp(data: ISignUpRequest): Promise<ISignupRes> {
    this.isLoading.signUp = true;

    try {
      const response = await register(data);

      if (response.status) {
        const { accessToken, refreshToken } = response.data;

        setTokens(accessToken, refreshToken);
        storage.set(userKey, encrypt_userobj(response.data));

        this.loggedInUserDetails = response.data;
        this.isAuthenticated = true;

        if (response.data.isAdmin) {
          const permissions: IPermission[] = response.data.permissions;
          this.updateCategoryTitle(permissions);
        }

        return {
          status: true,
          message: response.message
        };
      }

      return {
        status: false,
        message: response.message
      };
    } catch (error: any) {
      return {
        status: false,
        message: error.response.data.message
      };
    } finally {
      this.isLoading.signUp = false;
    }
  }

  async resetPassword(payload: IForgotPasswordRequest) {
    this.isLoading.resetPassword = true;
    try {
      const response = await resetPassword(payload);
      if (response.status) {
        return {
          status: true,
          message: response.message
        };
      }
      return {
        status: false,
        message: response.message
      };
    } catch (error: any) {
      return {
        status: false,
        message: error.response.data.message
      };
    } finally {
      this.isLoading.resetPassword = false;
    }
  }

  async login(data: ILoginRequest): Promise<ISignupRes> {
    this.isLoading.login = true;
    try {
      const response = await SignIn(data);

      if (response.status) {
        const { accessToken, refreshToken } = response.data;

        setTokens(accessToken, refreshToken);
        storage.set(userKey, encrypt_userobj(response.data));

        this.loggedInUserDetails = response.data;
        this.isAuthenticated = true;

        if (response.data.isAdmin) {
          const permissions: IPermission[] = response.data.permissions;
          this.updateCategoryTitle(permissions);
        }

        return {
          status: true,
          message: response.message,
          isAdmin: this.isAdmin(response.data.isAdmin)
        };
      } else {
        return {
          status: false,
          message: response.message
        };
      }
    } catch (error: any) {
      return {
        status: false,
        message: error.response.data.message
      };
    } finally {
      this.isLoading.login = false;
    }
  }

  async googleAuth(type: string, token: string): Promise<ISignupRes> {
    this.isLoading.googleAuth = true;
    try {
      let response = null;
      if (type === 'login') {
        response = await googleSignIn(token);
      } else {
        response = await googleSignUp(token);
      }

      if (response.status) {
        const { accessToken, refreshToken } = response.data;

        setTokens(accessToken, refreshToken);
        storage.set(userKey, encrypt_userobj(response.data));
        this.loggedInUserDetails = response.data;
        this.isAuthenticated = true;

        if (response.data.isAdmin) {
          const permissions: IPermission[] = response.data.permissions;
          this.updateCategoryTitle(permissions);
        }

        return {
          status: true,
          message: response.message,
          isAdmin: this.isAdmin(response.data.role)
        };
      } else {
        return {
          status: false,
          message: response.message
        };
      }
    } catch (error: any) {
      return {
        status: false,
        message: error.response.data.message
      };
    } finally {
      this.isLoading.googleAuth = false;
    }
  }

  async getAccessToken(): Promise<ISignupRes> {
    try {
      const response = await resendAuthToken();

      if (response.status) {
        storage.set(userKey, encrypt_userobj(response.data));

        const { accessToken, refreshToken } = response.data;

        setTokens(accessToken, refreshToken);
        this.loggedInUserDetails = response.data;
        this.isAuthenticated = true;

        if (response.data.isAdmin) {
          const permissions: IPermission[] = response.data.permissions;
          this.updateCategoryTitle(permissions);
        }

        return {
          status: true,
          message: response.message,
          isAdmin: this.isAdmin(response.data.role)
        };
      } else {
        return {
          status: false,
          message: response.message
        };
      }
    } catch (error: any) {
      return {
        status: false,
        message: error.response.data.message
      };
    }
  }

  updatePermissions(permissions: IRoleFeature[]) {
    const userPayload = decrypt_user0bj(storage.get(userKey));
    const newPayload = {
      ...userPayload,
      permissions
    };

    storage.set(userKey, encrypt_userobj(newPayload));
    this.updateCategoryTitle(permissions);
  }

  changeOnboardingStep(step: TOnboardingStep) {
    this.onboardingStep = step;
  }

  isAdmin(role?: string) {
    return !!(role && role !== 'CUSTOMER');
  }

  get token() {
    const key = storage.get(ACCESS_TOKEN);

    if (key) {
      return decrypt_key(key);
    } else {
      return null;
    }
  }

  get user() {
    return this.loggedInUserDetails;
  }

  get features() {
    return this.permissionFeatures;
  }

  get categories() {
    return this.permissionCategories;
  }

  set addFormData(data: any) {
    storage.set('formData', JSON.stringify(data));
  }

  updateFormData(data: any) {
    const formData = JSON.parse(storage.get('formData') || '{}');
    const newData = { ...formData, ...data };

    this.addFormData(newData);
  }

  updateCategoryTitle(permissions: IRoleFeature[]) {
    this.permissionCategories = permissions.map(
      (permission: IPermission) => permission && permission?.category
    );
    this.permissionFeatures = permissions.map(
      (permission: IPermission) => permission && permission?.title
    );
  }
}
