import {
  Box,
  VStack,
  Text,
  Input,
  Textarea,
  Image,
  InputGroup,
  InputLeftElement,
  Spinner,
  Button,
  Flex,
  ModalFooter,
  Card,
  FormControl,
  FormErrorMessage
} from '@chakra-ui/react';
import PRModal from 'components/modal';
import useFileUpload from 'hooks/useImageUpload';
import { observer } from 'mobx-react-lite';
import { Formik, Form } from 'formik';
import React, { useState, useEffect } from 'react';
import { LiaCloudUploadAltSolid } from 'react-icons/lia';
import { MdDelete } from 'react-icons/md';
import { deleteImageKey } from 'requests/inventory';
import { useStore } from 'store';
import { CampaignDetailsSchema } from 'validations/campaignValidators';
import { useCampaign, useCreateCampaign, useUpdateCampaign } from 'hooks/queryHooks';

const UploadBanner = () => {
  const [loadingStates, setLoadingStates] = useState(Array.from({ length: 5 }, () => false));
  const [images, setImages] = useState<Record<string, any>[]>(
    new Array(5).fill({ key: '', fileUrl: '' })
  );
  const {
    CampaignStore: {
      toggleCreateCampaignModal,
      isCreateCampaignModalOpen,
      campaignForm,
      pageSize: pageLimit,
      pageNumber: currentPage,
      newCampaign,
      editCampaign,
    }
  } = useStore();
  const { refetch } = useCampaign(currentPage, pageLimit);
  const { mutate: createCampaign, isLoading: createBanner } =
    useCreateCampaign(toggleCreateCampaignModal);
  const { mutate: updateCampaign, isLoading: updateBanner } = useUpdateCampaign(
    toggleCreateCampaignModal,
    editCampaign
  );
  const { handleFileUpload } = useFileUpload();
  const handleImageDelete = (key: string) => {
    let updatedImages = [...images] as any;
    const modifiedKey = key.replace(/.*\//, '');

    deleteImageKey('', modifiedKey);
    updatedImages = images.map((image: any) =>
      image.key === key ? { key: '', fileUrl: '' } : image
    );
    setImages(updatedImages);
  };

  const handleFileUploadWrapper = async (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const file = e?.target?.files as any;
    await handleFileUpload(file[0], (res) => {
      const updatedImages = [...images];

      updatedImages[index] = {
        key: res.key,
        fileUrl: res.fileUrl
      };

      setImages(updatedImages);
    });
  };
  const handleSubmit = async (values: ICampaign) => {
    const newImages = images.filter((image) => image.key !== '');

    const newValues: any = {
      title: values.title,
      description: values.description,
      images: newImages
    };

    if (newCampaign === 'newCampaign') {
      await createCampaign(newValues);
    } else if (newCampaign === 'editCampaign') {
      await updateCampaign(newValues, editCampaign as any);
    }
    await refetch();
  };
  useEffect(() => {
    const imagesLength = campaignForm?.images?.length || 0;
    const diff = 5 - imagesLength;
    const newImages = [
      ...(campaignForm?.images || []),
      ...new Array(diff).fill({ key: '', fileUrl: '' })
    ];

    setImages(newImages);
    setLoadingStates(Array.from({ length: 5 }, () => false));
  }, [campaignForm]);

  return (
    <PRModal
      title="Banners"
      open={!isCreateCampaignModalOpen ? false : true}
      onClose={toggleCreateCampaignModal}
    >
      <Box
        mt=".5rem"
        mb="4rem"
        h="65vh"
        overflowY="scroll"
        sx={{
          '&::-webkit-scrollbar': {
            display: 'none'
          }
        }}
      >
        <Formik
          initialValues={campaignForm}
          validationSchema={CampaignDetailsSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, handleChange, handleBlur }) => (
            <Form
              action=""
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem'
              }}
              id="campaign-form"
            >
              <FormControl isInvalid={!!errors.title && touched.title}>
                <VStack
                  align="start"
                  fontSize={{
                    base: '13px',
                    md: '15px'
                  }}
                >
                  <Text>Title</Text>
                  <Input
                    name="title"
                    value={values.title}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    bg="#EDEDEDED"
                    type="text"
                    _placeholder={{ fontSize: '13px' }}
                    placeholder="Enter title"
                  />
                </VStack>
                <FormErrorMessage>
                  {errors?.title && touched.title && errors.title}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.description && touched.description}>
                <VStack
                  align="start"
                  mt="1rem"
                  fontSize={{
                    base: '13px',
                    md: '15px'
                  }}
                >
                  <Text>Description</Text>
                  <Textarea
                    name="description"
                    bg="#EDEDEDED"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    h="70px"
                    _placeholder={{ fontSize: '13px' }}
                    placeholder="Enter description"
                  />
                </VStack>
                <FormErrorMessage>
                  {errors?.description && touched.description && errors.description}
                </FormErrorMessage>
              </FormControl>

              <VStack
                align="start"
                mt="1rem"
                width="100%"
                fontSize={{
                  base: '13px',
                  md: '15px'
                }}
              >
                <Text>Featured images</Text>
                <VStack p="1rem" borderRadius="6px" bg="#EDEDEDED" width="100%" gap="1rem">
                  {images?.map((imageData: any, index: number) => (
                    <Card key={index} width="100%">
                      <Box position="relative" width="100%" height="150px">
                        {imageData.fileUrl && (
                          <>
                            {loadingStates[index] && (
                              <Spinner
                                thickness="6px"
                                position="absolute"
                                top="50%"
                                left="50%"
                                transform="translate(-50%, -50%)"
                              />
                            )}
                            <Image
                              src={imageData?.fileUrl || ''}
                              alt={`Image ${imageData.key}`}
                              width="100%"
                              height="100%"
                              objectFit="cover"
                            />
                          </>
                        )}
                        {imageData.fileUrl && (
                          <InputGroup
                            width="5%"
                            bg="primary.main"
                            color="white"
                            height="20px"
                            position="absolute"
                            bottom="-8px"
                            right="-5px"
                            borderRadius="50%"
                            display="flex"
                            alignItems="center"
                          >
                            <Box ml="-10.6px" position="relative">
                              <InputLeftElement
                                mt=".15rem"
                                cursor="pointer"
                                borderRadius="50%"
                                children={
                                  <MdDelete
                                    fontSize=".75rem"
                                    onClick={() => {
                                      handleImageDelete(imageData.key as string);
                                    }}
                                  />
                                }
                                top="-22px"
                              />
                            </Box>
                          </InputGroup>
                        )}

                        {!imageData.fileUrl && (
                          <label>
                            <InputGroup
                              h="19.5vh"
                              cursor="pointer"
                              _hover={{
                                border: '1px solid #541AA9',
                                borderRadius: '5px'
                              }}
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <VStack
                                border="1px dotted gray"
                                boxShadow="sm"
                                width="35px"
                                height="35px"
                                bg="white"
                                color="black"
                                position="relative"
                                bottom="40px"
                                left="35%"
                                borderRadius="5px"
                              >
                                <Box ml="-2.6rem" position="relative">
                                  <InputLeftElement
                                    mt=".15rem"
                                    pointerEvents="none"
                                    children={<LiaCloudUploadAltSolid fontSize="1.1rem" />}
                                    top="-6px"
                                  />
                                </Box>
                                <Input
                                  type="file"
                                  accept="image/*"
                                  onChange={(e) => {
                                    handleFileUploadWrapper(index, e);
                                  }}
                                  sx={{
                                    border: 'none',
                                    padding: '0',
                                    margin: '0',
                                    height: '100%',
                                    width: '100%',
                                    outline: 'none',
                                    cursor: 'pointer',
                                    fontSize: '1rem',
                                    marginTop: '1.2rem',
                                    backgroundColor: 'transparent',
                                    textAlign: 'center',

                                    '&::-webkit-file-upload-button': {
                                      display: 'none'
                                    },

                                    _before: {
                                      content: `''`,
                                      display: 'inline-block',
                                      backgroundColor: 'transparent',
                                      cursor: 'pointer',
                                      fontSize: '14px',
                                      textAlign: 'center',
                                      padding: '0',
                                      margin: '0',
                                      height: '100%',
                                      width: '100%',
                                      outline: 'none',
                                      border: 'none'
                                    }
                                  }}
                                  aria-label="Upload a picture"
                                />
                              </VStack>
                              <VStack mr="2rem" position="relative" bottom="-40px">
                                <Text
                                  fontSize={{
                                    base: '13px',
                                    md: '15px'
                                  }}
                                  color="primary.main"
                                  fontWeight="500"
                                >
                                  Click to upload
                                </Text>
                                <Text
                                  fontSize={{
                                    base: '12px',
                                    md: '14px'
                                  }}
                                  color="gray.600"
                                >
                                  SVG, PNG, JPG or GIF (max. 5MB)
                                </Text>
                              </VStack>
                            </InputGroup>
                          </label>
                        )}
                      </Box>
                    </Card>
                  ))}
                </VStack>
              </VStack>
              <ModalFooter
                sx={{
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  bg: 'white'
                }}
                position="absolute"
                bottom="0"
                left="0"
              >
                <Flex
                  flexDir={{
                    base: 'column',
                    md: 'row'
                  }}
                  align="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Button
                    onClick={() => toggleCreateCampaignModal()}
                    bg="transparent"
                    color="primary.main"
                    cursor="pointer"
                    aria-label="cancel"
                  >
                    Cancel
                  </Button>

                  <Flex
                    fontSize={{
                      base: '14px',
                      md: '16px'
                    }}
                  >
                    <Button
                      size={{ base: 'sm' }}
                      onClick={async () => await handleSubmit(values)}
                      type="submit"
                      isLoading={newCampaign === 'newCampaign' ? createBanner : updateBanner}
                      form="campaign-form"
                      aria-label="submit"
                    >
                      {newCampaign === 'newCampaign' ? 'Save' : 'Edit'}
                    </Button>
                  </Flex>
                </Flex>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Box>
    </PRModal>
  );
};

export default observer(UploadBanner);
