import { action, computed, flow, makeObservable, observable } from 'mobx';
import { RootStore } from '..';
import {
  cancelOrder,
  getUserOrders,
  getUserSubscriptions,
  makePayment,
  pauseSubscription,
  resumeSubscription,
  verifyPayment,
  payWithWallet,
  cancelSubscription,
  getAllOrders
} from 'requests/order';
import toast from 'react-hot-toast';
import { parseError } from 'utils';
import { packages, orderStatus } from 'constant';
import Alert from 'utils/Alert';
import { OrderKeyMappping } from 'constant/order';
import { SessionStorageUtility } from 'utils/session-util';

export class OrderStore {
  orders: IOrder[] = [];
  subscriptions: ISubscription[] = [];
  selectedOrder: Nullable<IOrder> = null;
  selectedSubscription: Nullable<ISubscription> = null;
  selectedOrderType: Nullable<TOrderType> = null;
  orderDate = '';
  packType = {
    pack: packages.Pack,
    frequency: 'months',
    duration: 1,
    startDate: '',
    endDate: ''
  } as IPharmPackage;

  discountDetails = {
    discountDuration: 1,
    discountPercentage: 0
  };

  selectedCancelOrderNumber = '';
  selectedCancelSubscriptionNumber = '';
  selectedPauseSubscriptionNumber = '';
  selectedReorderOrderNumber = '';

  isOrderSummaryOpen = false;
  isSubscriptionSummaryOpen = false;
  isCancelOrderOpen = false;
  isPauseSubscriptionOpen = false;
  isResumeSubscriptionOpen = false;
  isPaymentOptionModalOpen = false;
  isPaymentSummaryModalOpen = false;
  isDiscountOptionsModalOpen = false;
  isCommerceCheckout = false;
  isCheckoutModalOpen = false;
  isRecurringOrderModalOpen = false;
  isOrderRequestModalOpen = false;
  pharmPackModal = false;
  isCheckoutRequestModalOpen = false;
  isPaymentSuccessModalOpen = false;
  isReorderModalOpen = false;
  isLandingModalOpen = false;
  isPaymentWallet = false;
  isPaymentWalletSuccess = false;

  paymentProvider: TPaymentMethod = 'paystack';

  isSubscription = 
  SessionStorageUtility.get<boolean>(OrderKeyMappping.IS_SUBSCRIPTION) || false;
  isPausedByAdmin = false;

  isLoading = {
    fetchUserOrders: false,
    fetchUserSubscriptions: false,
    cancelOrder: false,
    makePayment: false,
    verifyPayment: false,
    pauseSubscription: false,
    resumeSubscription: false,
    cancelSubscription: false,
    payWithWallet: false,
    getAllOrders: false
  };

  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeObservable(this, {
      orders: observable,
      subscriptions: observable,
      selectedOrder: observable,
      selectedSubscription: observable,
      selectedOrderType: observable,
      isOrderSummaryOpen: observable,
      isSubscription: observable,
      isSubscriptionSummaryOpen: observable,
      isCancelOrderOpen: observable,
      isPaymentOptionModalOpen: observable,
      isPaymentSummaryModalOpen: observable,
      isDiscountOptionsModalOpen: observable,
      isCommerceCheckout: observable,
      isPauseSubscriptionOpen: observable,
      isResumeSubscriptionOpen: observable,
      isLoading: observable,
      discountDetails: observable,
      paymentProvider: observable,
      isCheckoutModalOpen: observable,
      isRecurringOrderModalOpen: observable,
      isOrderRequestModalOpen: observable,
      pharmPackModal: observable,
      packType: observable,
      isCheckoutRequestModalOpen: observable,
      isPaymentSuccessModalOpen: observable,
      selectedCancelOrderNumber: observable,
      isReorderModalOpen: observable,
      selectedReorderOrderNumber: observable,
      isLandingModalOpen: observable,
      isPaymentWallet: observable,
      isPaymentWalletSuccess: observable,
      orderDate: observable,
      isPausedByAdmin: observable,

      makeSubscription: action.bound,
      toggleAdminPauseSubscription: action.bound,
      toggleRecurringOrderModal: action.bound,
      toggleCheckoutModal: action.bound,
      toggleOrderSummary: action.bound,
      toggleSubscriptionSummary: action.bound,
      toggleCancelOrder: action.bound,
      togglePaymentOptionModal: action.bound,
      togglePaymentSummaryModal: action.bound,
      toggleDiscountOptionsModal: action.bound,
      toggleDiscountOptionsForCheckout: action.bound,
      setDiscountDetails: action.bound,
      toggleOrderRequestModal: action.bound,
      togglePharmPackModal: action.bound,
      toggleCheckoutRequestModal: action.bound,
      togglePaymentSuccessModal: action.bound,
      togglePauseSubscription: action.bound,
      toggleResumeSubscription: action.bound,
      reorder: action.bound,
      toggleReorderModal: action.bound,
      toggleLandingModal: action.bound,
      togglePaymentWallet: action.bound,
      togglePaymentWalletSuccessModal: action.bound,
      updateOrderDate: action.bound,
      resetDiscountDetails: action.bound,

      cancelOrder: flow.bound,
      cancelSubscription: flow.bound,
      selectPack: action.bound,
      selectDuration: action.bound,
      selectFrequency: action.bound,
      selectStartDate: action.bound,
      selectEndDate: action.bound,

      fetchUserOrders: flow.bound,
      fetchUserSubscriptions: flow.bound,
      makePayment: flow.bound,
      verifyPayment: flow.bound,
      pauseSubscription: flow.bound,
      resumeSubscription: flow.bound,
      payWithWallet: flow.bound,
      getAllOrders: flow.bound,

      pendingOrders: computed,
      activeOrders: computed,
      orderHistory: computed,
      orderSummary: computed,
      selectedReorderOrder: computed
    });
    const pack = SessionStorageUtility.get(OrderKeyMappping.SUBSCRIPTIONDATA) as IPharmPackage;
    if (pack) {
      this.packType = pack;
    }
    
    this.rootStore = rootStore;
  }
  updateOrderDate = (orderDate: string) => {
    this.orderDate = orderDate;
  };
  togglePaymentWalletSuccessModal() {
    this.isOrderSummaryOpen = false;
    this.isPaymentOptionModalOpen = false;
    this.isSubscriptionSummaryOpen = false;
    this.isPaymentWalletSuccess = !this.isPaymentWalletSuccess;
  }
  togglePaymentWallet() {
    this.isPaymentWallet = !this.isPaymentWallet;
  }
  toggleLandingModal(open = false) {
    if (open) {
      this.isLandingModalOpen = true;
    } else {
      this.isLandingModalOpen = false;
    }
  }

  toggleCheckoutRequestModal() {
    this.isCheckoutRequestModalOpen = !this.isCheckoutRequestModalOpen;
  }

  togglePharmPackModal() {
    this.pharmPackModal = !this.pharmPackModal;
  }

  toggleOrderRequestModal() {
    this.isOrderRequestModalOpen = !this.isOrderRequestModalOpen;
  }

  toggleRecurringOrderModal() {
    this.isRecurringOrderModalOpen = !this.isRecurringOrderModalOpen;
  }

  toggleCheckoutModal() {
    this.isCheckoutModalOpen = !this.isCheckoutModalOpen;
  }

  toggleOrderSummary(orderNumber?: string) {
    if (orderNumber === undefined) {
      this.selectedOrder = null;
      this.isOrderSummaryOpen = false;
      return;
    } else {
      const order = this.orders.find((order) => order.orderNumber === orderNumber);
      if (order) {
        this.selectedOrder = order;
        this.isOrderSummaryOpen = true;
      }
    }
  }

  toggleAdminPauseSubscription() {
    this.isPausedByAdmin = !this.isPausedByAdmin;
  }

  toggleSubscriptionSummary(subscriptionNumber?: string) {
    if (subscriptionNumber === undefined) {
      this.selectedSubscription = null;
      this.isSubscriptionSummaryOpen = false;
      return;
    } else {
      const subscription = this.subscriptions.find(
        (sub) => sub.subscriptionNumber === subscriptionNumber
      );
      if (subscription) {
        this.selectedSubscription = subscription;
        this.isSubscriptionSummaryOpen = true;
      }
    }
  }

  toggleCancelOrder(orderType: string, orderNumber?: string) {
    if (orderNumber === undefined) {
      this.selectedCancelOrderNumber = '';
      this.isCancelOrderOpen = false;
      this.selectedOrderType = null;
      return;
    } else {
      this.selectedCancelOrderNumber = orderNumber;
      this.isCancelOrderOpen = true;
      this.selectedOrderType = orderType as TOrderType;
    }
  }

  togglePauseSubscription(orderNumber?: string) {
    if (orderNumber === undefined) {
      this.selectedPauseSubscriptionNumber = '';
      this.isPauseSubscriptionOpen = false;
      return;
    } else {
      this.selectedPauseSubscriptionNumber = orderNumber;
      this.isPauseSubscriptionOpen = true;
    }
  }

  toggleResumeSubscription(orderNumber?: string) {
    if (orderNumber === undefined) {
      this.selectedPauseSubscriptionNumber = '';
      this.isResumeSubscriptionOpen = false;
      return;
    } else {
      this.selectedPauseSubscriptionNumber = orderNumber;
      this.isResumeSubscriptionOpen = true;
    }
  }

  togglePaymentOptionModal(order?: IOrder) {
    this.isPaymentOptionModalOpen = !this.isPaymentOptionModalOpen;
    if (order) {
      this.selectedOrder = order;
      this.selectedOrderType = 'order';
    }
  }

  togglePaymentSummaryModal(orderNumber?: string) {
    this.isPaymentSummaryModalOpen = !this.isPaymentSummaryModalOpen;
    if (orderNumber) {
      const order = this.orders.find((order) => order.orderNumber === orderNumber);
      if (order) {
        this.selectedOrder = order;
        this.selectedOrderType = 'order';
        this.isPaymentSummaryModalOpen = true;
      }
    }
  }

  toggleDiscountOptionsModal(orderNumber?: string) {
    if (orderNumber === undefined) {
      this.isDiscountOptionsModalOpen = false;
    } else {
      const order = this.orders.find((sub) => sub.orderNumber === orderNumber);
      if (order) {
        this.selectedOrder = order;
        this.isDiscountOptionsModalOpen = true;
      }
    }
  }

  toggleDiscountOptionsForCheckout(isCommerceCheckout = false) {
    this.isDiscountOptionsModalOpen = true;
    this.isCommerceCheckout = isCommerceCheckout;
  }

  resetDiscountDetails() {
    this.discountDetails = {
      discountDuration: 1,
      discountPercentage: 0
    };
  }

  setDiscountDetails(discountDetails: IDiscountDetails) {
    this.discountDetails = discountDetails;
    //check for ecommerce
    if (this.isCommerceCheckout) {
      this.rootStore.CheckoutStore.toggleCheckoutPaymentOption();
    }
    this.isPaymentOptionModalOpen = true;
    this.selectedOrderType = 'subscription';
    this.toggleDiscountOptionsModal();
  }

  makeSubscription() {
    SessionStorageUtility.save(OrderKeyMappping.IS_SUBSCRIPTION, !this.isSubscription);
    // if its false, clear the subscription data
    this.isSubscription = !this.isSubscription;
  }

  togglePaymentSuccessModal() {
    this.isPaymentSuccessModalOpen = !this.isPaymentSuccessModalOpen;
  }
  selectPack(value: string) {
    this.packType.pack = value;
  }
  selectDuration(value: number) {
    this.packType.duration = value;
  }
  selectFrequency(value: string) {
    this.packType.frequency = value;
  }
  selectStartDate(value: string) {
    this.packType.startDate = value;
  }
  selectEndDate(value: string) {
    this.packType.endDate = value;
  }

  reorder(order: IOrder, orderType: TOrderType) {
    if (orderType === 'subscription') {
      this.isSubscription = true;
    }
    order.orderItems.forEach((item) => {
      this.rootStore.CartStore.addToCart({
        drugGeneratedId: item._id,
        brandName: item.drugName,
        dosageForm: item.dosageForm,
        quantity: item.quantity,
        price: item.costPrice || 0,
        strength: item.strength,
        manufacturer: item.manufacturer,
        category: item.category,
        unit: item.unit,
        totalPrice: item.amount || 0,
        itemType: item.itemType,
        fileUrl: item.fileUrl
      });
    });
    this.toggleReorderModal();
  }

  toggleReorderModal(orderNumber?: string) {
    if (!orderNumber) {
      this.isReorderModalOpen = false;
    } else {
      this.selectedReorderOrderNumber = orderNumber;
      this.isReorderModalOpen = true;
    }
  }

  *getAllOrders(pageNumber: number, size: number, query?: string) {
    this.isLoading.getAllOrders = true;
    try {
      const { data } = yield getAllOrders(pageNumber, size, query);
      return data;
    } catch (error) {
      toast.error(parseError(error), { position: 'top-right' });
    } finally {
      this.isLoading.getAllOrders = false;
    }
  }
  *fetchUserOrders() {
    this.isLoading.fetchUserOrders = true;
    try {
      const { data } = yield getUserOrders();
      this.orders = data.orders;
    } catch (error) {
      toast.error(parseError(error), { position: 'top-right' });
    } finally {
      this.isLoading.fetchUserOrders = false;
    }
  }

  *fetchUserSubscriptions() {
    this.isLoading.fetchUserSubscriptions = true;
    try {
      const { data } = yield getUserSubscriptions();
      this.subscriptions = data;
    } catch (error) {
      toast.error(parseError(error), { position: 'top-right' });
    } finally {
      this.isLoading.fetchUserSubscriptions = false;
    }
  }

  *cancelOrder(cancelReason: string) {
    this.isLoading.cancelOrder = true;
    try {
      const res = (yield cancelOrder({
        orderNumber: this.selectedCancelOrderNumber,
        cancelReason
      })) as ApiResponse<string>;
      this.fetchUserOrders();
      this.toggleOrderSummary();

      this.toggleCancelOrder('');
      Alert.success({ message: res.message });
    } catch (error) {
      Alert.error({ message: parseError(error) });
    } finally {
      this.isLoading.cancelOrder = false;
    }
  }

  *cancelSubscription(cancelReason: string) {
    this.isLoading.cancelOrder = true;
    try {
      const res = (yield cancelSubscription({
        subscriptionNumber: this.selectedCancelOrderNumber,
        cancelReason
      })) as ApiResponse<string>;
      this.fetchUserSubscriptions();

      this.toggleCancelOrder('');
      Alert.success({ message: res.message });
    } catch (error) {
      Alert.error({ message: parseError(error) });
    } finally {
      this.isLoading.cancelOrder = false;
    }
  }

  *pauseSubscription(pauseReason: string, resumptionDate?: string) {
    this.isLoading.pauseSubscription = true;
    try {
      const res = (yield pauseSubscription({
        subscriptionNumber: this.selectedPauseSubscriptionNumber,
        pauseReason,
        resumptionDate,
      })) as ApiResponse<string>;
      if (this.isPausedByAdmin) {
        this.toggleAdminPauseSubscription();
        yield this.rootStore.AdminOrderStore.fetchOrdersInSubscription(this.selectedPauseSubscriptionNumber);
      } else {
        this.subscriptions = this.subscriptions.map((subscription) => {
          if (subscription.subscriptionNumber === this.selectedPauseSubscriptionNumber) {
            return {
              ...subscription,
              subscriptionStatus: orderStatus.Paused.label
            };
          }
          return subscription;
        });
      this.toggleSubscriptionSummary();
      } 
   
      this.togglePauseSubscription();
      Alert.success({ message: res.message });
    } catch (error) {
      Alert.error({ message: parseError(error) });
    } finally {
      this.isLoading.pauseSubscription = false;
    }
  }

  *resumeSubscription() {
    this.isLoading.resumeSubscription = true;
    try {
      const res = (yield resumeSubscription({
        subscriptionNumber: this.selectedPauseSubscriptionNumber
      })) as ApiResponse<string>;
      this.subscriptions = this.subscriptions.map((subscription) => {
        if (subscription.subscriptionNumber === this.selectedPauseSubscriptionNumber) {
          return {
            ...subscription,
            subscriptionStatus: orderStatus.Active.label
          };
        }
        return subscription;
      });
      this.toggleResumeSubscription();
      this.toggleSubscriptionSummary();
      Alert.success({ message: res.message });
    } catch (error) {
      Alert.error({ message: parseError(error) });
    } finally {
      this.isLoading.resumeSubscription = false;
    }
  }

  *makePayment(options: IPaymentOptions) {
    this.isLoading.makePayment = true;
    this.paymentProvider = options.provider;
    const orderNumber = this.selectedOrder?.orderNumber;
    try {
      const query = `order/${orderNumber}/initiate-payment?provider=${this.paymentProvider}`;

      const res = (yield makePayment(query, {
        ...this.discountDetails,
        saveCard: options.saveCard
      })) as ApiResponse<{
        paymentUrl: string;
      }>;
      Alert.info({ message: res.message });
      this.selectedOrder = null;
      this.selectedSubscription = null;
      this.selectedOrderType = null;
      this.paymentProvider = 'paystack';
      window.location.href = res.data?.paymentUrl as string;
    } catch (error) {
      Alert.error({ message: parseError(error) });
    } finally {
      this.isLoading.makePayment = false;
    }
  }

  *verifyPayment(query: string, body?: { userId: string | null; orderNumber: string | null }) {
    this.isLoading.verifyPayment = true;
    try {
      const res =
        ((yield body ? verifyPayment(query, body) : verifyPayment(query)) as ApiResponse<string>) ||
        undefined;
      if (res && res.status) {
        Alert.success({ message: res.message });
        this.togglePaymentSuccessModal();
      }
    } catch (error) {
      Alert.error({ message: parseError(error) });
    } finally {
      this.isLoading.verifyPayment = false;
    }
  }

  get pendingOrders() {
    return this.orders.filter(
      (order) => order.orderStatus === 'Pending' || order.orderStatus === 'Awaiting Payment'
    );
  }

  get activeOrders() {
    return this.orders.filter(
      (order) =>
        order.orderStatus === 'Paid' ||
        order.orderStatus === 'Dispatched' ||
        order.orderStatus === 'Ready to Dispatch'
    );
  }

  get orderHistory() {
    return this.orders.filter(
      (order) =>
        order.orderStatus === 'Cancelled' ||
        order.orderStatus === 'Delivered' ||
        order.orderStatus === 'Closed' ||
        order.orderStatus === 'Refunded'
    );
  }

  get orderSummary() {
    const { selectedOrder, discountDetails } = this;

    const calculateOrderTotal = (order: IOrder) => {
      const orderNumber = order.orderNumber;
      const subtotal = order.orderTotal - order.deliveryFee;
      const newSubtotal = subtotal * discountDetails.discountDuration;
      const discountApplied = Math.max(0, (newSubtotal * discountDetails.discountPercentage) / 100);
      const deliveryFee = order.deliveryFee * discountDetails.discountDuration;
      const total = newSubtotal - discountApplied + deliveryFee;
      const discount = order.discount || 0;
      return {
        discountApplied,
        deliveryFee,
        subtotal,
        discount,
        total,
        newSubtotal,
        orderNumber
      };
    };
    const discount = 0;
    return selectedOrder
      ? calculateOrderTotal(selectedOrder)
      : {
          discountApplied: 0,
          deliveryFee: 0,
          discount,
          subtotal: 0,
          total: 0,
          newSubtotal: 0,
          orderNumber: ''
        };
  }
  get selectedReorderOrder() {
    return this.orders.find((order) => order.orderNumber === this.selectedReorderOrderNumber);
  }
  
  *payWithWallet(orderNumber: string, userId: string) {
    this.isLoading.payWithWallet = true;
    try {
      const res = (yield payWithWallet(orderNumber, { ...this.discountDetails })) as ApiResponse<string>;
      if (res && res?.status) {
        yield this.rootStore.FinanceStore.getWalletById(userId);
        Alert.success({ message: res.message });
        this.fetchUserOrders();
        this.fetchUserSubscriptions();
        this.isPaymentWallet = false;
        this.togglePaymentWalletSuccessModal();
      }
    } catch (error) {
      Alert.error({ message: parseError(error) });
    } finally {
      this.isLoading.payWithWallet = false;
    }
  }
}
