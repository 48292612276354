import toast from 'react-hot-toast';
import { useStore } from 'store';

interface FileUploadHook {
  handleFileUpload: (file: File, onSuccess: (result: any) => void) => Promise<void>;
}

const useFileUpload = (): FileUploadHook => {
  const {
    CartStore: { sendPrescription }
  } = useStore();

  const handleFileUpload = async (file: File, onSuccess: (result: any) => void): Promise<void> => {
    try {
      const MAX_FILE_SIZE = 5120000;

      // Validating file existence
      if (!file) {
        toast.error('Please choose a file', {
          position: 'top-right'
        });
        return;
      }

      // Validating file size
      if (file.size > MAX_FILE_SIZE) {
        toast.error('File size is more than Max size.', {
          position: 'top-right'
        });
        return;
      }

      const formData: any = new FormData();
      formData.append('file', file);

      const res = await sendPrescription(formData, 'Image');

      // Handling the response and updating the images state
      if (res) {
        onSuccess(res);
      }
    } catch (err: any) {
      toast.error(err?.message, {
        position: 'top-right'
      });
    }
  };

  return { handleFileUpload };
};

export default useFileUpload;
