import { configure } from 'mobx';
import { createContext, useContext } from 'react';
import { AppStore } from './App';
import { AuthStore } from './Auth';
import { BookTestStore } from './BookTest';
import { CartStore } from './Cart';
import { CheckoutStore } from './Checkout';
import { InventoryStore } from './Inventory';
import { OrderStore } from './Order';
import { PartnerStore } from './Partners';
import { UserStore } from './User';
import { WellnessStore } from './Wellness-checker';
import { AdminOrderStore } from './AdminOrder';
import { RunnerStore } from './Runner';
import { FinanceStore } from './Finance';
import { ConsultationStore } from './Consultations';
import { CouponStore } from './Coupon';
import { ActivitiesStore } from './Activities';
import { CampaignStore } from './Campaign';

configure({
  enforceActions: 'observed',
  computedRequiresReaction: true
});

interface StoreProviderProps {
  children: React.ReactNode;
}

export class RootStore {
  AuthStore: AuthStore;
  AppStore: AppStore;
  AdminOrderStore: AdminOrderStore;
  UserStore: UserStore;
  OrderStore: OrderStore;
  CheckoutStore: CheckoutStore;
  InventoryStore: InventoryStore;
  CartStore: CartStore;
  PartnerStore: PartnerStore;
  WellnessStore: WellnessStore;
  BookTestStore: BookTestStore;
  RunnerStore: RunnerStore;
  FinanceStore: FinanceStore;
  ConsultationStore: ConsultationStore;
  CouponStore: CouponStore;
  ActivitiesStore: ActivitiesStore;
  CampaignStore: CampaignStore;

  constructor() {
    this.AppStore = new AppStore(this);
    this.AuthStore = new AuthStore(this);
    this.AdminOrderStore = new AdminOrderStore(this);
    this.UserStore = new UserStore(this);
    this.OrderStore = new OrderStore(this);
    this.CheckoutStore = new CheckoutStore(this);
    this.InventoryStore = new InventoryStore(this);
    this.CartStore = new CartStore(this);
    this.PartnerStore = new PartnerStore(this);
    this.WellnessStore = new WellnessStore(this);
    this.BookTestStore = new BookTestStore(this);
    this.RunnerStore = new RunnerStore(this);
    this.FinanceStore = new FinanceStore(this);
    this.ConsultationStore = new ConsultationStore(this);
    this.CouponStore = new CouponStore(this);
    this.ActivitiesStore = new ActivitiesStore(this);
    this.CampaignStore = new CampaignStore(this);
  }
}

export const Stores = new RootStore();

const StoreContext = createContext<RootStore>(Stores);

export const StoreProvider = ({ children }: StoreProviderProps) => (
  <StoreContext.Provider value={Stores}>{children}</StoreContext.Provider>
);

export const useStore = () => useContext(StoreContext);

export default StoreProvider;
