import { Icon, IconProps } from "@chakra-ui/react";

export const CloseIcon = (props: IconProps) => (
    <Icon width="10px" height="10px" viewBox="0 0 10 10" fill="none" {...props}>
        <path
            d="M0.807518 0.807518C0.865575 0.749314 0.934545 0.703135 1.01048 0.671627C1.08641 0.640119 1.16781 0.623901 1.25002 0.623901C1.33223 0.623901 1.41363 0.640119 1.48956 0.671627C1.56549 0.703135 1.63446 0.749314 1.69252 0.807518L5.00002 4.11627L8.30752 0.807518C8.36563 0.749408 8.43461 0.703313 8.51054 0.671864C8.58646 0.640415 8.66784 0.624229 8.75002 0.624229C8.8322 0.624229 8.91357 0.640415 8.9895 0.671864C9.06542 0.703313 9.13441 0.749408 9.19252 0.807518C9.25063 0.865628 9.29672 0.934615 9.32817 1.01054C9.35962 1.08646 9.37581 1.16784 9.37581 1.25002C9.37581 1.3322 9.35962 1.41357 9.32817 1.4895C9.29672 1.56542 9.25063 1.63441 9.19252 1.69252L5.88377 5.00002L9.19252 8.30752C9.25063 8.36563 9.29672 8.43461 9.32817 8.51054C9.35962 8.58646 9.37581 8.66784 9.37581 8.75002C9.37581 8.8322 9.35962 8.91357 9.32817 8.9895C9.29672 9.06542 9.25063 9.13441 9.19252 9.19252C9.13441 9.25063 9.06542 9.29672 8.9895 9.32817C8.91357 9.35962 8.8322 9.37581 8.75002 9.37581C8.66784 9.37581 8.58646 9.35962 8.51054 9.32817C8.43461 9.29672 8.36563 9.25063 8.30752 9.19252L5.00002 5.88377L1.69252 9.19252C1.63441 9.25063 1.56542 9.29672 1.4895 9.32817C1.41357 9.35962 1.3322 9.37581 1.25002 9.37581C1.16784 9.37581 1.08646 9.35962 1.01054 9.32817C0.934615 9.29672 0.865628 9.25063 0.807518 9.19252C0.749408 9.13441 0.703313 9.06542 0.671864 8.9895C0.640415 8.91357 0.624229 8.8322 0.624229 8.75002C0.624229 8.66784 0.640415 8.58646 0.671864 8.51054C0.703313 8.43461 0.749408 8.36563 0.807518 8.30752L4.11627 5.00002L0.807518 1.69252C0.749314 1.63446 0.703135 1.56549 0.671627 1.48956C0.640119 1.41363 0.623901 1.33223 0.623901 1.25002C0.623901 1.16781 0.640119 1.08641 0.671627 1.01048C0.703135 0.934545 0.749314 0.865575 0.807518 0.807518Z"
            fill="#5519A8"
        />
    </Icon>
);
