import { VStack, Input, Text, Box, Button, Flex } from '@chakra-ui/react';
import PRModal from 'components/modal';
import { useCampaign, useCampaignDeleteAction } from 'hooks/queryHooks';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useStore } from 'store';

const DeleteBanner = () => {
  const [deletionReason, setDeletionReason] = useState<string>('');
  const {
    CampaignStore: {
      toggleDeleteCampaignModal,
      isDeleteCampaignModalOpen,
      selectedCampaign,
      pageSize: pageLimit,
      pageNumber: currentPage
    }
  } = useStore();
    const { refetch } = useCampaign(currentPage, pageLimit);
      const { mutate, isLoading } = useCampaignDeleteAction(
        selectedCampaign?._id as string,
        deletionReason,
        toggleDeleteCampaignModal,
      );
  const handleDeletion = () => {
    if (selectedCampaign) {
      mutate();
      setDeletionReason('');
    }
    refetch();
  };

  return (
    <PRModal
      title="Delete this promotion banner"
      open={isDeleteCampaignModalOpen}
      onClose={() => toggleDeleteCampaignModal()}
    >
      <Box maxW={{ base: '100%', md: '450px' }} py="1.5rem">
        <Text>You’re about to delete this promotion banner. Please enter delete reason</Text>

        <VStack align="start" mt="1rem">
          <Text fontWeight="500">Delete reason</Text>
          <Input
            name="deletionReason"
            value={deletionReason}
            onChange={(e) => setDeletionReason(e.target.value)}
            fontSize={{ base: '1rem', md: '0.875rem' }}
            type="text"
            placeholder="Enter delete reason"
          />
        </VStack>

        <Flex mt="2rem" w="100%" justify="end">
          <Button
            type="submit"
            isLoading={isLoading}
            bg="#e42222dd"
            onClick={() => {
              handleDeletion();
            }}
          >
            Delete banner
          </Button>
        </Flex>
      </Box>
    </PRModal>
  );
};

export default observer(DeleteBanner);
