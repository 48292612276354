import React from 'react';
import { Button, Heading, Text, VStack } from '@chakra-ui/react';
import errorImage from 'assets/images/error.png';

const ErrorPage: React.FC<{ error?: Error | null }> = ({ error }) => {
  return (
    <VStack align="center" justify="center" h="100vh" width="100%" p={{ base: '3rem' }}>
    
      <img src={errorImage} style={{
        width: '20rem'
      }} alt="error_image"  />
      <Heading textAlign="center">Oops, This Page Could Not Be Found</Heading>
      {error && <Text>Error: {error?.toString()}</Text>}
      <Text textAlign="center" w={{ base: '100%' }}>
        The page you are looking for might have been removed had its name changed or is temporarily
        unavailable
      </Text>
      <Button bg="#541AA9" onClick={()=> window.location.href = '/'} h="60px" w="300px" color="white" borderRadius="41px">
        Back to home
      </Button>
    </VStack>
  );
};

export default ErrorPage;
