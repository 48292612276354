import { Box, BoxProps, Flex, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react';
import { Routes, orderStatus } from 'constant';
import { observer } from 'mobx-react-lite';
import { SlOptions } from 'react-icons/sl';
import { useNavigate } from 'react-router-dom';
import { toJS } from 'mobx';
import { useStore } from 'store';
import { parseAmount, formatDateTime } from 'utils';
import { formatMilliSecondsToTime } from 'utils/helpers';
import Permissions from 'utils/permissions';
import { MdVerified } from 'react-icons/md';
import CustomToolTip from 'components/customToolTip';
import ToolTipInfo from 'components/customToolTip/TooltipInfo';

export const mapping: Mapping = {
  personalInformation: {
    header: 'Customer Name',
    formatter: (info) => (info ? info?.firstName + ' ' + info?.lastName : 'N/A')
  },
  userId: { header: 'Customer ID' },
  'personalInformation.phoneNumber': {
    header: 'Phone Number',
    formatter: (info) => (info ? info?.phoneNumber : 'N/A')
  },
  email: { header: 'Email Address' },
  totalOrders: { header: 'Total Orders' },
  amountSpent: { header: 'Amount Spent' },
  status: { header: 'Customer Status', formatter: (status) => (status ? 'Registered' : 'Guest') },
  customerCategory: { header: 'Activity Status' },
  isBusiness: {
    header: 'Customer Type',
    formatter: (isBusiness) => (isBusiness ? 'Business' : 'Individual')
  },
  createdAt: {
    header: 'Created At',
    formatter: (date) =>
      new Date(date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      })
  }
};

const CustomerMenuOptions = (data: any) => {
  const { data: rowData } = data;

  const {
    AppStore: { clearSearchQuery },
    UserStore: {
      toggleCustomerDetailsModal,
      toggleCustomerDeactivateModal,
      toggleDeleteCustomerModal,
      toggleActivateCustomerModal,
      toggleCustomerWalletModal,
      setSelectedUser,
      setSelectedFamilyAndFriends,
    },
    AuthStore
  } = useStore();

  const navigate = useNavigate();
  const permissions = new Permissions(AuthStore);

  const handleCustomerActivate = (status: boolean) => {
    if (status) {
      toggleCustomerDeactivateModal(rowData);
    } else {
      toggleActivateCustomerModal(rowData);
    }
  };

  const handlePlaceCustomerOrder = (rowData: ISelectedUserInfo) => {
    clearSearchQuery();
      setSelectedUser(rowData as ISelectedUserInfo);
    setSelectedFamilyAndFriends();
    navigate(Routes.AdminPlaceOrderForCustomer);
  };

  return (
    <Box>
      <Menu>
        <MenuButton>
          <SlOptions />
        </MenuButton>
        <MenuList>
          <MenuItem onClick={() => toggleCustomerDetailsModal(rowData)}>
            View customer details
          </MenuItem>
          <MenuItem onClick={() => handlePlaceCustomerOrder(rowData)}>
            Place order for customer{' '}
          </MenuItem>
          <MenuItem
            onClick={() => {
              clearSearchQuery();
              setSelectedUser(rowData as ISelectedUserInfo);
              navigate(`/admin/customer/${rowData?.userId}/orders`);
            }}
          >
            View order history
          </MenuItem>
          <MenuItem onClick={() => toggleCustomerWalletModal(rowData)}>
            View wallet details
          </MenuItem>
          <MenuItem
            onClick={() => {
              clearSearchQuery();
              setSelectedUser(rowData as ISelectedUserInfo);
              navigate(`/admin/customer/${rowData?.userId}/affiliate/orders`);
            }}
          >
            View affiliate orders
          </MenuItem>
          {(rowData.status
            ? permissions.hasFeatureAccess('DeactivateUsers')
            : permissions.hasFeatureAccess('ActivateUsers')) && (
            <MenuItem onClick={() => handleCustomerActivate(rowData.status)}>
              {rowData?.status === true ? 'Deactivate customer' : 'Activate customer'}
            </MenuItem>
          )}

          {permissions.hasFeatureAccess('DeleteCustomers') && (
            <MenuItem onClick={() => toggleDeleteCustomerModal(rowData)}>Delete customer</MenuItem>
          )}
        </MenuList>
      </Menu>
    </Box>
  );
};

export const adminCustomerColumn = [
  {
    key: 'personalInformation.firstName',
    label: 'customer name',
    notFoundText: 'N/A',
    formatter: (value: string, rowData: any) => {
      const fullName = rowData?.personalInformation
        ? rowData?.personalInformation?.firstName + ' ' + rowData?.personalInformation?.lastName
        : 'N/A';
      return <div style={{ width: '100px' }}>{fullName}</div>;
    },
    chakraStyle: { textAlign: 'start' } as BoxProps
  },
  {
    key: 'userId',
    label: 'customer id',
    chakraStyle: { color: '#541AA9', textAlign: 'start' } as BoxProps
  },
  {
    key: 'email',
    label: 'email',
    formatter: (value: boolean) => {
      return <div style={{ width: '120px' }}>{value}</div>;
    },
    chakraStyle: { textAlign: 'start' } as BoxProps
  },
  {
    key: 'personalInformation.phoneNumber',
    label: 'Phone no',
    notFoundText: 'N/A',
    formatter: (value: string, rowData: any) => {
      const phoneNumber = rowData?.personalInformation
        ? rowData?.personalInformation?.phoneNumber
        : 'N/A';
      return `${phoneNumber}`;
    },
    chakraStyle: { textAlign: 'start' } as BoxProps
  },

  {
    key: 'totalOrders',
    label: 'items ordered',
    chakraStyle: { textAlign: 'start' } as BoxProps
  },
  {
    key: 'amountSpent',
    label: 'amount spent',
    formatter: (value: number) => parseAmount(value),
    chakraStyle: { textAlign: 'start' } as BoxProps
  },
  {
    key: 'customerCategory',
    label: 'activity status',
    formatter: (value: string) => (
      <Text
        p=".5rem .5rem"
        borderRadius="8px"
        align="center"
        bg={value === 'Active' ? '#DCFCE7' : value === 'Inactive' ? '#FCE7E7' : '#fcf9e7'}
        color={value === 'Active' ? '#57d46c' : value === 'Inactive' ? '#d85f5f' : '#e5a24a'}
      >
        <CustomToolTip
          label={<ToolTipInfo text={value=== "Active" ? 'Customer has ordered within the last 30 days.' : value=== "Inactive" ? 'Customer has not ordered for more than 90 days.' : 'Customer last ordered within 31 to 90 days.'} />}
          placement="bottom"
          bg="white"
          maxW="800px"
        >
          {value}
          </CustomToolTip>
      </Text>
    ),
    chakraStyle: { textAlign: 'start' } as BoxProps
  },
  {
    key: 'isBusiness',
    label: 'customer type',
    formatter: (value: boolean) => `${value ? 'Business' : 'Individual'}`,
    chakraStyle: { textAlign: 'start' } as BoxProps
  },
  {
    key: 'status',
    label: 'Category',
    formatter: (status: boolean) => (
      <Text p=".5rem .5rem" borderRadius="8px" align="center" bg="#e8dcfc8d" color="#541AA9">
        {status ? (
          <Flex justify="center" gap=".1rem" align="center">
            Registered
            <MdVerified size={18} />
          </Flex>
        ) : (
          'Guest'
        )}
      </Text>
    ),
    chakraStyle: { textAlign: 'start' } as BoxProps
  },

  {
    key: 'createdAt',
    label: 'date',
    formatter: (value: string) => {
      const date = formatDateTime(value);
      return <Text style={{ width: '100px' }}>{date}</Text>;
    },
    chakraStyle: { textAlign: 'start' } as BoxProps
  },
  {
    key: '_id',
    label: '',
    formatter: (value: string, rowData: any) => {
      return (
        <Flex align="center" width="50px">
          <CustomerMenuOptions data={rowData} />;
        </Flex>
      );
    },

    chakraStyle: { textAlign: 'start' } as BoxProps
  }
];

const CustomerOrderSummaryOptions = observer((data: any) => {
  const { data: rowData } = data;
  const navigate = useNavigate();
  const {
    AdminOrderStore: { setSelectedOrder, setAdminReorder, canReorderItems, selectedReOrderAddress }
  } = useStore();

  const handleReorderItems = (rowData: IOrder) => {
    setAdminReorder(rowData);
    if (!rowData?.b2b) {
      if (rowData.isFaf) {
        selectedReOrderAddress(rowData?.deliveryAddress);
      }
      navigate(Routes.AdminPlaceOrderForCustomer);
      return;
    }
    rowData?.isFaf
      ? navigate(Routes.AdminPlaceOrderForPartnerMember(rowData.userId, rowData.memberId))
      : navigate(Routes.AdminPlaceOrderForPartner(rowData.userId));
  };

  return (
    <Box>
      <Menu>
        <MenuButton>
          <SlOptions />
        </MenuButton>
        <MenuList>
          <MenuItem
            onClick={() => {
              setSelectedOrder(rowData, rowData.userId);
              navigate(`/admin/${rowData?.orderNumber}/order-summary/details`, {
                state: toJS(rowData)
              });
            }}
          >
            View order summary
          </MenuItem>
          {canReorderItems(rowData as IOrder) && rowData?.enrolleeId !== '' && (
            <MenuItem onClick={() => handleReorderItems(rowData as IOrder)}>
              Re-order Items
            </MenuItem>
          )}
        </MenuList>
      </Menu>
    </Box>
  );
});

export const adminCustomerHistoryColumn = [
  {
    key: 'orderNumber',
    label: 'order no',
    chakraStyle: { color: '#541AA9', textAlign: 'center' } as BoxProps
  },
  {
    key: '_id',
    label: 'no of items',
    formatter: (value: string, rowData: any) => {
      if (rowData?.orderItems) return rowData?.orderItems.length;
    },
    chakraStyle: { textAlign: 'center' } as BoxProps
  },
  {
    key: 'isSubscription',
    label: 'order type',
    notFoundText: '-',
    formatter: (value: boolean) => `${value ? 'Recurring' : 'One time'}`,
    chakraStyle: { textAlign: 'center' } as BoxProps
  },
  {
    key: 'orderItems',
    label: 'items ordered',
    formatter: (value: []) => (
      <Box>
        {value?.map((item: any) => (
          <Flex align="start" key={item?._id}>
            <Text fontWeight="bold" ml=".9" color="primary.main">
              .
            </Text>
            {item?.drugName}
          </Flex>
        ))}
      </Box>
    ),
    chakraStyle: { textAlign: 'center' } as BoxProps
  },
  {
    key: 'orderTotal',
    label: 'order price',
    formatter: (value: number) => parseAmount(value),
    chakraStyle: { textAlign: 'center' } as BoxProps
  },
  {
    key: 'orderStatus',
    label: 'order status',
    conditionalStyles: (rowData: IColumnData) => {
      const status = rowData.orderStatus;
      if (!status) return {};
      return { color: orderStatus[status as keyof typeof orderStatus]?.color };
    },
    formatter: (value: string) => (
      <Text
        p=".5rem .5rem"
        borderRadius="8px"
        bg={orderStatus[value as keyof typeof orderStatus]?.bg}
      >
        {orderStatus[value as keyof typeof orderStatus]?.label}
      </Text>
    ),
    headerStyle: { textAlign: 'center' } as BoxProps,
    chakraStyle: { textAlign: 'center' } as BoxProps
  },
  {
    key: 'createdAt',
    label: 'order date',
    formatter: (date: any) => formatDateTime(date as string),
    chakraStyle: { textAlign: 'center' } as BoxProps
  },
  {
    key: 'deliveredTime',
    label: 'delivery date',
    notFoundText: 'N/A',
    formatter: (date: string | undefined) => {
      if (!date) {
        return 'N/A';
      }
      const formatDate = formatDateTime(date as string);
      return <Box style={{ width: '100px' }}> {formatDate}</Box>;
    },
    chakraStyle: { textAlign: 'center' } as BoxProps
  },
  {
    key: '',
    label: '',
    formatter: (value: string, rowData: any) => {
      return (
        <Flex align="center" width="50px">
          <CustomerOrderSummaryOptions data={rowData} />
        </Flex>
      );
    },

    chakraStyle: { textAlign: 'center' } as BoxProps
  }
];

export const adminCustomerAffiliateColumn = [
  { key: 'username', label: 'recipient name' },
  {
    key: 'orderNumber',
    label: 'order no',
    chakraStyle: { color: '#541AA9', textAlign: 'center' } as BoxProps
  },
  {
    key: 'deliveryAddress.phoneNumber',
    label: 'Phone no',
    notFoundText: 'N/A',
    chakraStyle: { textAlign: 'center' } as BoxProps
  },
  {
    key: 'orderStatus',
    label: 'Order Status',
    conditionalStyles: (rowData: IColumnData) => {
      const status = rowData.orderStatus;
      if (!status) return {};
      return { color: orderStatus[status as keyof typeof orderStatus]?.color };
    },
    formatter: (value: string) => (
      <Text
        p=".5rem .5rem"
        borderRadius="8px"
        bg={orderStatus[value as keyof typeof orderStatus]?.bg}
      >
        {orderStatus[value as keyof typeof orderStatus]?.label}
      </Text>
    ),
    headerStyle: { textAlign: 'center' } as BoxProps,
    chakraStyle: { textAlign: 'center' } as BoxProps
  },
  {
    key: 'tat',
    label: 'tat',
    notFoundText: 'N/A',
    chakraStyle: { textAlign: 'center', color: 'primary.main' } as BoxProps,
    formatter: (tat: ITat) => {
      if (tat && tat.totalTAT === 0) return 'N/A';
      return formatMilliSecondsToTime(tat.tatInSeconds as number);
    }
  },
  {
    key: 'isSubscription',
    label: 'Order Type',
    notFoundText: '-',
    formatter: (value: boolean) => `${value ? 'Recurring' : 'One time'}`,
    chakraStyle: { textAlign: 'center' } as BoxProps
  },
  {
    key: 'orderTotal',
    label: 'order price',
    formatter: (value: number) => parseAmount(value),
    chakraStyle: { textAlign: 'center' } as BoxProps
  },
  {
    key: 'createdAt',
    label: 'order date',
    formatter: (value: string) => {
      const date = formatDateTime(value);
      return <div style={{ width: '100px' }}>{date}</div>;
    },
    chakraStyle: { textAlign: 'center' } as BoxProps
  },
  {
    key: 'deliveredTime',
    label: 'delivery date',
    notFoundText: 'N/A',
    formatter: (date: string | undefined | Date) => {
      if (!date) {
        return 'N/A';
      }
      return <div style={{ width: '100px' }}> {formatDateTime(date as string)}</div>;
    },
    chakraStyle: { textAlign: 'center' } as BoxProps
  }
];

export const adminCustomerFilterOptions = [
  {
    category: 'Category',
    id: 'activeStatus',
    options: [
      {
        label: 'Registered',
        value: true
      },
      {
        label: 'Guest',
        value: false
      }
    ]
  },
  {
    category: 'Type',
    id: 'customerStatus',
    options: [
      {
        label: 'Individual',
        value: 'individual'
      },
      {
        label: 'Business',
        value: 'business'
      },
      {
        label: 'Affiliate',
        value: 'affiliate'
      },
      {
        label: 'Non-Affiliate',
        value: 'nonAffiliate'
      }
    ]
  },
  {
    category: 'Status',
    id: 'customerCategory',
    options: [
      {
        label: 'Active',
        value: 'Active'
      },
      {
        label: 'Inactive',
        value: 'Inactive'
      },
      {
        label: 'At Risk',
        value: 'Risky'
      }
    ]
  },
  {
    category: 'Orders',
    id: 'orderStatus',
    options: [
      {
        label: "Customer's with orders",
        value: 'order'
      },
      {
        label: "Customer's with no orders",
        value: 'nonorder'
      }
    ]
  }
];
