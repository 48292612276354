const CheckBlankIcon = () => {
    return (
      <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.833333 0.5H14.1667C14.3877 0.5 14.5996 0.587797 14.7559 0.744078C14.9122 0.900358 15 1.11232 15 1.33333V14.6667C15 14.8877 14.9122 15.0996 14.7559 15.2559C14.5996 15.4122 14.3877 15.5 14.1667 15.5H0.833333C0.61232 15.5 0.400358 15.4122 0.244078 15.2559C0.0877973 15.0996 0 14.8877 0 14.6667V1.33333C0 1.11232 0.0877973 0.900358 0.244078 0.744078C0.400358 0.587797 0.61232 0.5 0.833333 0.5ZM6.66917 11.3333L12.5608 5.44083L11.3825 4.2625L6.66917 8.97667L4.31167 6.61917L3.13333 7.7975L6.66917 11.3333Z"
          fill="#E6E6E6"
        />
      </svg>
    );
  };
  
  export default CheckBlankIcon;