import { VStack, Button, Text, Flex, Box } from '@chakra-ui/react';
import PRModal from 'components/modal';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStore } from 'store';

const VerifyCancelPaidItemModal = () => {
  const {
    AdminOrderStore: {
      isVerifyCancelPaidItemModalOpen,
      toggleVerifyCancelPaidItemModal,
      toggleRefundOptionsModal,
    }
  } = useStore();

const handleToggleModal = () => {
  toggleRefundOptionsModal();toggleVerifyCancelPaidItemModal();
};
  return (
    <PRModal
      title={'Cancel Item'}
      open={isVerifyCancelPaidItemModalOpen}
      onClose={toggleVerifyCancelPaidItemModal}
      width="40%"
    >
      <VStack
        pt="1rem"
        pl="1rem"
        gap=".5rem"
        w="100%"
        fontSize={{
          base: '12px',
          md: '14px'
        }}
        align="start"
      >
        <Box w="90%">
          <Text fontWeight="400">
            You're cancelling an item from a paid order, select your refund channel to initiate this
            refund
          </Text>
        </Box>
        <Flex w="100%" justifyContent="end" py="1rem" gap=".6rem" mt="20px">
          <Button type="submit" onClick={() => handleToggleModal()}>
            Refund
          </Button>
        </Flex>
      </VStack>
    </PRModal>
  );
};

export default observer(VerifyCancelPaidItemModal);
