import { client } from '..';
import config from '../../config';

export const calculatorBmi = async (
  body: IBmiDetails
): Promise<ApiResponse<IBmiResult> | undefined> => {
  return await client<ApiResponse<IBmiResult>>(
    `${config.AUTH_BASE_URL}/api/wellness/bmi-checker`,
    'POST',
    { body }
  );
};

export const calculateCalorie = async (
  body: ICalorieDetails
): Promise<ApiResponse<ICalorieResult> | undefined> => {
  return await client<ApiResponse<ICalorieResult>>(
    `${config.AUTH_BASE_URL}/api/wellness/calorie-checker`,
    'POST',
    { body }
  );
};

export const calculatorOvulation = async (
  body: IOvulationDetails
): Promise<ApiResponse<IOvulationResult> | undefined> => {
  return await client<ApiResponse<IOvulationResult>>(
    `${config.AUTH_BASE_URL}/api/wellness/ovulation-checker`,
    'POST',
    { body }
  );
};

export const getWellnessCheckerStats = async (payload: {
  startDate: string;
  endDate: string;
}): Promise<ApiResponse<any> | undefined> => {
  const { startDate, endDate } = payload;
  return await client<ApiResponse<any>>(
    `${config.AUTH_BASE_URL}/api/wellness/history/stats?startDate=${startDate}&endDate=${endDate}`,
    'GET'
  );
};

export const getWellnessCheckerHistory = async (
  type: string
): Promise<ApiResponse<any> | undefined> => {
  return await client<ApiResponse<any>>(
    `${config.AUTH_BASE_URL}/api/wellness/history/${type}`,
    'GET'
  );
};
