import React, { Component, ErrorInfo, ReactNode } from 'react';
import ErrorPage from './ErrorPage';

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error?: Error | null;
  errorInfo: ErrorInfo | null;
}
class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
      super(props);
      this.state = { hasError: false, error: null, errorInfo: null };
    }
  
    static getDerivedStateFromError(): ErrorBoundaryState {
      return { hasError: true, error: null, errorInfo: null };
    }
  
    componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
      // Log the error to an error reporting service if needed
      console.error('Error Boundary caught an error:', error, errorInfo);
      this.setState({ error, errorInfo });
    }
  
    render(): React.ReactNode {
      if (this.state.hasError) {
        return (
          <>
          <ErrorPage error={this.state?.error} />
          </>
        );
      }
  
      return this.props.children;
    }
  }
  
  export default ErrorBoundary;
  