import { client } from '..';
import config from '../../config';

export const getActivity = async (
  params: IPaginatedRequest & { entityId: string } & { service?: string }
): Promise<ApiResponse<Record<string, any>> | undefined> => {
  const { page, pageSize, entityId, service } = params;
  return await client<ApiResponse<Record<string, any>>>(
    `${config.AUTH_BASE_URL}/api/activity/?service=${service}&entityId=${entityId}&page=${page}&limit=${pageSize}`,
    'GET'
  );
};
