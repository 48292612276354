import * as moment from 'moment';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { getTagColor } from 'utils';
import { cities } from 'data/location';

dayjs.extend(relativeTime);

// Function to convert seconds into a human-readable format
export function formatMilliSecondsToTime(milliseconds: number): string {
  try {
    if (milliseconds === 0) {
      return '0 seconds';
    }

    const duration = moment.duration(milliseconds);
    return duration.humanize();
  } catch (error) {
    console.error('Error occurred while formatting milliseconds to time:', error);
    return '';
  }
}
export function getSchedule(schedules: ISchedule[]) {
  return schedules?.find((schedule) => {
    return !schedule.dispatched || (schedule.dispatched !== undefined && !schedule.delivered);
  });
}
export const timeAgo = (date: string | number | dayjs.Dayjs | Date | null | undefined) =>
  dayjs(date).fromNow();

export const getStage = (url: string) => {
  const lastIndex = url.lastIndexOf('/');
  const lastWord = url.substring(lastIndex + 1);
  return lastWord;
};

// Function to check if a hex color is light or dark
export const isDarkColor = (hex: string) => {
  // Convert hex to RGB
  const rgb = parseInt(hex?.replace('#', ''), 16);
  const r = (rgb >> 16) & 0xff;
  const g = (rgb >> 8) & 0xff;
  const b = (rgb >> 0) & 0xff;

  // Calculate perceived brightness
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;

  // Return true if it's a dark color
  return brightness < 128;
}

export const getTagProps = (tag: TagItem | string, index: number) => {
  const tagLabel = typeof tag === 'string' ? tag : tag?.label;
  const tagColor =
    typeof tag === 'object' && tag.color !== '' && tag.color
      ? tag?.color
      : getTagColor(index, 'bg');
  const textColor = tagColor ? (isDarkColor(tagColor) ? 'white' : 'black') : 'black';
  return { tagLabel, tagColor, textColor };
};

export const isValidLagosCity = (state: string, city: string): boolean => {
  const cityExist = cities.find((c) => c.name === city); 
  return state === 'Lagos' && cityExist !== undefined;
};    
