import { client } from '..';
import config from '../../config';

export const getAllUsers = async (
  params: any
): Promise<ApiResponse<PaginatedUserReponse | null> | undefined> => {
  const {
    page,
    pageSize,
    customerStatus,
    activeStatus,
    customerCategory,
    orderStatus,
    searchKey,
    startDate,
    endDate
  } = params;
  return await client<ApiResponse<PaginatedUserReponse | null>>(
    `${config.AUTH_BASE_URL}/api/user/all?page=${page}&size=${pageSize}&customerStatus=${
      customerStatus ?? ''
    }&activeStatus=${activeStatus ?? ''}&orderStatus=${
      orderStatus ?? ''
    }&searchKey=${searchKey}&customerCategory=${
      customerCategory ?? ''
    }&startDate=${startDate}&endDate=${endDate}`,
    'GET'
  );
};
export const getUser = async (): Promise<ApiResponse<IUserInfo | null> | undefined> => {
  return await client<ApiResponse<IUserInfo | null>>(`${config.AUTH_BASE_URL}/api/user`, 'GET');
};

export const getUserInfo = async (): Promise<ApiResponse<IUserInfo | null> | undefined> => {
  return await client<ApiResponse<IUserInfo | null>>(
    `${config.AUTH_BASE_URL}/api/user/personal-information`,
    'GET'
  );
};

export const getUserById = async (
  userId: string
): Promise<ApiResponse<IFullUser | null> | undefined> => {
  return await client<ApiResponse<IFullUser | null>>(
    `${config.AUTH_BASE_URL}/api/user/${userId}/details`,
    'GET'
  );
};

export const getUserOrders = async (
  userId: string,
  page: number,
  pageSize: number,
  query?: any
): Promise<ApiResponse<IUserOrder | null> | undefined> => {
  let url = `${config.AUTH_BASE_URL}/api/order/user/${userId}/all?page=${page}&limit=${pageSize}`;
  if (query) url += `&${query}`;
  return await client<ApiResponse<IUserOrder | null>>(url, 'GET');
};

export const getMemberOrders = async (
  memberId: string
): Promise<ApiResponse<IPaginatedOrder | null> | undefined> => {
  return await client<ApiResponse<IPaginatedOrder | null>>(
    `${config.AUTH_BASE_URL}/api/partner/member/${memberId}/orders`,
    'GET'
  );
};

export const getReferralOrders = async (
  userId: string
): Promise<ApiResponse<IUserInfo[] | null> | undefined> => {
  return await client<ApiResponse<IUserInfo[] | null>>(
    `${config.AUTH_BASE_URL}/api/report/affiliate/${userId}`,
    'GET'
  );
};

export async function updateUserInfo(body: IUserInfo): Promise<ApiResponse<string> | undefined> {
  return await client<ApiResponse<string>>(
    `${config.AUTH_BASE_URL}/api/user/personal-information`,
    'PUT',
    { body }
  );
}

export async function onboardBusiness(body: IBusiness): Promise<ApiResponse<string> | undefined> {
  return await client<ApiResponse<string>>(`${config.AUTH_BASE_URL}/api/partner/business`, 'POST', {
    body
  });
}

export async function updateUserAddress(
  body: IUserAddress,
  account = 'user'
): Promise<ApiResponse<string> | undefined> {
  return await client<ApiResponse<string>>(
    `${config.AUTH_BASE_URL}/api/user/delivery-address/${account}`,
    'PUT',
    { body }
  );
}

export async function updateUserAddressAdmin(
  body: IUserAddress,
  account: string,
  userId: string
): Promise<ApiResponse<string> | undefined> {
  return await client<ApiResponse<string>>(
    `${config.AUTH_BASE_URL}/api/user/delivery-address/${account}/${userId}/admin`,
    'PUT',
    { body }
  );
}

export const getUserAddress = async (): Promise<ApiResponse<IUserAddress | null> | undefined> => {
  return await client<ApiResponse<IUserAddress | null>>(
    `${config.AUTH_BASE_URL}/api/user/delivery-address`,
    'GET'
  );
};

export const getFamilyAndFriends = async (): Promise<
  ApiResponse<IFamilyAndFriends[] | null> | undefined
> => {
  return await client<ApiResponse<IFamilyAndFriends[] | null>>(
    `${config.AUTH_BASE_URL}/api/user/friends-and-family`,
    'GET'
  );
};

export const deleteFamilyAndFriends = async (
  isMember: boolean,
  fnfId: string
): Promise<ApiResponse<string> | undefined> => {
  return await client<ApiResponse<string>>(
    `${config.AUTH_BASE_URL}/api/user/friends-and-family/${fnfId}/${isMember}`,
    'DELETE'
  );
};

export const updateFamilyAndFriends = async (
  body: IFamilyAndFriends[] | any,
  fnfId: string
): Promise<ApiResponse<string> | undefined> => {
  return await client<ApiResponse<string>>(
    `${config.AUTH_BASE_URL}/api/user/friends-and-family/${fnfId}`,
    'PUT',
    { body }
  );
};

export const addFamilyAndFriends = async (
  body: IFamilyAndFriends[] | any
): Promise<ApiResponse<string> | undefined> => {
  return await client<ApiResponse<string>>(
    `${config.AUTH_BASE_URL}/api/user/friends-and-family`,
    'POST',
    { body }
  );
};

export const getUserAllergies = async (): Promise<ApiResponse<IAllergies | null> | undefined> => {
  return await client<ApiResponse<IAllergies | null>>(
    `${config.AUTH_BASE_URL}/api/user/meds-and-allergies`,
    'GET'
  );
};

export const updateUserAllergies = async (
  body: IAllergies[] | any
): Promise<ApiResponse<string> | undefined> => {
  return await client<ApiResponse<string>>(`${config.AUTH_BASE_URL}/api/user/allergies`, 'PUT', {
    body
  });
};

export const updateUserMeds = async (
  body: IMeds[] | any
): Promise<ApiResponse<string> | undefined> => {
  return await client<ApiResponse<string>>(`${config.AUTH_BASE_URL}/api/user/meds`, 'PUT', {
    body
  });
};

export const getUserHMO = async (): Promise<ApiResponse<IUserHmo | null> | undefined> => {
  return await client<ApiResponse<IUserHmo | null>>(`${config.AUTH_BASE_URL}/api/user/hmo`, 'GET');
};

export const updateUserHMO = async (
  body: IUserHmo | any
): Promise<ApiResponse<string> | undefined> => {
  return await client<ApiResponse<string>>(`${config.AUTH_BASE_URL}/api/user/hmo`, 'PUT', { body });
};

export const contactUs = async (
  body: IContactPharmacist | any
): Promise<ApiResponse<string> | undefined> => {
  return await client<ApiResponse<string>>(
    `${config.AUTH_BASE_URL}/api/contact/contact-us`,
    'POST',
    { body }
  );
};

export const contactPharmacist = async (
  body: IContactPharmacist | any
): Promise<ApiResponse<string> | undefined> => {
  return await client<ApiResponse<string>>(
    `${config.AUTH_BASE_URL}/api/contact/talk-to-pharmacist`,
    'POST',
    { body }
  );
};

export const getOrderReport = async (
  code: string
): Promise<ApiResponse<IOrderReport[] | null> | undefined> => {
  return await client<ApiResponse<IOrderReport[] | null>>(
    `${config.AUTH_BASE_URL}/api/report/order/${code}`,
    'GET'
  );
};

export const updateActivateCustomer = async (
  body: IActivateCustomer | any
): Promise<ApiResponse<string> | undefined> => {
  return await client<ApiResponse<string>>(
    `${config.AUTH_BASE_URL}/api/user/update-user-status`,
    'PUT',
    { body }
  );
};

export const deleteUser = async (id: string): Promise<ApiResponse<string> | undefined> => {
  return await client<ApiResponse<string>>(`${config.AUTH_BASE_URL}/api/auth/${id}`, 'DELETE');
};
export const hardDeleteUser = async (
  userId: string,
  isPartner: boolean
): Promise<ApiResponse<string> | undefined> => {
  return await client<ApiResponse<string>>(
    `${config.AUTH_BASE_URL}/api/user/hard-delete-user/${userId}/${isPartner}`,
    'DELETE'
  );
};

export const getUsersSummary = async (params: any): Promise<ApiResponse<any> | undefined> => {
  const { startDate, endDate, customerStatus, activeStatus, orderStatus, searchKey } = params;
  return await client<ApiResponse<any>>(
    `${
      config.AUTH_BASE_URL
    }/api/user/summary?startDate=${startDate}&endDate=${endDate}&customerStatus=${customerStatus}&activeStatus=${
      activeStatus ?? ''
    }&orderStatus=${orderStatus ?? ''}&searchKey=${searchKey}`,
    'GET'
  );
};

export const joinPartnerNetwork = async (
  partnerId: string
): Promise<ApiResponse<string> | undefined> => {
  return await client<ApiResponse<string>>(
    `${config.AUTH_BASE_URL}/api/partner/join-network-request/${partnerId}`,
    'POST'
  );
};
export const memberRequestStatus = async (
  memberId: string,
  status: string
): Promise<ApiResponse<string> | undefined> => {
  return await client<ApiResponse<string>>(
    `${config.AUTH_BASE_URL}/api/partner/network-request/${memberId}/${status}`,
    'POST'
  );
};
export const getMemberRequest = async (): Promise<ApiResponse<string> | undefined> => {
  return await client<ApiResponse<string>>(
    `${config.AUTH_BASE_URL}/api/partner/join-network/member-requests`,
    'GET'
  );
};

export const getUserPartner = async (): Promise<ApiResponse<string> | undefined> => {
  return await client<ApiResponse<string>>(
    `${config.AUTH_BASE_URL}/api/partner/network/all-partners`,
    'GET'
  );
};

export const getFamilyAndFriendsAdmin = async (
  userId: string
): Promise<ApiResponse<string> | undefined> => {
  return await client<ApiResponse<string>>(
    `${config.AUTH_BASE_URL}/api/user/friends-and-family/${userId}/admin`,
    'GET'
  );
};
export const addFamilyAndFriendsAdmin = async (
  body: IFamilyAndFriends[] | any,
  userId: string
): Promise<ApiResponse<string> | undefined> => {
  return await client<ApiResponse<string>>(
    `${config.AUTH_BASE_URL}/api/user/friends-and-family/${userId}/admin`,
    'POST',
    { body }
  );
};

export const updateFamilyAndFriendsAdmin = async (
  body: IFamilyAndFriends[] | any,
  id: string,
  userId: string
): Promise<ApiResponse<string> | undefined> => {
  return await client<ApiResponse<string>>(
    `${config.AUTH_BASE_URL}/api/user/friends-and-family/${id}/${userId}/admin`,
    'PUT',
    { body }
  );
};

export const getAdminInfo = async (): Promise<
  ApiResponse<{ role: { permissions: IRoleFeature[] } }> | undefined
> => {
  return await client<ApiResponse<{ role: { permissions: IRoleFeature[] } }>>(
    `${config.AUTH_BASE_URL}/api/user/admin-info`,
    'GET'
  );
};
