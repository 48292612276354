import { ChakraProvider } from '@chakra-ui/react';
import { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Loading from './components/loading';
import ModalBank from './components/modalBank';
import Routes from './routes';
import { PrChakraTheme as theme } from './themes';
import StoreProvider from './store';
import { Helmet } from 'react-helmet';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

const queryClient = new QueryClient();

function App() {
  return (
    <ChakraProvider resetCSS {...{ theme }}>
      <Suspense fallback={<Loading />}>
        <QueryClientProvider client={queryClient}>
          <StoreProvider>
            <Router>
              <Helmet>
                <title>Pharmarun</title>
                <meta
                  name="description"
                  content="Welcome to Your One-Stop Health Portal! Online Lab tests, BMI checker, Calorie calculator, Fertility tracker, Expert consultations - all in one place! Click now for a healthier you!"
                />
                <meta
                  name="keywords"
                  content="Pharmarun, Health, Wellness, Pharmacy, BMI checker, Calorie calculator, Fertility tracker, Expert consultations, Lab tests, Fertility tracker"
                />
              </Helmet>
              <Routes />
              <ModalBank />
            </Router>
          </StoreProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </Suspense>
    </ChakraProvider>
  );
}

export default App;
