import { getAuthTokens, userIsAuthenticated, refreshAccessToken } from 'utils/auth-util';
import { client } from '..';
import config from '../../config';
import axios from 'axios';

const getAllDrugs = async (
  pageNumber: number,
  size: number,
  queryString: string
): Promise<ApiResponse<IDrug[]> | undefined> => {
  return await client<ApiResponse<IDrug[]>>(
    `${config.AUTH_BASE_URL}/api/inventory/drugs/${pageNumber}/${size}/${queryString}`,
    'GET'
  );
};

const getDrugById = async (id: string): Promise<ApiResponse<IDrug> | undefined> => {
  return await client<ApiResponse<IDrug>>(
    `${config.AUTH_BASE_URL}/api/inventory/drugs/generated-id/${id}`,
    'GET'
  );
};

const postInventory = async (body: IDrug): Promise<ApiResponse<string> | undefined> => {
  return await client<ApiResponse<string>>(`${config.AUTH_BASE_URL}/api/inventory/drugs`, 'POST', {
    body
  });
};

async function addInventoryBulkUpload(file: File) {
  let refreshToken, accessToken;
  const tokens = getAuthTokens();
  if (tokens) {
    accessToken = tokens.accessToken;
    refreshToken = tokens.refreshToken;
  }
  const headers: ApiHeader = {
    'Content-Type': 'multipart/form-data',
  };
  if (userIsAuthenticated()) {

    headers.Authorization = `Bearer ${accessToken}`;
  } else {
    if (refreshToken) {
      const getAccessToken = await refreshAccessToken();
      if (getAccessToken) {
        headers.Authorization = `Bearer ${getAccessToken}`;
      }
    }
  }
  try {
    const response = await axios.post(
      `${config.AUTH_BASE_URL}/api/inventory/drugs/upload`,
      file,
      {
        headers: {
          ...headers,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error('Error during axios request:', error);
    throw error; 
  }

}
const editInventory = async (
  body: IDrug,
  drugGeneratedId: string
): Promise<ApiResponse<string> | undefined> => {
  return await client<ApiResponse<string>>(
    `${config.AUTH_BASE_URL}/api/inventory/drugs/${drugGeneratedId}`,
    'PUT',
    { body }
  );
};

const deleteInventory = async (): Promise<ApiResponse<string> | undefined> => {
  return await client<ApiResponse<string>>(`${config.AUTH_BASE_URL}/api/inventory/drugs`, 'DELETE');
};

const fetchDrugsByKeyword = async (key: string): Promise<ApiResponse<IDrug[]> | undefined> => {
  return await client<ApiResponse<IDrug[]>>(
    `${config.AUTH_BASE_URL}/api/inventory/drugs/${key}`,
    'GET'
  );
};

const uploadImages = async (drugId: string, body: any): Promise<ApiResponse<IDrug[]> | undefined> => {
  return await client<ApiResponse<IDrug[]>>(
    `${config.AUTH_BASE_URL}/api/inventory/drugs/upload-images/${drugId}`,
    'POST',
    {
      body
    }
  );
};

const deleteImageKey = async (drugId: string, imageKey: string): Promise<ApiResponse<IDrug[]> | undefined> => {
  return await client<ApiResponse<IDrug[]>>(
    `${config.AUTH_BASE_URL}/api/inventory/drugs/remove-image/${drugId}/${imageKey}`,
    'DELETE'
  );
};

const getBundles = async (
  pageNumber: number,
  pageSize: number,
  queryString: string
): Promise<ApiResponse<IBundle[]> | undefined> => {
  return await client<ApiResponse<IBundle[]>>(
    `${config.AUTH_BASE_URL}/api/bundle/${pageNumber}/${pageSize}/${queryString}`,
    'GET'
  );
};
const createBundle = async (
  body: IBundle
): Promise<ApiResponse<IBundle[]> | undefined> => {
  return await client<ApiResponse<IBundle[]>>(
    `${config.AUTH_BASE_URL}/api/bundle/create`,
    'POST',
    {
      body
    }
  );

}
const editBundle = async (
  body: IBundle, bundleId?: string): Promise<ApiResponse<IBundle[]> | undefined> => {
  return await client<ApiResponse<IBundle[]>>(
    `${config.AUTH_BASE_URL}/api/bundle/${bundleId}`,
    'PUT',
    {
      body
    }
  );
}
const deleteBundle = async (
  bundleId: string): Promise<ApiResponse<IBundle[]> | undefined> => {
  return await client<ApiResponse<IBundle[]>>(
    `${config.AUTH_BASE_URL}/api/bundle/${bundleId}`,
    'DELETE',

  );
}

const deleteMedication = async (
  drugId: string): Promise<ApiResponse<IBundle[]> | undefined> => {
  return await client<ApiResponse<IBundle[]>>(
    `${config.AUTH_BASE_URL}/api/inventory/drugs/${drugId}`,
    'DELETE',
  );
}

export {
  getAllDrugs,
  getDrugById,
  postInventory,
  getBundles,
  createBundle,
  editBundle,
  deleteBundle,
  addInventoryBulkUpload,
  editInventory,
  deleteInventory,
  fetchDrugsByKeyword,
  uploadImages,
  deleteImageKey,
  deleteMedication
};
