import config from '../../config';
import { client } from '..';

export const getAllCampaigns = async (
    pageNumber: number,
    pageSize: number,
): Promise<ApiResponse<ICampaign[] | null> | undefined> => {
    return await client<ApiResponse<ICampaign[] | null>>(
        `${config.AUTH_BASE_URL}/api/campaign/paginated/${pageNumber}/${pageSize}`,
        'GET'
    );
};

export const getCampaignById = async (campaignId: string): Promise<ApiResponse<ICampaign[] | null> | undefined> => {
    return await client<ApiResponse<ICampaign[] | null>>(
        `${config.AUTH_BASE_URL}/api/campaign/${campaignId}`,
        'GET'
    );
};

export const createCampaign = async (body: ICampaign) => {
    return await client<ApiResponse<ICampaign>>(
        `${config.AUTH_BASE_URL}/api/campaign/create`,
        'POST',
        { body }
    );
};

export const updateCampaign = async (body: ICampaign, campaignId: string,) => {
    return await client<ApiResponse<ICampaign>>(
        `${config.AUTH_BASE_URL}/api/campaign/update/${campaignId}`,
        'PUT',
        { body }
    );
}


export const deleteCampaign = async (campaignId: string, deletionReason?: string): Promise<ApiResponse<any | null> | undefined> => {

    return await client<ApiResponse<ICampaign[] | null>>(
        `${config.AUTH_BASE_URL}/api/campaign/delete/${campaignId}`,
        'DELETE',
        {
            body: { deletionReason }
        }
    );
};

export const deActivateCampaign = async (campaignId: string, deactivationReason?: string): Promise<ApiResponse<any | null> | undefined> => {

    return await client<ApiResponse<ICampaign[] | null>>(
        `${config.AUTH_BASE_URL}/api/campaign/deactivate/${campaignId}`,
        'PUT',
        {
            body: { deactivationReason }
        }
    );
};

export const activateCampaign = async (campaignId: string): Promise<ApiResponse<ICampaign[] | null> | undefined> => {
    return await client<ApiResponse<ICampaign[] | null>>(
        `${config.AUTH_BASE_URL}/api/campaign/activate/${campaignId}`, 'PUT'

    );
};