import axios from 'axios';
import config from 'config';
import { action, computed, makeObservable, observable } from 'mobx';
import toast from 'react-hot-toast';
import { RootStore } from 'store';
import { parseError } from 'utils';

export class BookTestStore {
  url = `${config.AUTH_BASE_URL}/api`;
  getTestPackages: IGetPackagesTest[] = [];
  getSingleTest: ISingleTest[] = [];

  isLoading = {
    getTestPackages: false,
    getSingleTest: false
  };
  rootStore: RootStore;
  
  constructor(rootStore: RootStore) {
    makeObservable(this, {
      getTestPackages: observable,
      getSingleTest: observable,
      isLoading: observable,

      getTestPackagesList: computed,
      getSingleTestList: computed,

      getAllTestPackages: action.bound,
      getAllSingleTest: action.bound
    });
    this.rootStore = rootStore;
  }

  async getAllTestPackages() {
    this.isLoading.getTestPackages = true;
    try {
      const path = `${this.url}/chekker/auth`;
      const response = await axios.post(path);
      const responseData = response.data.data.data.authid;

      localStorage.setItem('responseData', responseData);

      const getAuthId = localStorage.getItem('responseData');

      const headers: ApiHeader = {
        'Content-Type': 'application/json; charset=UTF-8'
      };

      if (getAuthId) {
        headers.Authorization = `Bearer ${getAuthId}`;
      }

      const res = await axios.get('https://api.mychekker.com/v2/gettestpackages', {
        headers: {
          ...headers
        }
      });

      this.getTestPackages = res.data.data;
    } catch (error) {
      toast.error(parseError(error), { position: 'top-right' });
    } finally {
      this.isLoading.getTestPackages = false;
    }
  }

  get getTestPackagesList() {
    return this.getTestPackages;
  }

  async getAllSingleTest() {
    this.isLoading.getSingleTest = true;
    try {
      const path = `${this.url}/chekker/auth`;
      const response = await axios.post(path);
      const responseData = response.data.data.data.authid;

      localStorage.setItem('responseData', responseData);
      const getAuthId = localStorage.getItem('responseData');

      const headers: ApiHeader = {
        'Content-Type': 'application/json; charset=UTF-8'
      };

      if (getAuthId) {
        headers.Authorization = `Bearer ${getAuthId}`;
      }

      const res = await axios.get('https://api.mychekker.com/getSingleTest', {
        headers: {
          ...headers
        }
      });
      this.getSingleTest = res?.data?.data;
    } catch (error) {
      toast.error(parseError(error), { position: 'top-right' });
    } finally {
      this.isLoading.getSingleTest = false;
    }
  }

  get getSingleTestList() {
    return this.getSingleTest;
  }
}
