import { client } from '..';
import config from '../../config';

const uploadFile = async (payload: any) => {
  const response = await fetch(`${config.AUTH_BASE_URL}/api/file`, {
    method: 'POST',
    body: payload
  }).then((res) => res.json());
  return response;
};

const getFile = async (key: string): Promise<ApiResponse<string> | undefined> => {
  return await client<ApiResponse<string>>(`${config.AUTH_BASE_URL}/api/file/download`, 'POST', {
    body: {
      key: key
    }
  });
};

const checkout = async (payload: any): Promise<ApiResponse<any> | undefined> =>
{
  return await client<any>(`${config.AUTH_BASE_URL}/api/order`, 'POST', {
    body: payload
  });
}; 

const partnerOrderCheckout = async (payload: any): Promise<ApiResponse<any> | undefined> =>
{
  return await client<any>(`${config.AUTH_BASE_URL}/api/order/b2b`, 'POST', {
    body: payload
  });
};
const subscriptionCheckoutApi = async (payload: any) => {
  return await client<any>(`${config.AUTH_BASE_URL}/api/subscription`, 'POST', {
    body: payload
  });
};

const ecommerceCheckout = async (
  payload: any,
  checkoutOptions?: ICheckoutOptions
): Promise<ApiResponse<any> | undefined> => {
  return await client<any>(
    `${config.AUTH_BASE_URL}/api/order/customer/checkout?paymentType=${checkoutOptions?.paymentType}&provider=${checkoutOptions?.provider}`,
    'POST',
    {
      body: {
        data: payload,
        email: checkoutOptions?.email,
        userId: checkoutOptions?.userId,
        saveCard: checkoutOptions?.saveCard
      }
    }
  );
};

export { uploadFile, getFile, checkout, subscriptionCheckoutApi, ecommerceCheckout, partnerOrderCheckout };
