import { Icon, IconProps } from "@chakra-ui/react";

export const Harmburger = (props: IconProps) => (
    <Icon
        width="15px"
        height="10px"
        viewBox="0 0 15 10"
        fill="none"
        {...props}
    >
        <path
            d="M0.722168 0.830132C0.722168 0.448188 1.03189 0.13916 1.41314 0.13916H6.97564C7.1589 0.13916 7.33465 0.211959 7.46423 0.341541C7.59381 0.471124 7.66661 0.646875 7.66661 0.830132C7.66661 1.01339 7.59381 1.18914 7.46423 1.31872C7.33465 1.44831 7.1589 1.5211 6.97564 1.5211H1.41314C1.22988 1.5211 1.05413 1.44831 0.924549 1.31872C0.794967 1.18914 0.722168 1.01339 0.722168 0.830132ZM0.722168 5.00027C0.722168 4.61833 1.03189 4.3093 1.41314 4.3093H13.9201C14.1033 4.3093 14.2791 4.3821 14.4087 4.51168C14.5383 4.64126 14.6111 4.81701 14.6111 5.00027C14.6111 5.18353 14.5383 5.35928 14.4087 5.48886C14.2791 5.61845 14.1033 5.69124 13.9201 5.69124H1.41314C1.22988 5.69124 1.05413 5.61845 0.924549 5.48886C0.794967 5.35928 0.722168 5.18353 0.722168 5.00027ZM1.41314 8.47944C1.22988 8.47944 1.05413 8.55224 0.924549 8.68182C0.794967 8.8114 0.722168 8.98715 0.722168 9.17041C0.722168 9.35367 0.794967 9.52942 0.924549 9.659C1.05413 9.78858 1.22988 9.86138 1.41314 9.86138H9.75342C9.93668 9.86138 10.1124 9.78858 10.242 9.659C10.3716 9.52942 10.4444 9.35367 10.4444 9.17041C10.4444 8.98715 10.3716 8.8114 10.242 8.68182C10.1124 8.55224 9.93668 8.47944 9.75342 8.47944H1.41314Z"
            fill="#541AA9"
        />
    </Icon>
);
