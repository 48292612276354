import { client } from '..';
import config from '../../config';

export const getPharmOrderRequests = async (
  params: GetAllUsersTypes & { pharmacyId: string }
): Promise<ApiResponse<Record<string, any>> | undefined> => {
  const { pharmacyId, page, pageSize, searchKey, startDate, endDate } = params;
  return await client<ApiResponse<Record<string, any>>>(
    `${
      config.AUTH_BASE_URL
    }/api/pharmacy/${pharmacyId}/order-requests?page=${page}&size=${pageSize}&startDate=${
      startDate
    }&endDate=${endDate}&searchKey=${searchKey ?? ''}`,
    'GET'
  );
};

export const getPharmStats = async (
  params: GetAllUsersTypes & { pharmacyId: string }
): Promise<ApiResponse<Record<string, any>> | undefined> => {
  const { pharmacyId, startDate, endDate } = params;
  return await client<ApiResponse<Record<string, any>>>(
    `${
      config.AUTH_BASE_URL
    }/api/pharmacy/${pharmacyId}/stats?startDate=${
      startDate
    }&endDate=${endDate}`,
    'GET'
  );
};
