export class SessionStorageUtility {
    static save<T>(key: string, value: T): void {
      try {
        const serializedValue = JSON.stringify(value);
        sessionStorage.setItem(key, serializedValue);
      } catch (error) {
        console.error('Error saving to sessionStorage', error);
      }
    }
  
    static get<T>(key: string): T | null {
      try {
        const serializedValue = sessionStorage.getItem(key);
        return serializedValue ? JSON.parse(serializedValue) as T : null;
      } catch (error) {
        console.error('Error retrieving from sessionStorage', error);
        return null;
      }
    }
  
    static remove(key: string): void {
      try {
        sessionStorage.removeItem(key);
      } catch (error) {
        console.error('Error removing from sessionStorage', error);
      }
    }
  
    static clear(): void {
      try {
        sessionStorage.clear();
      } catch (error) {
        console.error('Error clearing sessionStorage', error);
      }
    }
  }
  