import { Box, BoxProps, Menu, MenuButton, MenuItem, MenuList, Flex } from '@chakra-ui/react';
import { Routes } from 'constant';
import { SlOptions } from 'react-icons/sl';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'store';
import { formatDateTime, parseAmount } from 'utils';

const PartnerMembersMenuOptions = (data: any) => {
  const navigate = useNavigate();
  const { data: rowData } = data;
  const {
    UserStore: { toggleRemoveFamilyandFriends },
    PartnerStore: {toggleViewPartnerMember, selectedPartner},
    CheckoutStore: { selectAddress, setDeliveryAddress }
  } = useStore();
  const handlePlaceOrder = (rowData: any) => {
    selectAddress(rowData?._id, true);
    setDeliveryAddress(rowData);
    navigate(Routes.AdminPlaceOrderForPartnerMember(selectedPartner?.partnerId as string, rowData?._id));
  };
  return (
    <Box>
      <Menu>
        <MenuButton>
          <SlOptions />
        </MenuButton>
        <MenuList>
          <MenuItem onClick={() => toggleViewPartnerMember(rowData?._id as string)}>
            View member details{' '}
          </MenuItem>
          <MenuItem onClick={() => handlePlaceOrder(rowData)}>Place order for member</MenuItem>

          <MenuItem
            onClick={() =>
              toggleRemoveFamilyandFriends({
                isMember: true,
                id: rowData?._id as string
              })
            }
          >
            Delete member
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};
export const partnermembersColumn = [
  {
    key: `firstName`,
    label: 'member name',
    formatter: (value: any, rowData: any) => (value ? value + ' ' + rowData.lastName : 'N/A'),
    chakraStyle: { textAlign: 'center' } as BoxProps,
    headerStyle: { textAlign: 'center', width: '20%' } as BoxProps
  },
  {
    key: `businessId`,
    label: 'member id',
    formatter: (value: any) => (value ? value : 'N/A'),
    chakraStyle: { textAlign: 'center' } as BoxProps,
    headerStyle: { textAlign: 'center', width: '10%' } as BoxProps
  },
  {
    key: 'phoneNumber',
    label: 'phone no',
    chakraStyle: { textAlign: 'center' } as BoxProps,
    headerStyle: { textAlign: 'center', width: '13%' } as BoxProps
  },
  {
    key: 'email',
    label: 'email address',
    chakraStyle: { textAlign: 'center' } as BoxProps,
    headerStyle: { textAlign: 'center', width: '15%' } as BoxProps
  },
  {
    key: 'totalOrders',
    label: 'items ordered',
    chakraStyle: { textAlign: 'center' } as BoxProps,
    headerStyle: { textAlign: 'center', width: '1%' } as BoxProps
  },
  {
    key: 'amountSpent',
    label: 'amount spent(₦)',
    formatter: (value: number) => parseAmount(value),
    chakraStyle: { textAlign: 'center' } as BoxProps,
    headerStyle: { textAlign: 'center', width: '16%' } as BoxProps
  },
  {
    key: 'createdAt',
    label: 'Date Added',
    formatter: (value: string) => formatDateTime(value),
    chakraStyle: { textAlign: 'center' } as BoxProps,
    headerStyle: { textAlign: 'center', width: '15%' } as BoxProps
  },
  {
    key: 'userId',
    label: '',
    formatter: (value: string, rowData: any) => {
      return (
        <Flex align="center" width="50px">
          <PartnerMembersMenuOptions data={rowData} />;
        </Flex>
      );
    },
    chakraStyle: { textAlign: 'center' } as BoxProps
  }
];
