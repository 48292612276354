import { Box, Button, Flex, Input, Text, Textarea, VStack } from '@chakra-ui/react';
import PRModal from 'components/modal';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useStore } from 'store';
import Select from 'react-select';
import { refundReasons } from 'data/order';

const RefunCancelledItemModal = () => {
  const {
    AdminOrderStore: {
      isRefundCancelledItemModalOpen,
      toggleRefundCancelledItemModal,
      toggleEditOrderItem,
      updateOrder,
      selectedOrderDetails,
      refundAmount,
      refundType
    }
  } = useStore();

  const [formDetail, setformDetail] = useState({ message: '', amount: refundAmount });
  const [others, setOthers] = useState<string>('');

  const handleTextAreaChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    const { value } = e.target as HTMLTextAreaElement;
    setOthers(value);
  };

  const formatType = (type: string) => `${type.substring(0, 1).toUpperCase()}${type.substring(1)}`;

  async function handleFormSubmit(e: React.FormEvent) {
    e.preventDefault();
    if (!selectedOrderDetails) return;
    let message = formDetail.message;
    if (message === 'Others') {
      message = others;
    }
    const res = updateOrder(message);
    if (res) {
      toggleEditOrderItem();
      toggleRefundCancelledItemModal();
      setformDetail({ message: '', amount: 0 });
    } else {
      setformDetail({ message: message, amount: refundAmount });
    }
  }

  return (
    <PRModal
      title={`${refundType === 'third party' ? 'Bank' : formatType(refundType)} Refund`}
      open={isRefundCancelledItemModalOpen}
      onClose={toggleRefundCancelledItemModal}
      width="35%"
    >
      <form autoComplete="off" onSubmit={handleFormSubmit}>
        <VStack
          fontSize={{
            base: '12px',
            md: '14px'
          }}
          pt="1rem"
          pl="1rem"
          gap=".5rem"
          align="start"
          w="100%"
        >
          <Box w="100%" mb="20px">
            <Text fontWeight="600">Amount</Text>
            <Input
              type="number"
              w="100%"
              placeholder="Refund amount"
              value={refundAmount}
              disabled={true}
            />
          </Box>
          <Box w="100%">
            <Text fontWeight="600">Refund reason</Text>
            <Select
              options={refundReasons}
              isSearchable={false}
              onChange={(selectedOption: any) => {
                if (selectedOption) {
                  const value = selectedOption.value;

                  if (value === 'Others') {
                    setOthers('');
                  }
                  setformDetail((prev) => ({ ...prev, message: value }));
                }
              }}
              value={
                formDetail
                  ? { value: formDetail.message, label: formDetail.message }
                  : { value: '', label: 'Select a reason' }
              }
              menuPlacement="auto"
              menuPosition="fixed"
              styles={{
                menu: (provided: any) => ({
                  ...provided,
                  maxHeight: '380px',
                  overflowY: 'auto'
                })
              }}
            />
          </Box>
          {formDetail?.message === 'Others' && (
            <Box w="100%">
              <Text>Other reasons please specify</Text>
              <Textarea h="30vh" value={others} name="others" onChange={handleTextAreaChange} />
            </Box>
          )}
          <Flex justify="end" w="100%" mt="20px">
            <Button type="submit">Refund Customer</Button>
          </Flex>
        </VStack>
      </form>
    </PRModal>
  );
};

export default observer(RefunCancelledItemModal);
