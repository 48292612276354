import { VStack, Box, Text, Flex, Divider } from '@chakra-ui/react';
import PRModal from 'components/modal';
import { observer } from 'mobx-react-lite';
import ScheduleItem from './ScheduleItem';
import { useStore } from 'store';
import dayjs from 'dayjs';
import { orderStatus } from 'constant';
import { getSchedule } from 'utils/helpers';

const SchedulesModal = () => {

  const {
    AdminOrderStore: { selectedOrderDetails: order, isScheduleModalOpen, toggleScheduleModal }
  } = useStore();

  const schedules = order?.schedules;
  const schedule = schedules && getSchedule(schedules);
  return (
    <>
      {schedules && schedules.length > 0 && (
        <PRModal
          open={isScheduleModalOpen}
          onClose={() => toggleScheduleModal()}
          title="Subscription schedule"
        >
          <Box
            minW={{ base: '500px' }}
            h="75vh"
            overflowY="scroll"
            sx={{
              '&::-webkit-scrollbar': {
                display: 'none'
              }
            }}
            p="0rem 0rem 6rem 0rem"
          >
            <VStack w="100%" align="start" pt={`.5rem`}>
              <Flex
                flexDir={{
                  base: 'column',
                  md: 'row'
                }}
                justifyContent="space-between"
                width="100%"
                gap="1rem"
              >
                <VStack align="start">
                  <Text
                    fontSize={{
                      base: '12px',
                      md: '14px'
                    }}
                  >
                    Start Date:{' '}
                    {` ${new Date(order.startDate as Date).toLocaleDateString('en-NG')}`}
                  </Text>
                  <Text
                    fontSize={{
                      base: '13px',
                      md: '14px'
                    }}
                  >
                    Next debit date:{' '}
                    {` ${new Date(order.nextDate as Date).toLocaleDateString('en-NG')}`}
                  </Text>
                  <Text>
                    Next schedule date:{' '}
                    {schedule ? (
                      `${dayjs(schedule.dispatchDate).format('DD/MM/YYYY')}`
                    ) : (
                      <Text as="span" fontSize="13px">
                        {' '}
                        {dayjs(order.nextDate as Date).format('DD/MM/YYYY')}{' '}
                        <Text
                          as={'span'}
                          fontSize={{
                            base: '12px',
                            md: '14px'
                          }}
                          h="auto"
                          color={orderStatus['Awaiting Payment'].color}
                          p=".5rem"
                          align="center"
                          borderRadius="8px"
                          bg={orderStatus['Awaiting Payment'].bg}
                        >
                          {' '}
                          Awaiting Payment
                        </Text>
                      </Text>
                    )}
                  </Text>
                </VStack>

                <VStack align="start">
                  <Text>
                    {order?.duration} {order?.frequency} intervals
                  </Text>
                  <Text
                    fontSize={{
                      base: '12px',
                      md: '14px'
                    }}
                  >
                    Automatic debit:{' '}
                    {order.saveCard || order.paymentChannel === 'wallet' ? ' Active' : ' Inactive'}
                  </Text>

                  <Text
                    fontSize={{
                      base: '13px',
                      md: '14px'
                    }}
                  >
                    Order number:
                    <Text
                      as={'span'}
                      fontSize={{
                        base: '12px',
                        md: '14px'
                      }}
                      color="primary.main"
                    >
                      {' '}
                      {order?.orderNumber}
                    </Text>
                  </Text>
                </VStack>
              </Flex>
              <Divider pt=".5rem" />
              <VStack pt="2rem" align="start" w="100%">
                {schedules?.map((schedule, index) => (
                  <ScheduleItem key={index} schedule={schedules[schedules.length - 1 - index]} />
                ))}
              </VStack>
            </VStack>
          </Box>
        </PRModal>
      )}
    </>
  );
};

export default observer(SchedulesModal);
