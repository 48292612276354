import { Box } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { Suspense, lazy } from 'react';
import Loading from 'components/loading';
import { Routes } from 'constant';
import { useStore } from 'store';
const Navbar = lazy(() => import('components/navbar'));

const ClientLayout = () => {
  const {
    AuthStore: { isAuthenticated, user }
  } = useStore();
  const navigate = useNavigate();
  const location = useLocation();
  const verifyPage = location?.pathname;

  useEffect(() => {
    const notProtectedRoutes = [Routes.ClientOrdersAddress, Routes.PlaceOrder];
    !isAuthenticated &&
      !verifyPage &&
      !notProtectedRoutes.includes(location.pathname) &&
      navigate(Routes.PlaceOrder);
  }, [isAuthenticated]);

  return (
    <Suspense fallback={<Loading />}>
      <Box>
        <Navbar isAdmin={!user?.role} />
        <Box minHeight="100vh" w="100vw" overflowY="scroll">
          <Outlet />
        </Box>
      </Box>
    </Suspense>
  );
};

export default observer(ClientLayout);
