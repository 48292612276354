import { VStack, Button, Text, Flex, Box } from '@chakra-ui/react';
import PRModal from 'components/modal';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStore } from 'store';

const CancelItemModal = () => {
  const {
    AdminOrderStore: {
      isCancelItemModalOpen,
      toggleCancelOrderItemModal,
      removeOrderItem,
      selectedOrderDetails,
      selectedOrderItem
    }
  } = useStore();

  const handleClick = () => {
    if (selectedOrderItem) {
      removeOrderItem(selectedOrderItem?._id);
    }
    toggleCancelOrderItemModal('');
  };

  return (
    <PRModal
      title={'Cancel Item'}
      open={isCancelItemModalOpen}
      onClose={() => toggleCancelOrderItemModal('')}
      width="40%"
    >
      <VStack
        pt="1rem"
        pl="1rem"
        gap=".5rem"
        w="100%"
        fontSize={{
          base: '12px',
          md: '14px'
        }}
        align="start"
      >
        <Box w="90%">
          <Text fontWeight="400">
            {selectedOrderDetails?.orderStatus === 'Paid'
              ? 'Are you sure you want to cancel this item from this order? This is going to initiate a refund'
              : 'Are you sure you want to cancel this item from this order?'}
          </Text>
        </Box>
        <Flex w="100%" justifyContent="end" py="1rem" gap=".6rem" mt="20px">
          <Button type="submit" onClick={() => handleClick()}>
            {selectedOrderDetails?.orderStatus === 'Paid' ? `Proceed` : `Cancel`}
          </Button>
        </Flex>
      </VStack>
    </PRModal>
  );
};

export default observer(CancelItemModal);
