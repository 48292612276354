import { Icon, IconProps } from "@chakra-ui/react";

export const UserIcon = (props: IconProps) => (
    <Icon width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.00002 16.5001C13.1421 16.5001 16.5 13.1422 16.5 9.00008C16.5 4.858 13.1421 1.50008 9.00002 1.50008C4.85794 1.50008 1.50002 4.858 1.50002 9.00008C1.50002 13.1422 4.85794 16.5001 9.00002 16.5001ZM9.00002 17.3334C13.6025 17.3334 17.3334 13.6026 17.3334 9.00008C17.3334 4.39758 13.6025 0.666748 9.00002 0.666748C4.39752 0.666748 0.666687 4.39758 0.666687 9.00008C0.666687 13.6026 4.39752 17.3334 9.00002 17.3334Z"
            fill="#5519AB"
        />
        <path
            d="M4 13.846C4 13.4156 4.32167 13.0518 4.75 13.0043C7.96458 12.6485 10.05 12.6806 13.2575 13.0122C13.4177 13.0291 13.5695 13.0922 13.6944 13.1938C13.8193 13.2955 13.9119 13.4314 13.9608 13.5848C14.0098 13.7382 14.013 13.9026 13.9701 14.0578C13.9272 14.213 13.84 14.3524 13.7192 14.4589C9.93375 17.7585 7.77042 17.7131 4.26667 14.4622C4.09583 14.3039 4 14.0789 4 13.8464V13.846Z"
            fill="#5519AB"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.2146 13.4263C10.0325 13.0971 7.97713 13.0663 4.79546 13.4184C4.69073 13.4306 4.59418 13.481 4.52429 13.5599C4.45441 13.6389 4.4161 13.7408 4.41671 13.8463C4.41671 13.9654 4.46629 14.0784 4.55004 14.1567C6.28671 15.7675 7.60254 16.4954 8.88879 16.5C10.1796 16.5046 11.5663 15.7825 13.4455 14.145C13.5052 14.0919 13.5481 14.0226 13.5692 13.9455C13.5902 13.8684 13.5884 13.7868 13.5639 13.7107C13.5395 13.6346 13.4934 13.5673 13.4314 13.5168C13.3694 13.4664 13.2941 13.4351 13.2146 13.4267V13.4263ZM4.70421 12.59C7.95254 12.2304 10.0684 12.2629 13.3009 12.5975C13.5418 12.6227 13.7702 12.7175 13.9581 12.8705C14.146 13.0235 14.2852 13.2279 14.3587 13.4587C14.4322 13.6896 14.4367 13.9369 14.3719 14.1703C14.307 14.4037 14.1755 14.6131 13.9934 14.7729C12.0871 16.4346 10.4996 17.3396 8.88629 17.3334C7.26838 17.3275 5.75088 16.4071 3.98379 14.7675C3.85727 14.6497 3.75641 14.507 3.68752 14.3484C3.61862 14.1899 3.58317 14.0188 3.58338 13.8459C3.58277 13.5352 3.69679 13.2353 3.90361 13.0034C4.11043 12.7716 4.3955 12.6243 4.70421 12.5896V12.59Z"
            fill="#5519AB"
        />
        <path
            d="M12.3334 7.33333C12.3334 8.21739 11.9822 9.06523 11.357 9.69036C10.7319 10.3155 9.88408 10.6667 9.00002 10.6667C8.11597 10.6667 7.26812 10.3155 6.643 9.69036C6.01788 9.06523 5.66669 8.21739 5.66669 7.33333C5.66669 6.44928 6.01788 5.60143 6.643 4.97631C7.26812 4.35119 8.11597 4 9.00002 4C9.88408 4 10.7319 4.35119 11.357 4.97631C11.9822 5.60143 12.3334 6.44928 12.3334 7.33333Z"
            fill="#5519AB"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.00002 9.83333C9.66306 9.83333 10.2989 9.56994 10.7678 9.1011C11.2366 8.63226 11.5 7.99637 11.5 7.33333C11.5 6.67029 11.2366 6.03441 10.7678 5.56557C10.2989 5.09673 9.66306 4.83333 9.00002 4.83333C8.33698 4.83333 7.70109 5.09673 7.23225 5.56557C6.76341 6.03441 6.50002 6.67029 6.50002 7.33333C6.50002 7.99637 6.76341 8.63226 7.23225 9.1011C7.70109 9.56994 8.33698 9.83333 9.00002 9.83333ZM9.00002 10.6667C9.88408 10.6667 10.7319 10.3155 11.357 9.69036C11.9822 9.06523 12.3334 8.21739 12.3334 7.33333C12.3334 6.44928 11.9822 5.60143 11.357 4.97631C10.7319 4.35119 9.88408 4 9.00002 4C8.11597 4 7.26812 4.35119 6.643 4.97631C6.01788 5.60143 5.66669 6.44928 5.66669 7.33333C5.66669 8.21739 6.01788 9.06523 6.643 9.69036C7.26812 10.3155 8.11597 10.6667 9.00002 10.6667Z"
            fill="#5519AB"
        />
    </Icon>
);
